// Import React Libraries and Components
import React, { Component } from "react";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import BasicAppication from "../../../components/membership/slotsMembers/BasicApplication";
import OrganisationProfile from "../../../components/membership/slotsMembers/OrganisationProfile";
import SLOTSApplication from "../../../components/membership/slotsMembers/SLOTSApplication";
import {
  SLOTSMemberValidationRules,
  SLOTSAuthorisedRepresentativeValidationRules,
  SLOTSMemberBCAScopeValidationRules,
  SLOTSMemberRegistrationScopeValidationRules,
  SLOTSMemberBasicApplicationValidationRules,
  SLOTSMemberOrganisationProfileValidationRules

} from "./SLOTSMemberValidationRules";
import {
  CommonValidationMessages,
  CommonSuccessMessages,
} from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import { checkParameters } from "../../../utils/Encryption";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";

// Import API Services
import SlotMemberService from "../../../services/axiosServices/apiServices/SlotMemberService";
import SwalServices from "../../../services/swalServices/SwalServices";
import SendClarifications from "./SendClarifications";
import moment from "moment";
import { saveAs } from "file-saver";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import { Navigate } from "react-router-dom";
import SendRejectionReason from "./SendRejectionReason";
import CheckboxInput from "../../../components/inputs/CheckboxInput";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
export default class EditSLOTSMember extends Component {
  constructor(props) {
    super(props);
    this.slotMemberService = new SlotMemberService();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      validState: {
        isValid: true,
        error: {},
      },
      isDisabledFlag: false,
      arcaFiles: [],
      wpOnlineRecordFiles: [],
      cPFcontributionFiles: [],
      copyOfCPFContributionsOfCompletedProjectsList: [],
      approvedTechnicalQualificationList: [],
      supportingDocFormAOngoingList: [],
      supportingDocFormAOnCompleteList: [],
      companyBankFiles: [],
      supportingDocFormAList: [],
      route: null,
      isLoading: false,
      isLoadingRejectAplication: false,
      isLoadingExpireApplication: false,
      isWPRecordLoader: false,
      is6MonCPFLoader: false,
      isCPFConLoader: false,
      isApprovedBCALoader: false,
      isBankAccStatLoader: false,
      isOnGoingProLoader: false,
      isOnGoingProLoader2: false,
      isCompProLoader: false,
      isCompProLoader2: false,
      isDraftLoader: false,
      isSubmitLoader: false,
      isPaymentProofLoader: false,
      isACRACerLoader: false,
      isPaymentLinkLoader: false,
      isWorkshopProofLoader: false,
      isRepresentativeLoader: false,
      isFormALoader: false,
      isFormASupportingDocumentLoader: false,
      fileUploadCount: 0,
      cpfContributionLocalCount: 0,
      wPOnlineRecordCount: 0,
      copyofcpfContributionLocalCount: 0,
      approvedTechnicalQualificationCount: 0,
      companyBankAccStatementCount: 0,
      supportingDocOngoingFileUploadCount: 0,
      supportingDocCompleteFileUploadCount: 0,
      fileDeletedFlag: false,
      isExpiredMember: false,
      // All dropdown list
      status: [],
      countryList: [],
      annualTurnoverList: [],
      staffEmployeeLocalList: [
        {
          value: 1,
          label: "Emp List 1",
        },
        {
          value: 2,
          label: "Emp List 2",
        },
        {
          value: 3,
          label: "Emp List 3",
        },
      ],
      updatedBy: 0,
      updatedAppId: 0,
      adminName: '',
      roleList: [],
      mainGroupList: [],
      workheadList: [],
      gradeList: [],
      mainGroupListPricing: [],
      workheadListPricing: [],
      slotsMemberId: 0,
      foreignWorkPermitHolderList: [],
      priceCalculatorArray: [
        {
          value: 1,
          description:
            "Registration of Trade-head(s) under same category (max 2)",
          quantity: 0,
          Amount: 0,
        },
        {
          value: 1,
          description:
            "Registration of additional Trade-head(s) under existing category",
          quantity: 0,
          Amount: 0,
        },
        {
          value: 3,
          description: "Subtotal (before GST)",
          quantity: "",
          Amount: 0,
        },
      ],
      priceCalculatorArrayTopUpAdditional: [
        {
          value: 1,
          description:
            "Registration of Trade-head(s) under same category (max 2)",
          quantity: 0,
          Amount: 0,
        },
        {
          value: 1,
          description:
            "Registration of additional Trade-head(s) under existing category",
          quantity: 0,
          Amount: 0,
        },
        {
          value: 3,
          description: "Subtotal (before GST)",
          quantity: "",
          Amount: 0,
        },
      ],
      // SLOTS Application Details object
      renewalDataLength: 0,



      SLOTSApplicationDetails: {

        IsMigratedData: false,
        CertificateMembershipNo: "",
        slotsMemberId: 0,
        isRenewal: 0,
        companyName: "",
        chineseCompanyName: "",
        uenNumber: "",
        dateOfIncorporation: "",
        mailingAddress1: "",
        mailingAddress2: "",
        mailingAddress3: "",
        countryId: "",
        postalCode: "",
        businessTelNo: "",
        businessEmail: "",
        hashedPassword: "",
        passwordSalt: "",
        annualTurnOverId: 0,
        companyDescription: "",
        isCompanySafetyRelatedIssues: "",
        specifyReason: "",
        moreInformation: "",
        numberOfStaffEmployedLocalId: 0,
        foreignWorkPermitHolder: "",
        foreignWorkPermitHolderId: 0,
        foreignWorkPermitHolderLabel: "",
        uploadACRACertificate: "",
        uploadWPOnlineRecord: "",
        latest6monthsCPFContribution: "",
        copyOfCPFContributionsOfCompletedProjects: "",
        approvedTechnicalQualification: "",
        headOfCompanyFirstName: "",
        paidUpCapital: "",
        headOfCompanyLastName: "",
        jobTitle: "",
        directOfficeNumber: "",
        mobileNumber: "",
        email: "",
        companyBankStatementsOf6Month: "",
        isCompanyActivities: true,
        projectTrackRecordsOfOnGoingProject: "",
        projectTrackRecordsOfCompletedProject: "",
        membershipTypeId: "",
        uploadPaymentProof: "",
        isActive: "",
        authorisedRepresentativeSummaryList: [],
        registeredBCAScopeList: [],
        slotsRegistrationHeadScope: [],
        slotsRegistrationHeadScopeTopUp: [],
        slotsRegistrationHeadScopeTopUpAdditional: [],
        renewalSLOTSRegistrationHeadScope: [],
        priceCalculator: [],
        renewalPriceCalculator: [],
        statusId: 0,
        workshopProofPath: "",
        supportingDocFormAOngoing: "",
        supportingDocFormAOnComplete: "",
        isSgMAworkshopCertificate: false,
        isoOrGreenPolicy: "",
        iaoOrbizSAFEStar: "",
        appointmentletterforSGSecure: "",
        fileListOfWPRecord: [],
        fileList2: [],
        fileListOfcopyofcpfContribution: [],
        fileListOfApprovedTechnicalQualification: [],
        bankAccountStatementList: [],
        formAOnGoingList: [],
        formACompletedList: [],
        formA: "",
        supportingDocFormA: "",
        supportingDocFormAList: [],
        certificateofAttendance: "",
        isPaymentLinkSentForRenewal: false,
        isForceRenewal: false,
        isExpiredMember: false,
        CreditNoteStatusId: 0,

      },
      isForceRenewalLoader: false,
      supportingDocFormACount: 0,
      isBasicApplicationLoading: false,
      isOrganisationProfileLoading: false,
      // Roles Details Object
      roleDetails: {
        authorisedRepresentativeId: 0,
        roleId: 0,
        roles: "",
        firstName: "",
        lastName: "",
        jobTitle: "",
        directOfficeNumber: "",
        mobileNumber: "",
        emailAddress: "",
        isNewsletter: false,
        isTelegram: false,
        isePortal: false,
        sLOTSMemberId: 0,


      },
      // Buildings and Scope Object
      selectedBuildingConScope: {
        scopeId: 0,
        mainGroupId: 0,
        workheadId: 0,
        gradeId: 0,
        mainGroupName: "",
        workheadName: "",
        gradeName: "",
      },


      // SLOTS Registration Object
      selectedSLOTSRegistrationScope: {
        scopeId: 0,
        selectedMainGroupId: 0,
        selectedWorkheadId: 0,
        mainGroupName: "",
        workheadName: "",
        isTopup: false,
      },
      // slots member pricing
      slotsMemberPricingDetail: {
        membershipSLOTSPricingId: 0,
        slotsAdminFee: 0,
        slotsAnnual1To2TradeSameCategoryFee: 0,
        slotsAnnualTradeExistingCategoryFee: 0,
        slotsAnnual1To2TradeDifferentCategoryFee: 0,
      },
      // Sefty validation Rules
      specifyReason: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "specify reason"
          ),
        },
      ],
      moreInformation: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "more information"
          ),
        },
      ],
      uploadWPOnlineRecordValRule: [
        {
          type: "require",
          message: CommonValidationMessages.SelectRequired.replace(
            "{0}",
            "document"
          ),
        },
      ],
      // Valid State Object
      validStateSLOTSMember: {
        isValid: true,
        error: {},
      },
      // Valid State Role Object
      validStateSLOTSMemberRole: {
        isValid: true,
        error: {},
      },
      // Valid State BCA Scope Object
      validStateBCAScope: {
        isValid: true,
        error: {},
      },
      // Valid State Registration Scope Object
      validStateregistrationScope: {
        isValid: true,
        error: {},
      },

      validStateBasicApplication: {
        isValid: true,
        error: {},
      },

      validStateOrganisationProfile: {
        isValid: true,
        error: {},
      },
      setSendClarifications: false,
      clarification: "",
      setRejectionReason: false,
      sendRejectionReason: "",
      // sendRejectionReason: {},

    };
  }



  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    let cookie = getAuthProps();
    if (cookie != null) {
      const adminUserId = cookie.adminUserId;
      const adminname = cookie.name;
      this.setState({ updatedBy: adminUserId, updatedAppId: 111, adminName: adminname });
    }

    let checkRead = window.location.search;
    let getRead = checkRead.split("=");
    let checkFinal = getRead[2];
    if (checkFinal === "ReadOnly") {
      this.setState({ isDisabledFlag: true });
    }

    let slotsId = params.get("");
    if (slotsId && (slotsId = checkParameters(slotsId, "Number"))) {
      if (slotsId != null && slotsId > 0) {
        this.setState({ slotsMemberId: slotsId });
      }
    }

    this.getAllDropDownsForSLOTSMember();
    this.getSLOTSMemberDetailByMemberId(slotsId);
    this.getMembershipPricingWithGst();
  };

  // Get All Dropdowns For SLOTS Member Method
  getAllDropDownsForSLOTSMember = () => {
    this.slotMemberService.getAllDropDownsForSLOTSMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var mainGroupList = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ScopeMainGroup"
        );
        mainGroupList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        mainGroupList = mainGroupList.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({ mainGroupListPricing: mainGroupList });

        var mainGroupListBCA = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ScopeMainGroupBCA"
        );
        mainGroupListBCA.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        mainGroupListBCA = mainGroupListBCA.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({ mainGroupList: mainGroupListBCA });

        var country = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Country"
        );
        country.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        country = country.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ countryList: country });

        var annualTurnover = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipRevenue"

        );
        annualTurnover.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        annualTurnover = annualTurnover.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({ annualTurnoverList: annualTurnover });

        var empStaffLocal = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "NumberOfStaffEmployedLocal"
        );
        empStaffLocal.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        empStaffLocal = empStaffLocal.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({ staffEmployeeLocalList: empStaffLocal });

        var orgRole = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "OrganisationRole"
        );
        orgRole.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        orgRole = orgRole.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ roleList: orgRole });

        var ForeignWorkPermitHolder =
          response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "ForeignWorkPermitholder"
          );
        ForeignWorkPermitHolder.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ForeignWorkPermitHolder = ForeignWorkPermitHolder.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({ foreignWorkPermitHolderList: ForeignWorkPermitHolder });
      }
    });
  };

  // Get SOLTS Member Detail By Id
  getSLOTSMemberDetailByMemberId = (id) => {

    this.setState({ isBasicApplicationLoading: true });
    this.setState({ isOrganisationProfileLoading: true });
    // if (this.state.slotsMemberId > 0 && this.state.slotsMemberId != null) {
    // let request = new Object();
    // request.sLOTSMemberId = 1
    this.slotMemberService
      .getSLOTSMemberDetailByMemberId(id)
      .then((response) => {

        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.SLOTSApplicationDetails };
            // let arca = response.responseItem.responseContent.uploadACRACertificate
            //     ? response.responseItem.responseContent.uploadACRACertificate.split(',') : null;
            // if (arca) {
            //     arca = arca.map((p) => ({
            //         path: p,
            //     }));
            // }
            let wpOnlineRecord = response.responseItem.responseContent
              .uploadWPOnlineRecord
              ? response.responseItem.responseContent.uploadWPOnlineRecord.split(
                ","
              )
              : null;
            if (wpOnlineRecord) {
              wpOnlineRecord = wpOnlineRecord.map((p) => ({
                path: p,
              }));
            }

            let CPFcontribution = response.responseItem.responseContent
              .latest6monthsCPFContribution
              ? response.responseItem.responseContent.latest6monthsCPFContribution.split(
                ","
              )
              : null;
            if (CPFcontribution) {
              CPFcontribution = CPFcontribution.map((p) => ({
                path: p,
              }));
            }

            let CopyOfCPFContributionsOfCompletedProjects = response
              .responseItem.responseContent
              .copyOfCPFContributionsOfCompletedProjects
              ? response.responseItem.responseContent.copyOfCPFContributionsOfCompletedProjects.split(
                ","
              )
              : null;
            if (CopyOfCPFContributionsOfCompletedProjects) {
              CopyOfCPFContributionsOfCompletedProjects =
                CopyOfCPFContributionsOfCompletedProjects.map((p) => ({
                  path: p,
                }));
            }

            let companyBankStatements = response.responseItem.responseContent
              .companyBankStatementsOf6Month
              ? response.responseItem.responseContent.companyBankStatementsOf6Month.split(
                ","
              )
              : null;
            if (companyBankStatements) {
              companyBankStatements = companyBankStatements.map((p) => ({
                path: p,
              }));
            }

            let approvedTechnicalQualification = response.responseItem
              .responseContent.approvedTechnicalQualification
              ? response.responseItem.responseContent.approvedTechnicalQualification.split(
                ","
              )
              : null;
            if (approvedTechnicalQualification) {
              approvedTechnicalQualification =
                approvedTechnicalQualification.map((p) => ({
                  path: p,
                }));
            }

            let supportingDocFormAOngoingdocs = response.responseItem
              .responseContent.supportingDocFormAOngoing
              ? response.responseItem.responseContent.supportingDocFormAOngoing.split(
                ","
              )
              : null;
            if (supportingDocFormAOngoingdocs) {
              supportingDocFormAOngoingdocs = supportingDocFormAOngoingdocs.map(
                (p) => ({
                  path: p,
                })
              );
            }

            let supportingDocFormAOnCompletedocs = response.responseItem
              .responseContent.supportingDocFormAOnComplete
              ? response.responseItem.responseContent.supportingDocFormAOnComplete.split(
                ","
              )
              : null;
            if (supportingDocFormAOnCompletedocs) {
              supportingDocFormAOnCompletedocs =
                supportingDocFormAOnCompletedocs.map((p) => ({
                  path: p,
                }));
            }

            let supportingDocFormAs = response.responseItem
              .responseContent.supportingDocFormA
              ? response.responseItem.responseContent.supportingDocFormA.split(
                ","
              )
              : null;
            if (supportingDocFormAs) {
              supportingDocFormAs = supportingDocFormAs.map(
                (p) => ({
                  path: p,
                })
              );
            }

            this.setState({
              wpOnlineRecordFiles: wpOnlineRecord,
              cPFcontributionFiles: CPFcontribution,
              copyOfCPFContributionsOfCompletedProjectsList:
                CopyOfCPFContributionsOfCompletedProjects,
              companyBankFiles: companyBankStatements,
              approvedTechnicalQualificationList: approvedTechnicalQualification,
              supportingDocFormAOngoingList: supportingDocFormAOngoingdocs,
              supportingDocFormAOnCompleteList: supportingDocFormAOnCompletedocs,
              supportingDocFormAList: supportingDocFormAs,
            });


            // SLOTS Basic Inforamtion
            res.slotsMemberId =
              response.responseItem.responseContent.slotsMemberId;
            res.CreditNoteStatusId =
              response.responseItem.responseContent.creditNoteStatusId;
            res.companyName = response.responseItem.responseContent.companyName;
            res.chineseCompanyName =
              response.responseItem.responseContent.chineseCompanyName;
            res.uenNumber = response.responseItem.responseContent.uenNumber;
            res.dateOfIncorporation =
              response.responseItem.responseContent.dateOfIncorporation;
            res.mailingAddress1 =
              response.responseItem.responseContent.mailingAddress1;
            res.mailingAddress2 =
              response.responseItem.responseContent.mailingAddress2;
            res.mailingAddress3 =
              response.responseItem.responseContent.mailingAddress3;
            res.countryId = response.responseItem.responseContent.countryId;
            res.postalCode = response.responseItem.responseContent.postalCode;
            res.businessTelNo =
              response.responseItem.responseContent.businessTelNo;
            res.businessEmail =
              response.responseItem.responseContent.businessEmail;
            res.appointmentletterforSGSecure =
              response.responseItem.responseContent.appointmentletterforSGSecure;
            res.isoOrGreenPolicy =
              response.responseItem.responseContent.isoOrGreenPolicy;
            res.iaoOrbizSAFEStar =
              response.responseItem.responseContent.iaoOrbizSAFEStar;
            res.isSgMAworkshopCertificate =
              response.responseItem.responseContent.isSgMAworkshopCertificate;
            // SLOTS Organisation Profile
            res.annualTurnOverId =
              response.responseItem.responseContent.annualTurnOverId;
            res.companyDescription =
              response.responseItem.responseContent.companyDescription;
            res.isCompanySafetyRelatedIssues =
              response.responseItem.responseContent.isCompanySafetyRelatedIssues;
            res.specifyReason =
              response.responseItem.responseContent.specifyReason;
            res.moreInformation =
              response.responseItem.responseContent.moreInformation;
            res.numberOfStaffEmployedLocalId =
              response.responseItem.responseContent.numberOfStaffEmployedLocalId;
            res.foreignWorkPermitHolderId =
              response.responseItem.responseContent.foreignWorkPermitHolderId;

            res.uploadACRACertificate =
              response.responseItem.responseContent.uploadACRACertificate;
            res.uploadWPOnlineRecord =
              response.responseItem.responseContent.uploadWPOnlineRecord;
            res.latest6monthsCPFContribution =
              response.responseItem.responseContent.latest6monthsCPFContribution;
            res.copyOfCPFContributionsOfCompletedProjects =
              response.responseItem.responseContent.copyOfCPFContributionsOfCompletedProjects;
            res.approvedTechnicalQualification =
              response.responseItem.responseContent.approvedTechnicalQualification;
            res.headOfCompanyFirstName =
              response.responseItem.responseContent.headOfCompanyFirstName;
            res.paidUpCapital =
              response.responseItem.responseContent.paidUpCapital;
            res.headOfCompanyLastName =
              response.responseItem.responseContent.headOfCompanyLastName;
            res.jobTitle = response.responseItem.responseContent.jobTitle;
            res.directOfficeNumber =
              response.responseItem.responseContent.directOfficeNumber;
            res.mobileNumber =
              response.responseItem.responseContent.mobileNumber;
            res.email = response.responseItem.responseContent.email;
            res.authorisedRepresentativeSummaryList =
              response.responseItem.responseContent.authorisedRepresentativeSummaryList;


            // SLOTS Application
            res.companyBankStatementsOf6Month =
              response.responseItem.responseContent.companyBankStatementsOf6Month;
            res.isCompanyActivities =
              response.responseItem.responseContent.isCompanyActivities;
            res.projectTrackRecordsOfOnGoingProject =
              response.responseItem.responseContent.projectTrackRecordsOfOnGoingProject;
            res.projectTrackRecordsOfCompletedProject =
              response.responseItem.responseContent.projectTrackRecordsOfCompletedProject;
            res.membershipTypeId =
              response.responseItem.responseContent.membershipTypeId;

            res.IsMigratedData =
              response.responseItem.responseContent.isMigratedData;

            res.CertificateMembershipNo =
              response.responseItem.responseContent.certificateMembershipNo;

            res.uploadPaymentProof =
              response.responseItem.responseContent.uploadPaymentProof;
            res.isActive = response.responseItem.responseContent.isActive;
            res.registeredBCAScopeList =
              response.responseItem.responseContent.registeredBCAScopeList;
            res.formA =
              response.responseItem.responseContent.formA;
            res.certificateofAttendance =
              response.responseItem.responseContent.certificateofAttendance;
            //res.slotsRegistrationHeadScope = response.responseItem.responseContent.slotsRegistrationHeadScope;
            let array =
              response.responseItem.responseContent.slotsRegistrationHeadScope;
            array.map((x) => {
              x.isTopup = false;
              return x;
            });

            res.slotsRegistrationHeadScope = array;

            console.log("no top up", res.slotsRegistrationHeadScope)
            let array2 =
              response.responseItem.responseContent
                .slotsRegistrationHeadScopeTopUp;
            array2.map((x) => {
              x.isTopup = true;
              x.selectedMainGroupId = x.mainGroupId;
              x.selectedWorkheadId = x.mainWorkHeadId;
              return x;
            });

            res.slotsRegistrationHeadScopeTopUp = array2;

            console.log("topup", res.slotsRegistrationHeadScopeTopUp)
            let array3 =
              response.responseItem.responseContent
                .slotsRegistrationHeadScopeTopUpAdditional;
            array3.map((x) => {
              x.isTopup = true;
              x.selectedMainGroupId = x.mainGroupId;
              x.selectedWorkheadId = x.mainWorkHeadId;
              return x;
            });
            res.slotsRegistrationHeadScopeTopUpAdditional = array3;

            res.renewalSLOTSRegistrationHeadScope =
              response.responseItem.responseContent.renewalSLOTSRegistrationHeadScope;
            res.priceCalculator =
              response.responseItem.responseContent.priceCalculator;
            res.renewalPriceCalculator =
              response.responseItem.responseContent.renewalPriceCalculator;
            res.statusId = response.responseItem.responseContent.statusId;
            res.workshopProofPath =
              response.responseItem.responseContent.workshopProofPath;
            res.supportingDocFormAOngoing =
              response.responseItem.responseContent.supportingDocFormAOngoing;
            res.supportingDocFormAOnComplete =
              response.responseItem.responseContent.supportingDocFormAOnComplete;
            /// New Renewal fields
            res.formA =
              response.responseItem.responseContent.formA;
            res.supportingDocFormA =
              response.responseItem.responseContent.supportingDocFormA;
            ///////// ----
            let renewalLength =
              response.responseItem.responseContent
                .renewalSLOTSRegistrationHeadScope.length;

            // let foreginPermitObj = this.state.foreignWorkPermitHolderList?.find(foreignWorkPermitHolder => foreignWorkPermitHolder.value === res.foreignWorkPermitHolderId)
            // res.foreignWorkPermitHolderLabel = foreginPermitObj.label;

            let data1 =
              response.responseItem.responseContent
                .latest6monthsCPFContribution;
            let arr1 = [];
            if (data1) {
              arr1 = data1.split(",");
            }
            res.fileList2 = [...arr1];

            let data3 =
              response.responseItem.responseContent.uploadWPOnlineRecord;
            let arr3 = [];
            if (data3) {
              arr3 = data3.split(",");
            }
            res.fileListOfWPRecord = [...arr3];

            let data4 =
              response.responseItem.responseContent
                .copyOfCPFContributionsOfCompletedProjects;
            let arr4 = [];
            if (data4) {
              arr4 = data4.split(",");
            }
            res.fileListOfcopyofcpfContribution = [...arr4];

            let data5 =
              response.responseItem.responseContent
                .approvedTechnicalQualification;
            let arr5 = [];
            if (data5) {
              arr5 = data5.split(",");
            }
            res.fileListOfApprovedTechnicalQualification = [...arr5];

            let data6 =
              response.responseItem.responseContent
                .companyBankStatementsOf6Month;
            let arr6 = [];
            if (data6) {
              arr6 = data6.split(",");
            }
            res.bankAccountStatementList = [...arr6];

            let data7 =
              response.responseItem.responseContent.supportingDocFormAOngoing;
            let arr7 = [];
            if (data7) {
              arr7 = data7.split(",");
            }
            res.formAOnGoingList = [...arr7];

            let data8 =
              response.responseItem.responseContent
                .supportingDocFormAOnComplete;
            let arr8 = [];
            if (data8) {
              arr8 = data8.split(",");
            }
            res.formACompletedList = [...arr8];

            let data9 =
              response.responseItem.responseContent.supportingDocFormA;
            let arr9 = [];
            if (data9) {
              arr9 = data9.split(",");
            }
            res.supportingDocFormAList = [...arr9];

            res.isPaymentLinkSentForRenewal = response.responseItem.responseContent.isPaymentLinkSentForRenewal;

            res.isForceRenewal = response.responseItem.responseContent.isForceRenewal;
            res.isExpiredMember = response.responseItem.responseContent.isExpiredMember;

            // this.setState({ SLOTSApplicationDetails: res, renewalDataLength: renewalLength });
            this.setState({
              isBasicApplicationLoading: false,
              isOrganisationProfileLoading: false,
              SLOTSApplicationDetails: res,
              renewalDataLength: renewalLength,
              wPOnlineRecordCount: res.fileListOfWPRecord.length,
              cpfContributionLocalCount: res.fileList2.length,
              copyofcpfContributionLocalCount:
                res.fileListOfcopyofcpfContribution.length,
              approvedTechnicalQualificationCount:
                res.fileListOfApprovedTechnicalQualification.length,
              companyBankAccStatementCount: res.bankAccountStatementList.length,
              supportingDocOngoingFileUploadCount: res.formAOnGoingList.length,
              supportingDocCompleteFileUploadCount:
                res.formACompletedList.length,
            });

            if (res.statusId === 21) {
              this.setState({ isDisabledFlag: true });
            }
          }
        } else {
          this.swalServices.Error(response.message);
        }
        // this.setState({ isLoading: false });
      });
    // }
  };

  // Get Dropdown of Workhead and Grade Method
  getDropdownDataOfWorkheadAndGrade = (id, identity) => {
    let request = {};
    request.id = id;
    request.dropdownName = identity;
    this.slotMemberService
      .getDropdownDataOfWorkheadAndGrade(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //let data = response.responseItem.responseContent;
          if (identity === "Workhead") {
            var ScopeWorkhead = response.responseItem.responseContent;
            ScopeWorkhead = ScopeWorkhead.map((p) => ({
              value: p.id,
              label: p.name,
            }));
            this.setState({ workheadList: ScopeWorkhead });
          } else if (identity === "Grade") {
            var gradeList = response.responseItem.responseContent;
            gradeList = gradeList.map((p) => ({ value: p.id, label: p.name }));
            this.setState({ gradeList: gradeList });
          } else if (identity === "Workhead2") {
            var workheadList = response.responseItem.responseContent;
            workheadList = workheadList.map((p) => ({
              value: p.id,
              label: p.name,
            }));
            this.setState({ workheadListPricing: workheadList });
          }
        }
      });
  };

  // SLOTS Member Validation Method Declaration Section Start
  validateSLOTSMember = (key) => {
    let data = { ...SLOTSMemberValidationRules };
    data.uploadWPOnlineRecord = this.state.uploadWPOnlineRecordValRule;
    const returnValidState = validate(
      key,
      this.state.SLOTSApplicationDetails,
      data,
      this.state.validStateSLOTSMember
    );
    this.setState({ validStateSLOTSMember: returnValidState });
  };

  isValidateAllFields1 = () => {
    const newValidState = {
      isValid: this.state.clarification.trim() !== "",
      error: {
        clarification: this.state.clarification.trim() === "" ? "Field is required." : "",
        // Other fields if applicable
      },
    };
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  isValidSLOTSMember = () => {
    let data = { ...SLOTSMemberValidationRules };
    if (this.state.SLOTSApplicationDetails.isCompanySafetyRelatedIssues) {
      data.specifyReason = this.state.specifyReason;
      data.moreInformation = this.state.moreInformation;
    }
    if (
      this.state.SLOTSApplicationDetails.foreignWorkPermitHolderLabel !== "0"
    ) {
      data.uploadWPOnlineRecord = this.state.uploadWPOnlineRecordValRule;
    }
    const returnValidState = isValidForm(
      this.state.SLOTSApplicationDetails,
      data,
      this.state.validStateSLOTSMember
    );
    this.setState({ validStateSLOTSMember: returnValidState });
    return returnValidState.isValid;
  };

  // SLOTS Member Roles Validation Method Start
  validateSLOTSMemberRole = (key) => {
    const returnValidState = validate(
      key,
      this.state.roleDetails,
      SLOTSAuthorisedRepresentativeValidationRules,
      this.state.validStateSLOTSMemberRole
    );
    this.setState({ validStateSLOTSMemberRole: returnValidState });
  };



  isValidSLOTSMemberRole = () => {
    const returnValidState = isValidForm(
      this.state.roleDetails,
      SLOTSAuthorisedRepresentativeValidationRules,
      this.state.validStateSLOTSMemberRole
    );
    this.setState({ validStateSLOTSMemberRole: returnValidState });
    return returnValidState.isValid;
  };

  // SLOTS Member BCA Scope validation Method Start
  validateBCAScope = (key) => {
    const returnValidState = validate(
      key,
      this.state.selectedBuildingConScope,
      SLOTSMemberBCAScopeValidationRules,
      this.state.validStateBCAScope
    );
    this.setState({ validStateBCAScope: returnValidState });
  };
  isValidBCAScope = () => {
    const returnValidState = isValidForm(
      this.state.selectedBuildingConScope,
      SLOTSMemberBCAScopeValidationRules,
      this.state.validStateBCAScope
    );
    this.setState({ validStateBCAScope: returnValidState });
    return returnValidState.isValid;
  };



  validateBasicApplication = (key) => {
    const returnValidState = validate(
      key,
      this.state.SLOTSApplicationDetails,
      SLOTSMemberBasicApplicationValidationRules,
      this.state.validStateBasicApplication
    );
    this.setState({ validStateBasicApplication: returnValidState });
  };
  isValidBasicApplication = () => {
    const returnValidState = isValidForm(
      this.state.SLOTSApplicationDetails,
      SLOTSMemberBasicApplicationValidationRules,
      this.state.validStateBasicApplication
    );
    this.setState({ validStateBasicApplication: returnValidState });
    return returnValidState.isValid;
  };





  validateOrganisationProfile = (key) => {
    const returnValidState = validate(
      key,
      this.state.SLOTSApplicationDetails,
      SLOTSMemberOrganisationProfileValidationRules,
      this.state.validStateOrganisationProfile
    );
    this.setState({ validStateOrganisationProfile: returnValidState });
  };
  isValidOrganisationProfile = () => {
    const returnValidState = isValidForm(
      this.state.SLOTSApplicationDetails,
      SLOTSMemberOrganisationProfileValidationRules,
      this.state.validStateOrganisationProfile
    );
    this.setState({ validStateOrganisationProfile: returnValidState });
    return returnValidState.isValid;
  };


  // SLOTS Member Registration Scope validation Method Start
  validateRegistrationScope = (key) => {
    const returnValidState = validate(
      key,
      this.state.selectedSLOTSRegistrationScope,
      SLOTSMemberRegistrationScopeValidationRules,
      this.state.validStateregistrationScope
    );
    this.setState({ validStateregistrationScope: returnValidState });
  };
  isValidRegistrationScope = () => {
    const returnValidState = isValidForm(
      this.state.selectedSLOTSRegistrationScope,
      SLOTSMemberRegistrationScopeValidationRules,
      this.state.validStateregistrationScope
    );
    this.setState({ validStateregistrationScope: returnValidState });
    return returnValidState.isValid;
  };

  // Internal Data Handling Method
  // Method For Handle SLOTS Member Inputs
  handleChange = (event, identity, key, representativeId) => {
    let value = event.target.value ? event.target.value.toUpperCase() : "";
    let name = event.target.name;
    if (identity === "SLOTS_Information") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      if (name === 'uenNumber') {
        // this fields is disabled
      } else {
        detail[name] = value;
      }
      this.setState({ SLOTSApplicationDetails: detail });
    } else if (identity === "Roles_Details") {
      let detail = this.state.roleDetails;
      detail[name] = value;
      this.setState({ roleDetails: detail });
    } else if (identity === "Role_Checkboxes") {
      let detail = this.state.roleDetails;
      detail[name] = !detail[name];
      this.setState({ roleDetails: detail });
    } else if (identity === "Radio_Company_Activities") {
      let detail = this.state.SLOTSApplicationDetails;
      detail[name] = !detail[name];
      this.setState({ SLOTSApplicationDetails: detail });
    } else if (identity === "Add_Organisation_Role") {
      if (this.isValidSLOTSMemberRole()) {
        let detail = { ...this.state.SLOTSApplicationDetails };
        const roleDetails = { ...this.state.roleDetails };
        if (roleDetails.authorisedRepresentativeId > 0) {
          const index = detail.authorisedRepresentativeSummaryList.findIndex(
            rep => rep.authorisedRepresentativeId === roleDetails.authorisedRepresentativeId
          );
          if (index !== -1) {
            detail.authorisedRepresentativeSummaryList[index] = roleDetails;
          }
        } else {
          detail.authorisedRepresentativeSummaryList.push(roleDetails);
        }
        this.setState({ SLOTSApplicationDetails: detail }, () => {
          this.addEditAuthoriseRepresentativeSummary(roleDetails);
          this.resetHandler("Reset_Role_Section");
        });
      }
    }
    else if (identity === "Add_BCA_Scope") {
      if (this.isValidBCAScope()) {
        let detail = { ...this.state.SLOTSApplicationDetails };
        detail.registeredBCAScopeList.push(this.state.selectedBuildingConScope);
        this.setState({ SLOTSApplicationDetails: detail });
        this.resetHandler("Reset_BCA_Scope_Section");
      }
    } else if (identity === "Add_SLOTS_Registration_Scope") {
      if (this.state.SLOTSApplicationDetails.statusId === 18 || this.state.SLOTSApplicationDetails.statusId === 22) {
        this.swalServices.Error("Registration head is not allowed to update");
      }
      else {
        if (this.isValidRegistrationScope()) {
          let detail = { ...this.state.SLOTSApplicationDetails };
          let oldobj = [...detail.slotsRegistrationHeadScopeTopUp];
          let isExist = false;
          // detail.slotsRegistrationHeadScope.push(this.state.selectedSLOTSRegistrationScope);
          // this part tem commented
          let regScope = this.state.selectedSLOTSRegistrationScope;
          //    if(detail.slotsRegistrationHeadScope.length > 0){
          //     detail.slotsRegistrationHeadScope.forEach(element => {
          //         if(element.workheadName === regScope.workheadName){
          //             isExist = true;
          //         }
          //     })

          //    }

          if (detail.slotsRegistrationHeadScopeTopUp.length > 0) {
            detail.slotsRegistrationHeadScopeTopUp.forEach((element) => {
              if (element.workheadName === regScope.workheadName) {
                isExist = true;
              }
            });
          }
          if (isExist === true) {
            this.swalServices.Error("You cannot duplicate ");
            this.resetHandler("Reset_SLOTS_Registration_Section");
          } else {
            //add here api call for add registration head TopUp++

            //after
            let request = {
              mainGroupId:
                this.state.selectedSLOTSRegistrationScope.selectedMainGroupId,
              workheadId:
                this.state.selectedSLOTSRegistrationScope.selectedWorkheadId,
              sLOTSMemberId: this.state.SLOTSApplicationDetails.slotsMemberId,
              isRenewal: this.state.SLOTSApplicationDetails.isRenewal,
              // clarificationDetails: this.state.clarification,
            };
            //let request =  this.state.SLOTSApplicationDetails;
            this.setState({ isLoading: true });

            if (isExist === false) {
              //  detail.slotsRegistrationHeadScopeTopUp.push(this.state.selectedSLOTSRegistrationScope);
              detail.slotsRegistrationHeadScopeTopUp.push(
                this.state.selectedSLOTSRegistrationScope
              );
              this.setState({ SLOTSApplicationDetails: detail });
              //var creaditNoteId = this.state.SLOTSApplicationDetails.CreditNoteStatusId;
              //if (creaditNoteId != 2) {
              this.pricingCalculation();
              //}
            }

            let oldPrice = this.state.SLOTSApplicationDetails.priceCalculator;
            if (this.state.SLOTSApplicationDetails.statusId === 4) {
              if (this.state.priceCalculatorArray[2].Amount <= oldPrice[2].amount && oldPrice.length > 0) {
                request.sameCategoryAmount = this.state.priceCalculatorArray[0].Amount;
                request.sameCategoryQuantity = this.state.priceCalculatorArray[0].quantity;
                request.existingCategoryAmount = this.state.priceCalculatorArray[1].Amount;
                request.existingCategoryQuantity = this.state.priceCalculatorArray[1].quantity;
                request.subtotalAmount = this.state.priceCalculatorArray[2].Amount;
                request.subtotalQuantity = this.state.priceCalculatorArray[2].quantity;
                request.updatedBy = this.state.updatedBy;
                request.updatedAppId = this.state.updatedAppId;
                this.slotMemberService
                  .AddSLOTSRegistrationHeadScopeTopUp(request)
                  .then((response) => {
                    if (
                      response.statusCode === 200 &&
                      response.responseItem != null
                    ) {
                      this.state.selectedSLOTSRegistrationScope.scopeId =
                        response.responseItem.responseContent.keyId;
                      //   this.swalServices.Success("Success");

                      this.resetHandler("Reset_SLOTS_Registration_Section");
                    } else {
                      this.resetHandler("Reset_SLOTS_Registration_Section");
                      this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                  });
              } else {
                detail.slotsRegistrationHeadScopeTopUp = [...oldobj];
                this.setState({ SLOTSApplicationDetails: detail });

                this.resetHandler("Reset_SLOTS_Registration_Section");
                this.swalServices.Error("Update registration head is not allowed. because your seleted registration head pricing more then our current selected registration. if you stil wants to add then use top-up functionality");
                this.setState({ isLoading: false }, () => {
                  this.pricingCalculation();
                });

              }
            } else {
              if (oldPrice.length > 0) {

                //if ((this.state.priceCalculatorArray[2].Amount <= oldPrice[2].amount && oldPrice.length > 0) || (this.state.SLOTSApplicationDetails.statusId === 1 || this.state.SLOTSApplicationDetails.statusId === 2 || this.state.SLOTSApplicationDetails.statusId === 15 || this.state.SLOTSApplicationDetails.statusId === 17)) {
                if (
                  //(this.state.priceCalculatorArray[2].Amount <= oldPrice[2].amount ||
                  (this.state.SLOTSApplicationDetails.statusId === 1 || this.state.SLOTSApplicationDetails.statusId === 2 || this.state.SLOTSApplicationDetails.statusId === 15 || this.state.SLOTSApplicationDetails.statusId === 16 || this.state.SLOTSApplicationDetails.statusId === 17)
                  && (this.state.SLOTSApplicationDetails.statusId !== 18 || this.state.SLOTSApplicationDetails.statusId !== 22)) {
                  //if ((oldPrice.length > 0) && (this.state.SLOTSApplicationDetails.statusId !== 18 || this.state.SLOTSApplicationDetails.statusId !== 22)) {
                  request.sameCategoryAmount = this.state.priceCalculatorArray[0].Amount;
                  request.sameCategoryQuantity = this.state.priceCalculatorArray[0].quantity;
                  request.existingCategoryAmount = this.state.priceCalculatorArray[1].Amount;
                  request.existingCategoryQuantity = this.state.priceCalculatorArray[1].quantity;
                  request.subtotalAmount = this.state.priceCalculatorArray[2].Amount;
                  request.subtotalQuantity = this.state.priceCalculatorArray[2].quantity;
                  this.slotMemberService
                    .AddSLOTSRegistrationHeadScopeTopUp(request)
                    .then((response) => {
                      if (
                        response.statusCode === 200 &&
                        response.responseItem != null
                      ) {
                        this.state.selectedSLOTSRegistrationScope.scopeId =
                          response.responseItem.responseContent.keyId;
                        //   this.swalServices.Success("Success");

                        this.resetHandler("Reset_SLOTS_Registration_Section");
                      } else {
                        this.resetHandler("Reset_SLOTS_Registration_Section");
                        this.swalServices.Error(response.message);
                      }
                      this.setState({ isLoading: false });
                    });
                } else {
                  detail.slotsRegistrationHeadScopeTopUp = [...oldobj];
                  this.setState({ SLOTSApplicationDetails: detail });

                  this.resetHandler("Reset_SLOTS_Registration_Section");
                  this.swalServices.Error("Update registration head is not allowed");
                  this.setState({ isLoading: false }, () => {
                    this.pricingCalculation();
                  });
                }
              } else {
                detail.slotsRegistrationHeadScopeTopUp = [...oldobj];
                this.setState({ SLOTSApplicationDetails: detail });

                this.resetHandler("Reset_SLOTS_Registration_Section");
                this.swalServices.Error("Update registration head is not allowed");
                this.setState({ isLoading: false }, () => {
                  //if (creaditNoteId != 2) {
                  this.pricingCalculation();
                  //}
                });
              }
            }
          }
        }
      }


    } else if (identity === "Add_SLOTS_Registration_Scope_Additional") {
      if (this.isValidRegistrationScope()) {
        let detail = { ...this.state.SLOTSApplicationDetails };
        let oldobj = [...detail.slotsRegistrationHeadScopeTopUpAdditional];
        let isExist = false;
        // this part tem commented
        let regScope = this.state.selectedSLOTSRegistrationScope;
        if (detail.slotsRegistrationHeadScopeTopUpAdditional.length > 0) {
          detail.slotsRegistrationHeadScopeTopUpAdditional.forEach((element) => {
            if (element.workheadName === regScope.workheadName) {
              isExist = true;
            }
          });
        }

        if (detail.slotsRegistrationHeadScopeTopUp.length > 0) {
          detail.slotsRegistrationHeadScopeTopUp.forEach(element => {
            if (element.workheadName === regScope.workheadName) {
              isExist = true;
            }
          })

        }

        if (isExist === true) {
          this.swalServices.Error("You cannot duplicate ");
          this.resetHandler("Reset_SLOTS_Registration_Section");
        } else {

          //after
          let request = {
            mainGroupId:
              this.state.selectedSLOTSRegistrationScope.selectedMainGroupId,
            workheadId:
              this.state.selectedSLOTSRegistrationScope.selectedWorkheadId,
            sLOTSMemberId: this.state.SLOTSApplicationDetails.slotsMemberId,
            isRenewal: this.state.SLOTSApplicationDetails.isRenewal,

          };

          this.setState({ isLoading: true });

          if (isExist === false) {

            detail.slotsRegistrationHeadScopeTopUpAdditional.push(
              this.state.selectedSLOTSRegistrationScope
            );
            this.setState({ SLOTSApplicationDetails: detail });

            this.pricingCalculationAdditional();

          }
          if (this.state.priceCalculatorArrayTopUpAdditional[2].Amount !== 0) {

            this.slotMemberService
              .AddSLOTSRegistrationHeadScopeTopUpAdditional(request)
              .then((response) => {
                if (
                  response.statusCode === 200 &&
                  response.responseItem != null
                ) {
                  this.state.selectedSLOTSRegistrationScope.scopeId =
                    response.responseItem.responseContent.keyId;

                  this.getSLOTSMemberDetailByMemberId(this.state.slotsMemberId);
                  this.pricingCalculationAdditional();

                  this.resetHandler("Reset_SLOTS_Registration_Section");
                } else {
                  this.resetHandler("Reset_SLOTS_Registration_Section");
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              });

          } else {
            detail.slotsRegistrationHeadScopeTopUpAdditional = [...oldobj];
            this.setState({ SLOTSApplicationDetails: detail });
            this.resetHandler("Reset_SLOTS_Registration_Section");
            this.swalServices.Error("You cannot add this registration TredHead(s) as TopUp Additional,But You can update registration TredHead(s) ");
            this.pricingCalculationAdditional();
            this.setState({ isLoading: false });
          }

        }
      }
    }
    else if (identity === "Remove_Organisation_Role") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      detail.authorisedRepresentativeSummaryList.splice(key, 1);
      this.setState({ SLOTSApplicationDetails: detail });
      this.removeSLOTSAuthoriseRepresentativeSummary(representativeId);
    } else if (identity === "Remove_BCA_Scope") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      detail.registeredBCAScopeList.splice(key, 1);
      this.setState({ SLOTSApplicationDetails: detail });
    } else if (identity === "Remove_SLOTS_Registration_Scope") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      detail.slotsRegistrationHeadScope.splice(key, 1);
      this.setState({ SLOTSApplicationDetails: detail });
    } else if (identity === "Remove_SLOTS_Registration_Scope_topup") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      detail.slotsRegistrationHeadScopeTopUp.splice(key, 1);
      this.setState({ SLOTSApplicationDetails: detail });
      this.pricingCalculation();
    }
  };

  // Method to add roles and scope
  handleAddRolesAndScope = (scope) => {
    if (scope === "BCA_Scope") {
      if (this.isValidBCAScope()) {
        let data = { ...this.state.SLOTSApplicationDetails };
        let bcaScope = this.state.selectedBuildingConScope;
        let isExist = false;
        if (data.registeredBCAScopeList.length > 0) {
          data.registeredBCAScopeList.forEach((element) => {
            if (

              element.workheadName === bcaScope.workheadName &&
              element.gradeName === bcaScope.gradeName
            ) {
              isExist = true;
            }
          });
        }
        if (isExist === true) {
          this.swalServices.Error("you cannot duplicate role");
        } else {
          data.registeredBCAScopeList.push(this.state.selectedBuildingConScope);
          this.resetHandler("Reset_BCA_Scope_Section");
        }
        this.setState({ SLOTSApplicationDetails: data });
      }
    }
  };

  // Method to remove roles and scope
  handleRemoveRolesAndScope = (key, scope) => {
    if (scope === "BCA_Scope") {
      const list = { ...this.state.SLOTSApplicationDetails };
      list.registeredBCAScopeList.splice(key, 1);
      this.setState({ SLOTSApplicationDetails: list });
    }
  };

  updateSLOTSRegistrationHeadScopeTopUp = () => {
    let request = {};
    request.sameCategoryAmount = this.state.priceCalculatorArray[0].Amount;
    request.sameCategoryQuantity = this.state.priceCalculatorArray[0].quantity;
    request.existingCategoryAmount = this.state.priceCalculatorArray[1].Amount;
    request.existingCategoryQuantity = this.state.priceCalculatorArray[1].quantity;
    request.subtotalAmount = this.state.priceCalculatorArray[2].Amount;
    request.subtotalQuantity = this.state.priceCalculatorArray[2].quantity;
    request.slotsMemberId = this.state.slotsMemberId;
    request.updatedBy = this.state.updatedBy;
    request.updatedAppId = this.state.updatedAppId;

    this.slotMemberService
      .updateSLOTSRegistrationHeadScopeTopUp(request)
      .then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem != null
        ) {
          this.swalServices.Success("Update successfully.");
          this.getSLOTSMemberDetailByMemberId(this.state.slotsMemberId);
        } else {
          this.swalServices.Error(response.message);
        }
      });
  }

  removeRegistrationHead = (event, key) => {

    if (this.state.SLOTSApplicationDetails.statusId === 18 || this.state.SLOTSApplicationDetails.statusId === 22) {
      this.swalServices.Error("Registration head is not allowed to delete.");
    } else {

      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_RemoveSlotsRegistrationHeadTopUp.replace(
            "{0}",
            "Admin"
          ),
          "",
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          if (response) {
            let detail = { ...this.state.SLOTSApplicationDetails };
            this.removeSLOTSRegistrationHeadScopeTopUp(
              detail.slotsRegistrationHeadScopeTopUp[key].scopeId
            );
            detail.slotsRegistrationHeadScopeTopUp.splice(key, 1);
            this.setState({ SLOTSApplicationDetails: detail });
            //var creaditNoteId = this.state.SLOTSApplicationDetails.CreditNoteStatusId;
            //if (creaditNoteId != 2) {
            this.pricingCalculation();
            //}
            this.setState({ SLOTSApplicationDetails: detail }, () => {
              if ((this.state.SLOTSApplicationDetails.statusId === 1 || this.state.SLOTSApplicationDetails.statusId === 2 || this.state.SLOTSApplicationDetails.statusId === 15 || this.state.SLOTSApplicationDetails.statusId === 17)
                && (this.state.SLOTSApplicationDetails.statusId !== 18 || this.state.SLOTSApplicationDetails.statusId !== 22)) {
                this.setSLOTsMemberPricing();
              }
            });
          }
        });
    }

  };

  setSLOTsMemberPricing = () => {
    // 
    let request = {};
    request.slotsMemberId = this.state.slotsMemberId;
    request.sameCategoryAmount = this.state.priceCalculatorArray[0].Amount;
    request.sameCategoryQuantity = this.state.priceCalculatorArray[0].quantity;
    request.existingCategoryAmount = this.state.priceCalculatorArray[1].Amount;
    request.existingCategoryQuantity = this.state.priceCalculatorArray[1].quantity;
    request.subtotalAmount = this.state.priceCalculatorArray[2].Amount;
    request.subtotalQuantity = this.state.priceCalculatorArray[2].quantity;
    this.slotMemberService
      .setSLOTsMemberPricing(request)
      .then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem != null
        ) {
          //this.state.selectedSLOTSRegistrationScope.scopeId =
          // response.responseItem.responseContent.keyId;
          //   this.swalServices.Success("Success");

          //this.resetHandler("Reset_SLOTS_Registration_Section");
        }
        this.setState({ isLoading: false });
      });
  }

  removeRegistrationHeadAdditional = (event, key) => {
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_RemoveSlotsRegistrationHeadTopUpAdditional.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          let detail = { ...this.state.SLOTSApplicationDetails };
          this.removeSLOTSRegistrationHeadScopeTopUpAdditional(
            detail.slotsRegistrationHeadScopeTopUpAdditional[key].scopeId
          );
          detail.slotsRegistrationHeadScopeTopUpAdditional.splice(key, 1);
          this.setState({ SLOTSApplicationDetails: detail });
          this.pricingCalculationAdditional();
        }
      });
  };

  saveClarificationDetails = () => {
    let isValidInput = this.isValidateAllFields1();
    if (isValidInput) {
      this.setState({ setSendClarifications: true });
      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_SendClarifications.replace(
            "{0}",
            "Admin"
          ),
          "",
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        ).then((response) => {
          if (response) {
            let request = {
              slotMemberId: this.state.SLOTSApplicationDetails.slotsMemberId,
              clarificationDetails: this.state.clarification,
              statusId: this.state.SLOTSApplicationDetails.statusId,
              updatedBy:this.state.updatedBy,
              updatedAppId:this.state.updatedAppId
            };
            //
            this.setState({ isLoading: true });
            this.slotMemberService
              .SaveSlotMemberClarificationDetails(request)
              .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  this.swalServices.Success("Clarification mail sent successfully");
                  this.setState({ setSendClarifications: false });
                  this.setState({ clarification: "" });
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              });
          } else {
            this.setState({ isLoading: false });
            this.setState({ setSendClarifications: false });

          }
        });
    }
  };

  saveSendRejectionReasonDetails = () => {
    let request = {
      slotMemberId: this.state.SLOTSApplicationDetails.slotsMemberId,
      clarificationDetails: this.state.sendRejectionReason,
    };
    this.setState({ isLoadingRejectAplication: true });
    this.slotMemberService
      .SaveSlotMemberClarificationDetails(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Clarification mail sent successfully");
          this.setState({ setRejectionReason: false });
          this.setState({ sendRejectionReason: "" });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingRejectAplication: false });
      });
  };

  editSlotsMemberDetails = () => {
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_update_slotsMemebr_Information.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      ).then((response) => {
        if (response) {
          if (this.isValidSLOTSMember()) {
            let request = this.state.SLOTSApplicationDetails;
            request.updatedBy = this.state.updatedBy;
            request.updatedAppId = this.state.updatedAppId;
            if (request.isCompanyActivities === false) {
              request.registeredBCAScopeList = [];
            }

            this.setState({ isLoading: true });
            this.slotMemberService
              .EditSlotsMemberDetails(request)
              .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {

                  this.swalServices.Success(
                    "Slots Member Details Updated successfully"
                  );

                  this.getSLOTSMemberDetailByMemberId(
                    this.state.SLOTSApplicationDetails.slotsMemberId
                  );
                  this.getAllDropDownsForSLOTSMember();
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              });
          }
        }
      });
  };

  rejectSLOTSMemberApplication = (flag) => {
    let reject = this.state.sendRejectionReason;


    if ((reject && reject !== null) || flag === "UnReject") {
      let request = {};
      if (flag !== "UnReject") {

      }
      request.slotsMemberId = this.state.SLOTSApplicationDetails.slotsMemberId;
      request.sendRejectionReason = reject;
      if (flag === "Rejected") {
        request.isRejected = true;
      } else if (flag === "UnReject") {
        request.isRejected = false;
      }
      this.setState({ isLoadingRejectAplication: true });

      request.updatedBy = this.state.updatedBy;
      request.updatedAppId=this.state.updatedAppId;

      this.swalServices
        .Confirm(
          request.isRejected === false ? CommonSuccessMessages.Confirm_Appeal_Process.replace(
            "{0}",
            "Admin"
          ) : CommonSuccessMessages.Confirm_Reject_Application.replace(
            "{0}",
            "Admin"
          ),
          "",
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        ).then((response) => {
          if (response) {
            this.slotMemberService
              .rejectSLOTSMemberApplication(request)
              .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  this.swalServices.Success(`Slots Member ${flag} successfully`);
                  this.setState({ route: "/Membership/SLOTAccreditation" });
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoadingRejectAplication: false });
              });
          } else {
            this.setState({ isLoadingRejectAplication: false });

          }

        });

    } else {
      this.swalServices.Error("Please enter detail");
    }
  };

  ExpireSlotsMemberApplication = () => {

    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_ExpireSlots.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      ).then((response) => {
        if (response) {
          this.setState({ isLoadingExpireApplication: true });

          this.slotMemberService
            .ExpireSlotsMemberApplication(
              this.state.SLOTSApplicationDetails.slotsMemberId
            )
            .then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success(`Slots member exprire successfully`);
                this.getSLOTSMemberDetailByMemberId(
                  this.state.SLOTSApplicationDetails.slotsMemberId
                );
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoadingExpireApplication: false });
            });
        }
      })


  };

  handleChangeClarificationDetails = (data) => {
    if (typeof data === "string") {
      this.setState({
        clarification: data,
      });
    }
  };

  handleChangeSendRejectionReasonDetails = (data) => {
    if (typeof data === "string") {
      this.setState({
        sendRejectionReason: data,
      });
      this.setState({ sendRejectionReason: data });
    }

  };

  setSendClarificationsFlag = () => {
    this.setState({ setSendClarifications: true });
  };

  setSendRejectionReasonFlag = () => {
    this.setState({ setRejectionReason: true });
  };

  sendReconciliationUploadWorkshopProofLinkToSlots = () => {
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_SendWorkShopProffUploadLink.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      ).then((response) => {
        if (response) {
          let request = {
            slotsMemberId: this.state.SLOTSApplicationDetails.slotsMemberId,
            companyName: this.state.SLOTSApplicationDetails.companyName,
            firstName: this.state.SLOTSApplicationDetails.headOfCompanyFirstName,
            lastName: this.state.SLOTSApplicationDetails.headOfCompanyLastName,
            email: this.state.SLOTSApplicationDetails.businessEmail,
          };
          this.setState({ isWorkshopProofLoader: true });
          this.slotMemberService
            .SendReconciliationUploadWorkshopProofLinkToSlots(request)
            .then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success(
                  "Upload Workshop Proof Link mail sent successfully"
                );
                this.setState({ setSendClarifications: false });
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isWorkshopProofLoader: false });
            });
        }
      });

  };

  // Handle Change Method For Dropdown Select
  handleChangeInDropdown = (selected, identity) => {
    if (identity === "Country") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      if (selected.value === "*") {
        detail.countryId = 0;
      } else {
        detail.countryId = selected.value;
      }

      this.setState({ SLOTSApplicationDetails: detail }, () => {
        this.validateSLOTSMember(
          "countryId",
          this.state.SLOTSApplicationDetails
        );
      });
    } else if (identity === "Annual_Turnover") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      if (selected.value === "*") {
        detail.annualTurnOverId = 0;
      } else {
        detail.annualTurnOverId = selected.value;
      }
      this.setState({ SLOTSApplicationDetails: detail }, () => {
        this.validateSLOTSMember(
          "annualTurnOverId",
          this.state.SLOTSApplicationDetails
        );
      });
    } else if (identity === "Staff_Employee_Local") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      if (selected.value === "*") {
        detail.numberOfStaffEmployedLocalId = 0;
      } else {
        detail.numberOfStaffEmployedLocalId = selected.value;
      }

      this.setState({ SLOTSApplicationDetails: detail }, () => {
        this.validateSLOTSMember(
          "numberOfStaffEmployedLocalId",
          this.state.SLOTSApplicationDetails
        );
      });
    } else if (identity === "Role") {
      let detail = { ...this.state.roleDetails };
      detail.roleId = selected.value;
      detail.roles = selected.label;
      this.setState({ roleDetails: detail }, () => {
        this.validateSLOTSMemberRole("roleId", this.state.roleDetails);
      });
    } else if (identity === "Main_Group_Scope1") {
      let detail = { ...this.state.selectedBuildingConScope };
      detail.mainGroupId = selected.value;
      detail.mainGroupName = selected.label;
      detail.workheadId = 0;
      detail.gradeId = 0;
      detail.workheadName = "";
      detail.gradeName = "";
      this.setState(
        { selectedBuildingConScope: detail, workheadList: [], gradeList: [] },
        () => {
          this.getDropdownDataOfWorkheadAndGrade(selected.value, "Workhead");
          this.validateBCAScope(
            "mainGroupId",
            this.state.selectedBuildingConScope
          );
        }
      );
    } else if (identity === "Workhead_Scope1") {
      let detail = { ...this.state.selectedBuildingConScope };
      detail.workheadId = selected.value;
      detail.workheadName = selected.label;
      detail.gradeId = 0;
      detail.gradeName = "";
      this.setState({ selectedBuildingConScope: detail, gradeList: [] }, () => {
        this.getDropdownDataOfWorkheadAndGrade(selected.value, "Grade");
        this.validateBCAScope(
          "workheadId",
          this.state.selectedBuildingConScope
        );
      });
    } else if (identity === "Grade_Scope1") {
      let detail = { ...this.state.selectedBuildingConScope };
      detail.gradeId = selected.value;
      detail.gradeName = selected.label;
      this.setState({ selectedBuildingConScope: detail }, () =>
        this.validateBCAScope("gradeId", this.state.selectedBuildingConScope)
      );
    } else if (identity === "Main_Group_Scope2") {
      let detail = { ...this.state.selectedSLOTSRegistrationScope };
      detail.selectedMainGroupId = selected.value;
      detail.mainGroupName = selected.label;
      detail.selectedWorkheadId = 0;
      detail.workheadName = "";
      detail.isTopup = true;
      this.setState(
        { selectedSLOTSRegistrationScope: detail, workheadListPricing: [] },
        () => {
          this.getDropdownDataOfWorkheadAndGrade(selected.value, "Workhead2");
        }
      );
    } else if (identity === "Workhead_Scope2") {
      let detail = { ...this.state.selectedSLOTSRegistrationScope };
      if (selected.value === "*") {
        detail.selectedWorkheadId = 0;
      } else {
        detail.selectedWorkheadId = selected.value;
      }
      detail.workheadName = selected.label;
      detail.isTopup = true;
      this.setState({ selectedSLOTSRegistrationScope: detail });
    } else if (identity === "Foreign_WorkPermit_Holder") {
      let detail = { ...this.state.SLOTSApplicationDetails };
      if (selected.value === "*") {
        detail.foreignWorkPermitHolderId = 0;
      } else {
        detail.foreignWorkPermitHolderId = selected.value;
        detail.foreignWorkPermitHolderLabel = selected.label;
      }

      this.setState({ SLOTSApplicationDetails: detail }, () => {
        this.validateSLOTSMember(
          "foreignWorkPermitHolderId",
          this.state.SLOTSApplicationDetails
        );
      });
    }
  };

  resetHandler = (section) => {
    if (section === "Reset_Role_Section") {
      let reset = { ...this.state.roleDetails };
      reset.authorisedRepresentativeId = 0;
      reset.roleId = 0;
      reset.roles = "";
      reset.firstName = "";
      reset.lastName = "";
      reset.jobTitle = "";
      reset.directOfficeNumber = "";
      reset.mobileNumber = "";
      reset.emailAddress = "";
      reset.isNewsletter = false;
      reset.isTelegram = false;
      reset.isePortal = false;
      this.setState({ roleDetails: reset });
    } else if (section === "Reset_BCA_Scope_Section") {
      let reset = { ...this.state.selectedBuildingConScope };
      reset.scopeId = 0;
      reset.mainGroupId = 0;
      reset.workheadId = 0;
      reset.gradeId = 0;
      reset.mainGroupName = "";
      reset.workheadName = "";
      reset.gradeName = "";
      this.setState({ selectedBuildingConScope: reset });
    } else if (section === "Reset_SLOTS_Registration_Section") {
      let reset = { ...this.state.selectedSLOTSRegistrationScope };
      reset.scopeId = 0;
      reset.selectedMainGroupId = 0;
      reset.selectedWorkheadId = 0;
      reset.mainGroupName = "";
      reset.workheadName = "";
      this.setState({ selectedSLOTSRegistrationScope: reset });
    }
  };

  sendPaymentLinkOfSLOTSMember = () => {
    this.setState({ isPaymentLinkLoader: true });
    this.slotMemberService
      .sendPaymentLinkOfSLOTSMember(this.state.slotsMemberId)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            this.swalServices.Success(
              "Payment Link has been sent successfully."
            );
            this.getSLOTSMemberDetailByMemberId(this.state.slotsMemberId);
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isPaymentLinkLoader: false });
      });
  };

  acceptRenewSLOTSMembership = () => {
    this.setState({ isPaymentLinkLoader: true });
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_PaymentProffLinkForRenewal.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {

          this.slotMemberService
            .acceptRenewSLOTSMembership(this.state.slotsMemberId)
            .then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                if (response.responseItem.responseContent != null) {
                  this.swalServices.Success(
                    "Payment Link has been sent for renewal successfully."
                  );

                  let res = { ...this.state.SLOTSApplicationDetails };
                  res.isPaymentLinkSentForRenewal = true;
                  this.setState({
                    SLOTSApplicationDetails: res
                  })
                }
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isPaymentLinkLoader: false });
            });
        } else {
          this.setState({ isPaymentLinkLoader: true });
        }
      });
  };

  GetSlotsMemberFiles = (type, Filepath) => {
    if (Filepath) {
      let request = [
        this.state.SLOTSApplicationDetails.slotsMemberId,
        type,
        Filepath,
      ];
      this.slotMemberService.GetSlotsMemberFiles(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
          var filename = "";
          filename = `${type}${formattedDate}`;
          const extension = Filepath.split(".").pop();
          if (extension) {
            saveAs(new Blob([response.data]), `${filename}.${extension}`);
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {
      this.swalServices.Error("No data found");
    }
  };

  DownloadMultipleFilesZip = (type) => {

    let request = {};
    if (type === "ACRACertificates") {
      request = {
        FileList: this.state.arcaFiles,
        Type: type,
      };
    } else if (type === "WPOnlineRecord") {
      request = {
        FileList: this.state.wpOnlineRecordFiles,
        //FileList: this.state.SLOTSApplicationDetails.fileListOfWPRecord,
        Type: type,
      };
    } else if (type === "Latest6MonthsCPFcontribution") {
      request = {
        FileList: this.state.cPFcontributionFiles,
        Type: type,
      };
    } else if (type === "CopyOfCPFContributionsOfCompletedProjects") {
      request = {
        FileList: this.state.copyOfCPFContributionsOfCompletedProjectsList,
        Type: type,
      };
    } else if (type === "ApprovedTechnicalQualification") {
      request = {
        FileList: this.state.approvedTechnicalQualificationList,
        Type: type,
      };
    } else if (type === "BankAccountStatements") {
      request = {
        FileList: this.state.companyBankFiles,
        Type: type,
      };
    } else if (type === "supportingDocFormAOngoing") {
      request = {
        FileList: this.state.supportingDocFormAOngoingList,
        Type: type,
      };
    } else if (type === "supportingDocFormAOnComplete") {
      request = {
        FileList: this.state.supportingDocFormAOnCompleteList,
        Type: type,
      };
    } else if (type === "SupportingDocFormA") {
      request = {
        FileList: this.state.supportingDocFormAList,
        Type: type,
      };
    }
    if (request.FileList && request.FileList.length > 0) {
      this.setState({ isLoading: true });
      this.commonServices.DownloadMultipleFilesZip(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var blob = new Blob([response.data]);
          saveAs(blob, type + ".zip");
          this.setState({ isCreatingZip: false });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.swalServices.Error("No data found");
    }
  };

  // On upload file change handle method
  onFileChange = (event, fileType) => {

    if (this.state.slotsMemberId > 0) {
      const file = event.target.files[0];
      if (file) {
        if (file.size < 5242880) {
          if (fileType === "Upload_WP_Record") {
            if (this.state.wPOnlineRecordCount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                file.type === "application/pdf" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword" ||
                file.type === "application/msexcel" ||
                file.type === "application/vnd.ms-excel"
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.storagePath = "SLOTSMemberPDFs\\WPOnlineRecord";
                this.setState({ isWPRecordLoader: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  if (response !== null) {
                    if (response.isNotCurrupted === true) {
                      let detail = { ...this.state.SLOTSApplicationDetails };
                      detail["uploadWPOnlineRecord"] = response.fileName;
                      detail.fileListOfWPRecord.push(response.fileName);
                      this.setState(
                        {
                          wpOnlineRecordFiles: detail.fileListOfWPRecord.map((p) => ({ path: p })),
                          SLOTSApplicationDetails: detail,
                          wPOnlineRecordCount: this.state.wPOnlineRecordCount + 1,
                        },
                        () =>
                          this.validateSLOTSMember(
                            "uploadWPOnlineRecord",
                            this.state.SLOTSApplicationDetails
                          )
                      );
                      this.saveSLOTsDocuments(
                        "UploadWPOnlineRecord",
                        "uploadWPOnlineRecord",
                        "Organisation_Profile"
                      );

                    } else {
                      this.swalServices.Error("Invalid Document");
                    }
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isWPRecordLoader: false });
                });
              } else {
                this.swalServices.Error(
                  "Please upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
                );
              }
            } else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "CPF_Contribution") {
            if (this.state.cpfContributionLocalCount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                file.type === "application/pdf" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword" ||
                file.type === "application/msexcel"
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.uploadId = `UplaodFile${this.state.cpfContributionLocalCount + 1
                  }`;
                uploadFile.storagePath =
                  "SLOTSMemberPDFs\\Latest6MonthsCPFcontribution";
                this.setState({ is6MonCPFLoader: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  if (response !== null) {

                    if (response.isNotCurrupted === true) {

                      let detail = { ...this.state.SLOTSApplicationDetails };
                      detail["latest6monthsCPFContribution"] = response.fileName;
                      detail.fileList2.push(response.fileName);
                      this.setState({
                        cpfContributionLocalCount:
                          this.state.cpfContributionLocalCount + 1,
                      });
                      this.setState({
                        SLOTSApplicationDetails: detail,
                        cPFcontributionFiles: detail.fileList2.map((p) => ({ path: p })),
                      }, () =>
                        this.validateSLOTSMember(
                          "latest6monthsCPFContribution",
                          this.state.SLOTSApplicationDetails
                        )
                      );
                      this.saveSLOTsDocuments(
                        "Latest6monthsCPFContribution",
                        "latest6monthsCPFContribution",
                        "Organisation_Profile"
                      );
                    }
                    else {
                      this.swalServices.Error("Invalid Document");
                    }
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ is6MonCPFLoader: false });
                });
              } else {
                this.swalServices.Error(
                  "Please upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
                );
              }
            } else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "CPF_Complete_Contribution") {
            if (this.state.copyofcpfContributionLocalCount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                file.type === "application/pdf" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword" ||
                file.type === "application/msexcel"
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.uploadId = `UplaodFile${this.state.copyofcpfContributionLocalCount + 1
                  }`;
                uploadFile.storagePath =
                  "SLOTSMemberPDFs\\CopyOfCPFContributionsOfCompletedProjects";
                this.setState({ isCPFConLoader: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  if (response !== null) {
                    if (response.isNotCurrupted === true) {
                      let detail = { ...this.state.SLOTSApplicationDetails };
                      detail["copyOfCPFContributionsOfCompletedProjects"] =
                        response.fileName;
                      detail.fileListOfcopyofcpfContribution.push(
                        response.fileName
                      );
                      this.setState({
                        copyofcpfContributionLocalCount:
                          this.state.copyofcpfContributionLocalCount + 1,
                      });
                      this.setState({
                        SLOTSApplicationDetails: detail,
                        copyOfCPFContributionsOfCompletedProjectsList: detail.fileListOfcopyofcpfContribution.map((p) => ({ path: p })),
                      }, () =>
                        this.validateSLOTSMember(
                          "fileListOfcopyofcpfContribution",
                          this.state.SLOTSApplicationDetails
                        )
                      );
                      this.saveSLOTsDocuments(
                        "CopyOfCPFContributionsOfCompletedProjects",
                        "copyOfCPFContributionsOfCompletedProjects",
                        "Organisation_Profile"
                      );
                    }
                    else {
                      this.swalServices.Error(" Invalid Document");
                    }
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isCPFConLoader: false });
                });
              } else {
                this.swalServices.Error(
                  "Please upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
                );
              }
            } else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "Approved_technical_qualification") {
            if (this.state.approvedTechnicalQualificationCount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                file.type === "application/pdf" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword" ||
                file.type === "application/msexcel"
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.uploadId = `UplaodFile${this.state.approvedTechnicalQualificationCount + 1
                  }`;
                uploadFile.storagePath =
                  "SLOTSMemberPDFs\\ApprovedTechnicalQualification";
                this.setState({ isApprovedBCALoader: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  if (response !== null) {
                    if (response.isNotCurrupted === true) {


                      var detail = { ...this.state.SLOTSApplicationDetails };
                      detail["approvedTechnicalQualification"] = response.fileName;
                      detail.fileListOfApprovedTechnicalQualification.push(
                        response.fileName
                      );

                      this.setState({
                        approvedTechnicalQualificationCount:
                          this.state.approvedTechnicalQualificationCount + 1,
                      });
                      this.setState({
                        SLOTSApplicationDetails: detail
                        , approvedTechnicalQualificationList: detail.fileListOfApprovedTechnicalQualification.map((p) => ({ path: p }))
                      }, () =>
                        this.validateSLOTSMember(
                          "approvedTechnicalQualification",
                          this.state.SLOTSApplicationDetails
                        )
                      );
                      this.saveSLOTsDocuments(
                        "ApprovedTechnicalQualification",
                        "approvedTechnicalQualification",
                        "Organisation_Profile"
                      );

                    }
                    else {
                      this.swalServices.Error("Invalid Document");
                    }
                  }
                  else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isApprovedBCALoader: false });
                });
              } else {
                this.swalServices.Error(
                  "Please upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
                );
              }
            } else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "Bank_Account_Statement") {
            if (this.state.companyBankAccStatementCount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                file.type === "application/pdf" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword" ||
                file.type === "application/msexcel"
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.storagePath = "SLOTSMemberPDFs\\BankAccountStatements";
                this.setState({ isBankAccStatLoader: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  if (response !== null) {
                    if (response.isNotCurrupted === true) {
                      var detail = { ...this.state.SLOTSApplicationDetails };
                      detail["companyBankStatementsOf6Month"] = response.fileName;
                      detail.bankAccountStatementList.push(response.fileName);
                      this.setState({
                        companyBankAccStatementCount:
                          this.state.companyBankAccStatementCount + 1,
                      });
                      this.setState({
                        SLOTSApplicationDetails: detail,
                        companyBankFiles: detail.bankAccountStatementList.map((p) => ({ path: p }))
                      }, () =>
                        this.validateSLOTSMember(
                          "companyBankStatementsOf6Month",
                          this.state.SLOTSApplicationDetails
                        )
                      );
                      this.saveSLOTsDocuments(
                        "CompanyBankStatementsOf6Month",
                        "companyBankStatementsOf6Month",
                        "SLOTS_Application"
                      );

                    } else {
                      this.swalServices.Error(" Invalid Document");
                    }
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isBankAccStatLoader: false });
                });
              } else {
                this.swalServices.Error(
                  "Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB!"
                );
              }
            } else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "Upload_from_A") {
            if (
              (file && file.type === "application/pdf") ||
              file.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              file.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              file.type === "application/msword" ||
              file.type === "application/msexcel"
            ) {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath =
                "SLOTSMemberPDFs\\ProjectTrackRecordsOfOnGoingProject";

              this.setState({ isOnGoingProLoader: true });
              this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                if (response !== null) {
                  if (response.isNotCurrupted === true) {


                    let detail = this.state.SLOTSApplicationDetails;
                    //detail.projectTrackRecordsOfOnGoingProject = uploadFile.filename;
                    //detail.reportValidation = true;
                    detail.projectTrackRecordsOfOnGoingProject = response.fileName;
                    //this.swalServices.Success("File Uploaded");
                    //this.validateField("projectTrackRecordsOfOnGoingProject", "SLOTS_Application");
                    this.setState({ SLOTSApplicationDetails: detail }, () =>
                      this.validateSLOTSMember(
                        "projectTrackRecordsOfOnGoingProject",
                        this.state.SLOTSApplicationDetails
                      )
                    );
                    this.saveSLOTsDocuments(
                      "ProjectTrackRecordsOfOnGoingProject",
                      "projectTrackRecordsOfOnGoingProject",
                      "SLOTS_Application"
                    );
                  }
                  else {
                    this.swalServices.Error("Invalid Document");
                  }
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isOnGoingProLoader: false });
              });
            } else {
              this.swalServices.Error(
                "Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
              );
            }
          } else if (fileType === "supportingDocFormAOngoing") {
            if (this.state.supportingDocOngoingFileUploadCount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword" ||
                file.type === "application/msexcel"
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.uploadId = `UplaodFile${this.state.supportingDocOngoingFileUploadCount + 1
                  }`;
                uploadFile.storagePath =
                  "SLOTSMemberPDFs\\supportingDocFormAOngoing";
                this.setState({ isOnGoingProLoader2: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  if (response !== null) {
                    if (response.isNotCurrupted === true) {
                      var detail = this.state.SLOTSApplicationDetails;

                      detail["supportingDocFormAOngoing"] = response.fileName;
                      detail.formAOnGoingList.push(response.fileName);
                      this.setState(
                        {
                          supportingDocFormAOngoingList: detail.formAOnGoingList.map((p) => ({ path: p })),
                          LOTSApplicationDetails: detail,
                          supportingDocOngoingFileUploadCount: this.state.supportingDocOngoingFileUploadCount + 1,
                        },
                        () =>
                          this.validateSLOTSMember(
                            "supportingDocFormAOngoing",
                            this.state.SLOTSApplicationDetails
                          )
                      );
                      this.saveSLOTsDocuments(
                        "SupportingDocFormAOngoing",
                        null,
                        null
                      );
                    } else {
                      this.swalServices.Error("Invalid Document");
                    }
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isOnGoingProLoader2: false });
                });

              } else {
                this.swalServices.Error(
                  "Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
                );
              }
            } else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "supportingDocFormAOnComplete") {
            if (this.state.supportingDocCompleteFileUploadCount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type === "application/msword" ||
                file.type === "application/msexcel"
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.uploadId = `UplaodFile${this.state.supportingDocCompleteFileUploadCount + 1
                  }`;
                uploadFile.storagePath =
                  "SLOTSMemberPDFs\\supportingDocFormAOnComplete";
                this.setState({ isCompProLoader2: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  if (response !== null) {
                    if (response.isNotCurrupted === true) {
                      let detail = this.state.SLOTSApplicationDetails;
                      detail["supportingDocFormAOnComplete"] = response.fileName;
                      detail.formACompletedList.push(response.fileName);
                      this.setState({
                        supportingDocFormAOnCompleteList: detail.formACompletedList.map((p) => ({ path: p })),
                        supportingDocCompleteFileUploadCount:
                          this.state.supportingDocCompleteFileUploadCount + 1,
                      });
                      this.setState({ SLOTSApplicationDetails: detail }, () =>
                        this.validateSLOTSMember(
                          "supportingDocFormAOnComplete",
                          this.state.SLOTSApplicationDetails
                        )
                      );
                      this.saveSLOTsDocuments(
                        "SupportingDocFormAOnComplete",
                        null,
                        null
                      );
                    } else {
                      this.swalServices.Error("Invalid Document");
                    }
                  } else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isCompProLoader2: false });
                });
              } else {
                this.swalServices.Error(
                  "Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
                );
              }
            } else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "Upload_from_B") {
            if (
              (file && file.type === "application/pdf") ||
              file.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              file.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              file.type === "application/msword" ||
              file.type === "application/msexcel"
            ) {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath =
                "SLOTSMemberPDFs\\ProjectTrackRecordsOfOnCompletedProject";
              this.setState({ isCompProLoader: true });
              this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                if (response !== null) {
                  if (response.isNotCurrupted === true) {
                    let detail = this.state.SLOTSApplicationDetails;
                    detail.projectTrackRecordsOfCompletedProject =
                      response.fileName;
                    this.setState({ SLOTSApplicationDetails: detail }, () =>
                      this.validateSLOTSMember(
                        "projectTrackRecordsOfCompletedProject",
                        this.state.SLOTSApplicationDetails
                      )
                    );
                    this.saveSLOTsDocuments(
                      "ProjectTrackRecordsOfCompletedProject",
                      "projectTrackRecordsOfCompletedProject",
                      "SLOTS_Application"
                    );
                  } else {
                    this.swalServices.Error("Invalid Document");
                  }
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isCompProLoader: false });
              });
            } else {
              this.swalServices.Error(
                "Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
              );
            }
          } else if (fileType === "Upload_ACRA_Certificate") {
            if (
              (file && file.type === "application/pdf") ||
              file.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              file.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              file.type === "application/msword" ||
              file.type === "application/msexcel"
            ) {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "SLOTSMemberPDFs\\ACRACertificates";
              uploadFile.slotsMemberId =
                this.state.SLOTSApplicationDetails.slotsMemberId;
              this.setState({ isACRACerLoader: true });
              this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                  if (response.isNotCurrupted === true) {
                    let detail = this.state.SLOTSApplicationDetails;
                    detail.uploadACRACertificate = response.fileName;
                    this.setState({ SLOTSApplicationDetails: detail }, () =>
                      this.validateSLOTSMember(
                        "uploadACRACertificate",
                        this.state.SLOTSApplicationDetails
                      )
                    );
                    this.saveSLOTsDocuments(
                      "UploadACRACertificate",
                      "uploadACRACertificate",
                      "Organisation_Profile"
                    );
                  } else {
                    this.swalServices.Error("Invalid document");
                  }
                  // this.getSLOTSMemberDetailByMemberId(this.state.SLOTSApplicationDetails.slotsMemberId);
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isACRACerLoader: false });
              });
            } else {
              this.swalServices.Error(
                "Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
              );
            }
          } else if (fileType === "Upload_Payment_Proof") {
            //const file = event.target.files[0];
            if (
              file &&
              (file.type === "image/jpeg" || file.type === "image/png")
            ) {
              let request = {};
              request.storagePath = "PaymentImages";
              request.file = file;
              request.fileName = file.name;
              this.setState({ isPaymentProofLoader: true });
              this.slotMemberService.uploadPDF(request).then((response) => {
                if (response !== null) {
                  //if (request.storagePath === "PaymentImages") {
                  let detail = { ...this.state.SLOTSApplicationDetails };
                  detail.uploadPaymentProof = response.fileName;
                  //detail["fileDisplayName"] = file.name;
                  this.setState({ SLOTSApplicationDetails: detail }, () =>
                    this.validateSLOTSMember(
                      "uploadPaymentProof",
                      this.state.SLOTSApplicationDetails
                    )
                  );
                  this.saveSLOTsDocuments(
                    "UploadPaymentProof",
                    "uploadPaymentProof",
                    "SLOTS_Application"
                  );
                  // this.getSLOTSMemberDetailByMemberId(this.state.SLOTSApplicationDetails.slotsMemberId);
                  // }
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isPaymentProofLoader: false });
              });
            } else {
              this.swalServices.Error("Please select only Image");
            }
          } else if (fileType === "WorkshopProofPath" || fileType === "IAOOrbizSAFEStar" || fileType === "ISOOrGreenPolicy" || fileType === "AppointmentletterforSGSecure") {
            //const file = event.target.files[0];
            if ((file && file.type === "application/pdf") || (file.type === "image/jpeg") || (file.type === "image/jpg") || (file.type === "image/png")) {
              let request = {};
              request.storagePath = "UploadWorkshopProof";
              request.file = file;
              request.fileName = file.name;
              this.setState({ isPaymentProofLoader: true });
              this.slotMemberService.uploadPDF(request).then((response) => {
                if (response != null) {
                  //if (request.storagePath === "PaymentImages") {
                  let detail = { ...this.state.SLOTSApplicationDetails };
                  //detail.uploadPaymentProof = response.fileName;

                  //let detail = { ...this.state.SLOTSApplicationDetails };
                  if (fileType === 'WorkshopProofPath') {
                    detail.workshopProofPath = response.fileName;
                  } else if (fileType === 'IAOOrbizSAFEStar') {
                    detail.iaoOrbizSAFEStar = response.fileName;
                  } else if (fileType === 'ISOOrGreenPolicy') {
                    detail.isoOrGreenPolicy = response.fileName;
                  } else if (fileType === 'AppointmentletterforSGSecure') {
                    detail.appointmentletterforSGSecure = response.fileName;
                  }
                  //detail["fileDisplayName"] = file.name;
                  this.setState({ SLOTSApplicationDetails: detail }, () =>
                    // this.validateSLOTSMember(
                    //   "uploadPaymentProof",
                    //   this.state.SLOTSApplicationDetails
                    // )
                    this.saveSLOTsDocuments(fileType)
                  );
                  // this.getSLOTSMemberDetailByMemberId(this.state.SLOTSApplicationDetails.slotsMemberId);
                  // }
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isPaymentProofLoader: false });
              });
            } else {
              this.swalServices.Error("Please Upload Image or PDF files only");
            }
          } else if (fileType === "Upload_from_A_For_Renewal") {
            if (
              (file && file.type === "application/pdf") ||
              (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
              (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
              (file.type === "application/msword") ||
              (file.type === "application/msexcel")
            ) {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "SLOTSMemberPDFs\\FormA";
              this.setState({ isformALoader: true });
              this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                if (response != null) {

                  let detail = this.state.SLOTSApplicationDetails;
                  //let detail2 = this.state.SLOTSApplicationSummary;
                  detail.formA = response.fileName;
                  //detail2.formA = response.fileName;
                  this.setState({ SLOTSApplicationDetails: detail },
                    () => {
                      this.saveSLOTsDocuments('formA', "formA", "SLOTS_ApplicationIsRenewal");
                    });
                }
                else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isformALoader: false });
              })
            }
            else {
              this.swalServices.Error("Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !");
            }
          } else if (fileType === "supportingDocFormA") {
            if (this.state.supportingDocFormACount < 10) {
              if (
                (file && file.type === "application/pdf") ||
                (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
                (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
                (file.type === "application/msword") ||
                (file.type === "application/msexcel")
              ) {
                let uploadFile = { ...this.state.resFile };
                uploadFile.filename = file.name;
                uploadFile["file"] = file;
                uploadFile.uploadId = `UplaodFile${this.state.supportingDocFormACount + 1}`;
                uploadFile.storagePath = "SLOTSMemberPDFs\\SupportingDocFormA";
                this.setState({ isFormASupportingDocumentLoader: true });
                this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                  console.log("File upload response OnGoing:", response);
                  if (response != null) {
                    let detail = this.state.SLOTSApplicationDetails;
                    //let detail2 = this.state.SLOTSApplicationSummary;

                    //this.setState({ slotsApplicationDetail: detail });
                    //detail["supportingDocFormA"] = response.fileName;
                    //detail2["supportingDocFormAOngoing"] = response.fileName;
                    detail.supportingDocFormAList.push(response.fileName);
                    this.setState({ supportingDocFormACount: this.state.supportingDocFormACount + 1 });
                    this.setState({ slotsApplicationDetail: detail },
                      () => {
                        //this.validateField("supportingDocFormA", "SLOTS_ApplicationIsRenewal");
                        this.saveSLOTsDocuments('supportingDocFormA', 'supportingDocFormA', 'SLOTS_ApplicationIsRenewal');
                      });
                  }
                  else {
                    this.swalServices.Error(response.message);
                  }
                  this.setState({ isFormASupportingDocumentLoader: false });
                })
              }
              else {
                this.swalServices.Error("Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !");
              }
            }
            else {
              this.swalServices.Warning("You cannot upload more than 10 files");
            }
          } else if (fileType === "Certificate_of_Attendance") {
            if (
              (file && file.type === "application/pdf") ||
              (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
              (file.type === "application/msword") ||
              (file.type === "application/msexcel")
            ) {
              let uploadFile = { ...this.state.resFile };
              uploadFile.filename = file.name;
              uploadFile["file"] = file;
              uploadFile.storagePath = "SLOTSMemberPDFs\\Certificate_of_Attendance";
              this.slotMemberService.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                  let detail = this.state.SLOTSApplicationDetails;
                  //let detail2 = this.state.SLOTSApplicationSummary;
                  detail.certificateofAttendance = response.fileName;
                  //detail2.certificate_of_Attendance = response.fileName;
                  this.setState({ SLOTSApplicationDetails: detail },
                    () => {
                      this.saveSLOTsDocuments('CertificateofAttendance', 'certificate_of_Attendance', 'SLOTS_ApplicationIsRenewal');
                    });
                }
                else {
                  this.swalServices.Error(response.message);
                }
                // this.setState({ isOnGoingProLoader: false });
              })
            }
            else {
              this.swalServices.Error("Please Upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !");
            }
          }
        } else {
          this.swalServices.Error("File size must be less than 5 MB!");
        }
      }
    } else {

      this.swalServices
        .ErrorWithAction("Please login for upload documents!", "OK")
        .then((res) => {
          // location.reload();
        });
    }
  };

  // onFileChangeForWorshopProof = (event, identity) => {
  //   
  //   const file = event.target.files[0];
  //   if (file && file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
  //     let request = {};
  //     request.storagePath = "UploadWorkshopProof";
  //     request.file = file;
  //     request.fileName = file.name;
  //     this.SLOTSMemberServices.uploadPDF(request).then((response) => {
  //       if (response != null) {
  //         if (request.storagePath === "UploadWorkshopProof") {
  //           let detail = { ...this.state.SLOTSApplicationDetails };
  //           if (identity === 'SgMAworkshopCertificate') {
  //             detail.workshopProofPath = response.fileName;
  //           } else if (identity === 'IAO OR bizSAFE Star') {
  //             detail.iaoOrbizSAFEStar = response.fileName;
  //           } else if (identity === 'ISO OR Green Policy') {
  //             detail.isoOrGreenPolicy = response.fileName;
  //           } else if (identity === 'Appointment letter for SG Secure') {
  //             detail.appointmentletterforSGSecure = response.fileName;
  //           }

  //           // detail.isSgMAWorkshopCertificate = res                        
  //           //detail.fileDisplayName = file.name;

  //           this.setState({ SLOTSApplicationDetails: detail }, () => {
  //             this.swalServices.Success("File Uploaded");
  //           });
  //         }
  //       } else {
  //         this.swalServices.Error(response.message);
  //       }
  //     });
  //   }
  //   else {
  //     this.swalServices.Error("Please Upload given format files only");
  //   }
  //   // if (file && (file.type === "image/jpeg" || file.type === "image/png")) {

  //   // } else {
  //   //     this.swalServices.Error("Please select only Image");
  //   // }
  // };


  // Save SLOTS documents path to database method

  saveSLOTsDocuments = (fileType, validationFieldName, SelectName) => {
    if (this.state.slotsMemberId > 0) {
      let request = {};
      request.columnName = fileType;
      request.slotsMemberId = this.state.slotsMemberId;
      request.fileName = "";
      if (fileType === "UploadACRACertificate") {
        //request.columnName = this.state.organisationProfile.uploadACRACertificate;
        request.fileName =
          this.state.SLOTSApplicationDetails.uploadACRACertificate;
      } else if (fileType === "UploadWPOnlineRecord") {
        if (this.state.SLOTSApplicationDetails.fileListOfWPRecord.length > 0) {
          request.fileName = "";
          var arr1 = this.state.SLOTSApplicationDetails.fileListOfWPRecord.map(
            function (value) {
              return value;
            }
          );
          request.fileName = arr1.join([","]);
        }
        //request.fileName = this.state.organisationProfile.uploadWPOnlineRecord;
      } else if (fileType === "Latest6monthsCPFContribution") {
        if (this.state.SLOTSApplicationDetails.fileList2.length > 0) {
          request.fileName = "";
          let arr1 = this.state.SLOTSApplicationDetails.fileList2.map(function (
            value
          ) {
            return value;
          });
          request.fileName = arr1.join([","]);
        }
      } else if (fileType === "CopyOfCPFContributionsOfCompletedProjects") {
        if (
          this.state.SLOTSApplicationDetails.fileListOfcopyofcpfContribution
            .length > 0
        ) {
          request.fileName = "";
          let arr1 =
            this.state.SLOTSApplicationDetails.fileListOfcopyofcpfContribution.map(
              function (value) {
                return value;
              }
            );
          request.fileName = arr1.join([","]);
        }
      } else if (fileType === "ApprovedTechnicalQualification") {
        if (
          this.state.SLOTSApplicationDetails
            .fileListOfApprovedTechnicalQualification.length > 0
        ) {
          request.fileName = "";
          let arr1 =
            this.state.SLOTSApplicationDetails.fileListOfApprovedTechnicalQualification.map(
              function (value) {
                return value;
              }
            );
          request.fileName = arr1.join([","]);
        }
      } else if (fileType === "CompanyBankStatementsOf6Month") {
        if (
          this.state.SLOTSApplicationDetails.bankAccountStatementList.length > 0
        ) {
          request.fileName = "";
          let arr1 =
            this.state.SLOTSApplicationDetails.bankAccountStatementList.map(
              function (value) {
                return value;
              }
            );
          request.fileName = arr1.join([","]);
        }
      } else if (fileType === "ProjectTrackRecordsOfOnGoingProject") {
        request.fileName =
          this.state.SLOTSApplicationDetails.projectTrackRecordsOfOnGoingProject;
      } else if (fileType === "ProjectTrackRecordsOfCompletedProject") {
        request.fileName =
          this.state.SLOTSApplicationDetails.projectTrackRecordsOfCompletedProject;
      } else if (fileType === "SupportingDocFormAOngoing") {
        if (this.state.SLOTSApplicationDetails.formAOnGoingList.length > 0) {
          request.fileName = "";
          let arr1 = this.state.SLOTSApplicationDetails.formAOnGoingList.map(
            function (value) {
              return value;
            }
          );
          request.fileName = arr1.join([","]);
        } else {
          request.fileName = "";
        }
      } else if (fileType === "SupportingDocFormAOnComplete") {
        if (this.state.SLOTSApplicationDetails.formACompletedList.length > 0) {
          request.fileName = "";
          let arr1 = this.state.SLOTSApplicationDetails.formACompletedList.map(
            function (value) {
              return value;
            }
          );
          request.fileName = arr1.join([","]);
        } else {
          request.fileName = "";
        }
      } else if (fileType === "UploadPaymentProof") {
        request.fileName =
          this.state.SLOTSApplicationDetails.uploadPaymentProof;
      } else if (fileType === "WorkshopProofPath") {
        request.fileName =
          this.state.SLOTSApplicationDetails.workshopProofPath;
      } else if (fileType === "IAOOrbizSAFEStar") {
        request.fileName = this.state.SLOTSApplicationDetails.iaoOrbizSAFEStar;
      } else if (fileType === "ISOOrGreenPolicy") {
        request.fileName = this.state.SLOTSApplicationDetails.isoOrGreenPolicy;
      } else if (fileType === "AppointmentletterforSGSecure") {
        request.fileName = this.state.SLOTSApplicationDetails.appointmentletterforSGSecure;
      } else if (fileType === "formA") {
        request.fileName = this.state.SLOTSApplicationDetails.formA;
      } else if (fileType === "supportingDocFormA") {
        if (this.state.SLOTSApplicationDetails.supportingDocFormAList.length > 0) {
          request.fileName = "";
          let arr1 = this.state.SLOTSApplicationDetails.supportingDocFormAList.map(
            function (value) {
              return value;
            }
          );
          request.fileName = arr1.join([","]);
        } else {
          request.fileName = "";
        }
        //request.fileName = this.state.SLOTSApplicationDetails.appointmentletterforSGSecure;
      } else if (fileType === "CertificateofAttendance") {
        request.fileName = this.state.SLOTSApplicationDetails.certificateofAttendance;
      }
      this.slotMemberService.saveSLOTsDocuments(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("File Uploaded");
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isACRACerLoader: false });
      });
    }
  };

  // Delete SLOTs member file method
  deleteSlotsMembersFiles = (fileName, fileType, slotsMemberId) => {
    if (slotsMemberId > 0) {
      let request = {};
      request.sLOTSMemberId = slotsMemberId;
      request.slotsFileType = fileType;
      request.slotsFileName = fileName;
      if (fileType === "WPOnlineRecord") {
        this.slotMemberService
          .deleteSlotsMembersFiles(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              this.setState({ fileDeletedFlag: true });
              // this.setState({ wPOnlineRecordCount: this.state.wPOnlineRecordCount - 1 });
              this.saveSLOTsDocuments(
                "UploadWPOnlineRecord",
                "uploadWPOnlineRecord",
                "Organisation_Profile"
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isWPRecordLoader: false });
          });
      } else if (fileType === "Latest6monthsCPF") {
        this.slotMemberService
          .deleteSlotsMembersFiles(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              this.setState({ fileDeletedFlag: true });
              // this.setState({ cpfContributionLocalCount: this.state.cpfContributionLocalCount - 1 });
              this.saveSLOTsDocuments(
                "Latest6monthsCPFContribution",
                "latest6monthsCPFContribution",
                "Organisation_Profile"
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isWPRecordLoader: false });
          });
      } else if (fileType === "CopyOfCPF") {
        this.slotMemberService
          .deleteSlotsMembersFiles(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              this.setState({ fileDeletedFlag: true });
              // this.setState({ copyofcpfContributionLocalCount: this.state.copyofcpfContributionLocalCount - 1 });
              this.saveSLOTsDocuments(
                "CopyOfCPFContributionsOfCompletedProjects",
                "copyOfCPFContributionsOfCompletedProjects",
                "Organisation_Profile"
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isWPRecordLoader: false });
          });
      } else if (fileType === "ApprovedTechnicalQualification") {
        this.slotMemberService
          .deleteSlotsMembersFiles(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              this.setState({ fileDeletedFlag: true });
              // this.setState({ approvedTechnicalQualificationCount: this.state.approvedTechnicalQualificationCount - 1 });
              this.saveSLOTsDocuments(
                "ApprovedTechnicalQualification",
                "approvedTechnicalQualification",
                "Organisation_Profile"
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isWPRecordLoader: false });
          });
      } else if (fileType === "BankStatementsOf6Month ") {
        this.slotMemberService
          .deleteSlotsMembersFiles(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              this.setState({ fileDeletedFlag: true });
              // this.setState({ companyBankAccStatementCount: this.state.companyBankAccStatementCount - 1 });
              this.saveSLOTsDocuments(
                "CompanyBankStatementsOf6Month",
                "companyBankStatementsOf6Month",
                "SLOTS_Application"
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isWPRecordLoader: false });
          });
      } else if (fileType === "SupportingFormAOngoing") {
        this.slotMemberService
          .deleteSlotsMembersFiles(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              this.setState({ fileDeletedFlag: true });
              // this.setState({ supportingDocOngoingFileUploadCount: this.state.supportingDocOngoingFileUploadCount - 1 });
              this.saveSLOTsDocuments("SupportingDocFormAOngoing", null, null);
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isWPRecordLoader: false });
          });
      } else if (fileType === "SupportingFormAOnComplete") {
        this.slotMemberService
          .deleteSlotsMembersFiles(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              this.setState({ fileDeletedFlag: true });
              // this.setState({ supportingDocCompleteFileUploadCount: this.state.supportingDocCompleteFileUploadCount - 1 });
              this.saveSLOTsDocuments(
                "SupportingDocFormAOnComplete",
                null,
                null
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isWPRecordLoader: false });
          });
      }
    }
  };

  // Delete uploaded file from array
  deleteFile = (value, identity) => {
    if (identity === "ACRA_Certificates") {
      let det = this.state.SLOTSApplicationDetails;
      let find = det["fileList"].findIndex((x) => x === value);
      if (find >= 0) {
        det["fileList"].splice(find, 1);
      }
      this.setState({ SLOTSApplicationDetails: det });
    } else if (identity === "CPF_Contribution") {
      let det = this.state.SLOTSApplicationDetails;
      let find = det["fileList2"].findIndex((x) => x === value);
      if (find >= 0) {
        det["fileList2"].splice(find, 1);
        this.setState({
          cpfContributionLocalCount: this.state.cpfContributionLocalCount - 1,
        });
        this.deleteSlotsMembersFiles(
          value,
          "Latest6monthsCPF",
          this.state.SLOTSApplicationDetails.slotsMemberId
        );
      }
      this.setState({ SLOTSApplicationDetails: det });
    } else if (identity === "CPF_Complete_Contribution") {
      let det = this.state.SLOTSApplicationDetails;
      let find = det["fileListOfcopyofcpfContribution"].findIndex(
        (x) => x === value
      );
      if (find >= 0) {
        det["fileListOfcopyofcpfContribution"].splice(find, 1);
        this.setState({
          copyofcpfContributionLocalCount:
            this.state.copyofcpfContributionLocalCount - 1,
        });
        this.deleteSlotsMembersFiles(
          value,
          "CopyOfCPF",
          this.state.SLOTSApplicationDetails.slotsMemberId
        );
      }
      this.setState({ SLOTSApplicationDetails: det });
    } else if (identity === "Approved_technical_qualification") {
      let det = this.state.SLOTSApplicationDetails;
      let find = det["fileListOfApprovedTechnicalQualification"].findIndex(
        (x) => x === value
      );
      if (find >= 0) {
        det["fileListOfApprovedTechnicalQualification"].splice(find, 1);
        this.setState({
          approvedTechnicalQualificationCount:
            this.state.approvedTechnicalQualificationCount - 1,
        });
        this.deleteSlotsMembersFiles(
          value,
          "ApprovedTechnicalQualification",
          this.state.SLOTSApplicationDetails.slotsMemberId
        );
      }
      this.setState({ SLOTSApplicationDetails: det });
    } else if (identity === "Upload_WP_Record") {

      let det = this.state.SLOTSApplicationDetails;
      let find = det["fileListOfWPRecord"].findIndex((x) => x === value);
      if (find >= 0) {
        det["fileListOfWPRecord"].splice(find, 1);
        this.setState({
          wPOnlineRecordCount: this.state.wPOnlineRecordCount - 1,
        });
      }
      this.setState({ SLOTSApplicationDetails: det });
      this.deleteSlotsMembersFiles(
        value,
        "WPOnlineRecord",
        this.state.SLOTSApplicationDetails.slotsMemberId
      );
    } else if (identity === "Bank_Account_Statement_6Months") {
      let det = this.state.SLOTSApplicationDetails;
      let find = det["bankAccountStatementList"].findIndex((x) => x === value);
      if (find >= 0) {
        det["bankAccountStatementList"].splice(find, 1);
        this.setState({
          companyBankAccStatementCount:
            this.state.companyBankAccStatementCount - 1,
        });
        this.deleteSlotsMembersFiles(
          value,
          "BankStatementsOf6Month ",
          this.state.SLOTSApplicationDetails.slotsMemberId
        );
      }
      this.setState({ SLOTSApplicationDetails: det });
    } else if (identity === "Supporting_Doc_Form_Ongoing") {
      let det = this.state.SLOTSApplicationDetails;
      let find = det["formAOnGoingList"].findIndex((x) => x === value);
      if (find >= 0) {
        det["formAOnGoingList"].splice(find, 1);
        this.setState({
          supportingDocOngoingFileUploadCount:
            this.state.supportingDocOngoingFileUploadCount - 1,
        });
        this.deleteSlotsMembersFiles(
          value,
          "SupportingFormAOngoing",
          this.state.SLOTSApplicationDetails.slotsMemberId
        );
      }
      this.setState({ SLOTSApplicationDetails: det });
    } else if (identity === "Supporting_Doc_Form_Complete") {
      let det = this.state.SLOTSApplicationDetails;
      let find = det["formACompletedList"].findIndex((x) => x === value);
      if (find >= 0) {
        det["formACompletedList"].splice(find, 1);
        this.setState({
          supportingDocCompleteFileUploadCount:
            this.state.supportingDocCompleteFileUploadCount - 1,
        });
        this.deleteSlotsMembersFiles(
          value,
          "SupportingFormAOnComplete",
          this.state.SLOTSApplicationDetails.slotsMemberId
        );
      }
      this.setState({ SLOTSApplicationDetails: det });
    }

  };


  getAuthorisedRepresentative = (key) => {

    let authorisedRepresentativeData = this.state.SLOTSApplicationDetails.authorisedRepresentativeSummaryList;
    let setData = authorisedRepresentativeData[key];
    let data = {};
    data.authorisedRepresentativeId = setData.authorisedRepresentativeId;
    data.roleId = setData.roleId;
    data.firstName = setData.firstName;
    data.lastName = setData.lastName;
    data.jobTitle = setData.jobTitle;
    data.directOfficeNumber = setData.directOfficeNumber;
    data.mobileNumber = setData.mobileNumber;
    data.emailAddress = setData.emailAddress;
    data.isNewsletter = setData.isNewsletter;
    data.isTelegram = setData.isTelegram;
    data.isePortal = setData.isePortal;
    data.slotsMemberId = setData.slotsMemberId;
    data.roles = this.state.roleList.filter(role => role.value === setData.roleId)[0]?.label;

    this.setState({ roleDetails: data });
  };

  addEditAuthoriseRepresentativeSummary = () => {
    let request = this.state.roleDetails;
    request.sLOTSMemberId = this.state.slotsMemberId;
    this.setState({ isRepresentativeLoader: true });
    this.slotMemberService
      .addEditAuthoriseRepresentativeSummary(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Authorised representative summary added sucessfully!"

          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isRepresentativeLoader: false });
      });
  };
  deleteSlotsMembersFilesComman = (value, identity) => {
    let request = {};
    request.sLOTSMemberId = this.state.slotsMemberId;
    request.slotsFileType = identity;
    let det = this.state.SLOTSApplicationDetails;
    let find = det["supportingDocFormAList"].findIndex((x) => x === value);
    if (find >= 0) {
      det["supportingDocFormAList"].splice(find, 1);
    }
    this.setState({ supportingDocFormACount: this.state.supportingDocFormACount - 1, SLOTSApplicationDetails: det }, () => {

      if (this.state.SLOTSApplicationDetails.supportingDocFormAList.length > 0) {
        request.slotsFileName = "";
        let arr1 = this.state.SLOTSApplicationDetails.supportingDocFormAList.map(function (value) {
          return value;
        });
        request.slotsFileName = arr1.join([","]);
      } else {
        request.slotsFileName = "";
      }
      this.slotMemberService.deleteSlotsMembersFiles(request).then((response) => {
        if (response != null && response.statusCode === 200) {
          //this.setState({ fileDeletedFlag: true })
          // this.setState({ supportingDocCompleteFileUploadCount: this.state.supportingDocCompleteFileUploadCount - 1 });
          //this.saveSLOTsDocuments('SupportingDocFormA', null, null);
          //warrning  // let data = this.state.SLOTSApplicationDetails;
          //data.supportingDocFormA = request.slotsFileName;
          //this.setState({ slotsApplicationDetail: data }, () => {
          //this.validateField('supportingDocFormA', 'SLOTS_ApplicationIsRenewal');            
          //})
          this.swalServices.Success("File Deleted");
        }
        else {
          this.swalServices.Error(response.message);
        }
        //this.setState({ isWPRecordLoader: false });
      })
    })
    //this.setState({ slotsApplicationDetail: det });
  }


  removeSLOTSRegistrationHeadScopeTopUp = (id) => {
    this.slotMemberService
      .RemoveSLOTSRegistrationHeadScopeTopUp(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Registration head(s)  (Top-Up) Deleted successfully"
          );
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  removeSLOTSRegistrationHeadScopeTopUpAdditional = (id) => {
    this.slotMemberService
      .RemoveSLOTSRegistrationHeadScopeTopUpAdditional(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.pricingCalculationAdditional();
          this.swalServices.Success(
            "Registration head(s)  (Top-Up) Deleted successfully"
          );
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  //price calculation
  pricingCalculation = () => {
    let pricingData = this.state.priceCalculatorArray;
    this.state.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp.forEach(
      (element) => {
        element.mainGroupId = element.selectedMainGroupId;
      }
    );

    // let data = [...this.state.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp,...this.state.SLOTSApplicationDetails.slotsRegistrationHeadScope]
    let data =
      this.state.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp;
    let max2GroupPrice =
      this.state.slotsMemberPricingDetail.slotsAnnual1To2TradeSameCategoryFee;
    let additionalGroupPrice =
      this.state.slotsMemberPricingDetail.slotsAnnualTradeExistingCategoryFee;
    //existing category
    let newPrice = data;
    newPrice.map((x) => {
      x.isCalculated = false;
      return x;
    });

    pricingData[0].quantity = 0;
    pricingData[1].quantity = 0;
    pricingData[0].Amount = 0;
    pricingData[1].Amount = 0;
    pricingData[2].Amount = 0;
    data.map((item, keyId) => {
      let price1 = newPrice.filter(
        (id) => id.mainGroupId === item.mainGroupId && id.isCalculated === false
      );
      if (price1.length > 0) {
        if (price1.length === 1 || price1.length === 2) {
          let count = 1;
          pricingData[0].Amount = pricingData[0].Amount + max2GroupPrice; //350
          pricingData[0].quantity = pricingData[0].quantity + count;
          // if(price1.length === 2)  {

          // pricingData[1].Amount = (pricingData[1].Amount + (additionalGroupPrice * (price1.length - 1))); //50

          // pricingData[1].quantity = (pricingData[1].quantity + (price1.length - 1));
          // }
        }

        if (price1.length > 2) {
          let count = 1;

          pricingData[0].Amount = pricingData[0].Amount + max2GroupPrice; // 350
          pricingData[1].Amount =
            pricingData[1].Amount + additionalGroupPrice * (price1.length - 2); //50

          pricingData[0].quantity = pricingData[0].quantity + count;
          pricingData[1].quantity =
            pricingData[1].quantity + (price1.length - 2);
        }
        newPrice.map((x) => {
          if (item.mainGroupId === x.mainGroupId) {
            x.isCalculated = true;

          }
          return x;
        });

      }
      return item;
    });
    pricingData[2].quantity = pricingData[0].quantity + pricingData[1].quantity;
    pricingData[2].Amount = pricingData[0].Amount + pricingData[1].Amount;

    //this part temporary commented
    // pricingData[0].Amount = pricingData[0].Amount - this.state.SLOTSApplicationDetails.priceCalculator[0].amount;
    // pricingData[0].quantity = pricingData[0].quantity - this.state.SLOTSApplicationDetails.priceCalculator[0].quantity;

    // pricingData[1].Amount = pricingData[1].Amount - this.state.SLOTSApplicationDetails.priceCalculator[1].amount;
    // pricingData[1].quantity = pricingData[1].quantity - this.state.SLOTSApplicationDetails.priceCalculator[1].quantity;

    // pricingData[2].Amount = pricingData[2].Amount - this.state.SLOTSApplicationDetails.priceCalculator[2].amount;
    // pricingData[2].quantity = pricingData[2].quantity - this.state.SLOTSApplicationDetails.priceCalculator[2].quantity;

    console.log("new  data :- ", pricingData);
    this.setState({ priceCalculatorArray: pricingData });

    console.log(
      "check old price :- ",
      this.state.SLOTSApplicationDetails.priceCalculator
    );
  };
  pricingCalculationAdditional = () => {
    let pricingData = this.state.priceCalculatorArray;
    this.state.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUpAdditional.forEach(
      (element) => {
        element.mainGroupId = element.selectedMainGroupId;
      }
    );

    let data = [...this.state.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp, ...this.state.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUpAdditional]
    // let data =
    //   this.state.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp;
    let max2GroupPrice =
      this.state.slotsMemberPricingDetail.slotsAnnual1To2TradeSameCategoryFee;
    let additionalGroupPrice =
      this.state.slotsMemberPricingDetail.slotsAnnualTradeExistingCategoryFee;
    //existing category
    let newPrice = data;
    newPrice.map((x) => {
      x.isCalculated = false;
      return x;
    });

    pricingData[0].quantity = 0;
    pricingData[1].quantity = 0;
    pricingData[0].Amount = 0;
    pricingData[1].Amount = 0;
    pricingData[2].Amount = 0;
    data.map((item, keyId) => {
      let price1 = newPrice.filter(
        (id) => id.mainGroupId === item.mainGroupId && id.isCalculated === false

      );
      if (price1.length > 0) {
        if (price1.length === 1 || price1.length === 2) {
          let count = 1;
          pricingData[0].Amount = pricingData[0].Amount + max2GroupPrice; //350
          pricingData[0].quantity = pricingData[0].quantity + count;
        }
        if (price1.length > 2) {
          let count = 1;

          pricingData[0].Amount = pricingData[0].Amount + max2GroupPrice; // 350
          pricingData[1].Amount =
            pricingData[1].Amount + additionalGroupPrice * (price1.length - 2); //50

          pricingData[0].quantity = pricingData[0].quantity + count;
          pricingData[1].quantity =
            pricingData[1].quantity + (price1.length - 2);
        }
        newPrice.map((x) => {
          if (item.mainGroupId === x.mainGroupId) {
            x.isCalculated = true;

          }
          return x;
        });
      }


      return item;
    });
    pricingData[2].quantity = pricingData[0].quantity + pricingData[1].quantity;
    pricingData[2].Amount = pricingData[0].Amount + pricingData[1].Amount;

    //this part temporary commented
    pricingData[0].Amount = pricingData[0].Amount - (this.state.SLOTSApplicationDetails.priceCalculator.length > 0 ? this.state.SLOTSApplicationDetails.priceCalculator[0].amount : 0);
    pricingData[0].quantity = pricingData[0].quantity - (this.state.SLOTSApplicationDetails.priceCalculator.length > 0 ? this.state.SLOTSApplicationDetails.priceCalculator[0].quantity : 0);

    pricingData[1].Amount = pricingData[1].Amount - (this.state.SLOTSApplicationDetails.priceCalculator.length > 0 ? this.state.SLOTSApplicationDetails.priceCalculator[1].amount : 0);
    pricingData[1].quantity = pricingData[1].quantity - (this.state.SLOTSApplicationDetails.priceCalculator.length > 0 ? this.state.SLOTSApplicationDetails.priceCalculator[1].quantity : 0);

    pricingData[2].Amount = pricingData[2].Amount - (this.state.SLOTSApplicationDetails.priceCalculator.length > 0 ? this.state.SLOTSApplicationDetails.priceCalculator[2].amount : 0);
    pricingData[2].quantity = pricingData[2].quantity - (this.state.SLOTSApplicationDetails.priceCalculator.length > 0 ? this.state.SLOTSApplicationDetails.priceCalculator[2].quantity : 0);

    console.log("new  data :- ", pricingData);
    this.setState({ priceCalculatorArrayTopUpAdditional: pricingData });


  };
  getMembershipPricingWithGst = () => {
    this.slotMemberService.getMembershipPricingWithGst().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // this.swalServices.Success("Success");
        this.setState({
          slotsMemberPricingDetail: response.responseItem.responseContent,
        });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };
  // Add Authorise Representative Summary for SLOTs

  // Delete Authorise Representative Summary for SLOTs
  removeSLOTSAuthoriseRepresentativeSummary = (
    slotsAuthoriseRepresentativeId
  ) => {
    if (this.state.slotsMemberId > 0) {
      let request = {};
      request.slotsAuthoriseRepresentativeId = slotsAuthoriseRepresentativeId;
      request.sLOTSMemberId = this.state.slotsMemberId;
      this.slotMemberService
        .removeSLOTSAuthoriseRepresentativeSummary(request)
        .then((response) => {
          if (response != null && response.statusCode === 200) {
            this.swalServices.Success(
              "Authorised representative summary deleted sucessfully!"
            );
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  BuyAdditionalRegistrationHeadTopUp = (event, priceCalculatorTopUp) => {
    if (this.state.slotsMemberId > 0) {
      this.setState({ isLoading: true });
      let request = {};
      request.priceCalculator = priceCalculatorTopUp;
      request.isRenewal = this.state.SLOTSApplicationDetails.isRenewal;
      request.slotsMemberId = this.state.slotsMemberId;
      if (request.priceCalculator && request.priceCalculator[2].Amount > 0) {
        this.slotMemberService
          .BuyAdditionalRegistrationHeadTopUp(request)
          .then((response) => {
            if (response != null && response.statusCode === 200) {
              //    this.swalServices.Success("Authorised representative summary deleted sucessfully!");
              this.swalServices.Success("sucessfully");
              this.setState({ isLoading: false });
            } else {
              this.swalServices.Error(response.message);
              this.setState({ isLoading: false });
            }
          });
      }
    }
  };

  updateBasicAppicationDetails = () => {
   
    //if (this.isValidBasicApplication()) {
    if (this.state.slotsMemberId > 0) {
      this.setState({ isBasicApplicationLoading: true });
      let res = {};
      res.slotsMemberId = this.state.slotsMemberId;
      res.companyName = this.state.SLOTSApplicationDetails.companyName;
      res.chineseCompanyName = this.state.SLOTSApplicationDetails.chineseCompanyName;
      //res.uenNumber = this.state.SLOTSApplicationDetails.uenNumber;
      res.dateOfIncorporation = this.state.SLOTSApplicationDetails.dateOfIncorporation;
      res.mailingAddress1 = this.state.SLOTSApplicationDetails.mailingAddress1;
      res.mailingAddress2 = this.state.SLOTSApplicationDetails.mailingAddress2;
      res.mailingAddress3 = this.state.SLOTSApplicationDetails.mailingAddress3;
      res.countryId = this.state.SLOTSApplicationDetails.countryId;
      res.postalCode = this.state.SLOTSApplicationDetails.postalCode;
      res.businessTelNo = this.state.SLOTSApplicationDetails.businessTelNo;
      res.businessEmail = this.state.SLOTSApplicationDetails.businessEmail;
      res.updatedBy = this.state.updatedBy;
      res.updatedAppId = this.state.updatedAppId;
      // this.setState({ isBasicApplicationLoading: true }, () => {
      this.slotMemberService
        .updateBasicAppicationDetails(res)
        .then((response) => {
        
          if (response != null && response.statusCode === 200) {
            this.swalServices.Success("SLOTS basic application detail save sucessfully.");
            this.setState({ isBasicApplicationLoading: false });

          } else {
            this.swalServices.Error(response.message);
            this.setState({ isBasicApplicationLoading: false });
          }
        });
      // });

      //}
    }
  }

  updateOrganisationProfileDetails = () => {
  
    //if (this.isValidOrganisationProfile()) {
    if (this.state.slotsMemberId > 0) {
      let res = {};
      res.slotsMemberId = this.state.slotsMemberId;
      res.annualTurnOverId = this.state.SLOTSApplicationDetails.annualTurnOverId;
      res.companyDescription = this.state.SLOTSApplicationDetails.companyDescription;
      //res.uenNumber = this.state.SLOTSApplicationDetails.uenNumber;
      res.numberOfStaffEmployedLocalId = this.state.SLOTSApplicationDetails.numberOfStaffEmployedLocalId;
      res.foreignWorkPermitHolderId = this.state.SLOTSApplicationDetails.foreignWorkPermitHolderId;
      res.headOfCompanyFirstName = this.state.SLOTSApplicationDetails.headOfCompanyFirstName;
      res.headOfCompanyLastName = this.state.SLOTSApplicationDetails.headOfCompanyLastName;
      res.jobTitle = this.state.SLOTSApplicationDetails.jobTitle;
      res.directOfficeNumber = this.state.SLOTSApplicationDetails.directOfficeNumber;
      res.mobileNumber = this.state.SLOTSApplicationDetails.mobileNumber;
      res.email = this.state.SLOTSApplicationDetails.email;
      res.paidUpCapital = this.state.SLOTSApplicationDetails.paidUpCapital;

      res.updatedBy = this.state.updatedBy;
      res.updatedAppId = this.state.updatedAppId;
      this.setState({ isOrganisationProfileLoading: true });
      this.slotMemberService
        .updateOrganisationProfileDetails(res)
        .then((response) => {
          if (response != null && response.statusCode === 200) {
            this.swalServices.Success("SLOTS Organisation Profile details updated sucessfully.");
            this.setState({ isOrganisationProfileLoading: false });
          } else {
            this.swalServices.Error(response.message);
            this.setState({ isOrganisationProfileLoading: false });
          }
        });
    }
    // }
  }
  updateForceRenewalFlag = () => {
    if (this.state.slotsMemberId > 0) {
      let data = this.state.SLOTSApplicationDetails;
      let request = [this.state.slotsMemberId, !data.isForceRenewal]
      this.setState({ isForceRenewalLoader: true });

      this.slotMemberService
        .updateForceRenewalFlag(request)
        .then((response) => {
          if (response != null && response.statusCode === 200) {

            data.isForceRenewal = !data.isForceRenewal;
            if (data.isForceRenewal) {
              this.swalServices.Success("Force renewal functionality activated sucessfully.");
            } else {
              this.swalServices.Success("Force renewal functionality deactivated sucessfully.");
            }
            this.setState({ isForceRenewalLoader: false, SLOTSApplicationDetails: data });
          } else {
            this.swalServices.Error(response.message);
            this.setState({ isForceRenewalLoader: false });
          }
        });
    }
  }

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="custom-card shadow-lg bg-white">
            {/* <h2>{this.state.slotsMemberId}</h2> */}
            <div>
              {this.state.SLOTSApplicationDetails && this.state.SLOTSApplicationDetails.isExpiredMember === true ?
                <div className="flex justify-end">
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="check-box-sec flex items-center register-desc mb-5">
                      <div className={''}>
                        <CheckboxInput
                          id="isForceRenewal"
                          name="isForceRenewal"
                          className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                          checked={this.state.SLOTSApplicationDetails.isForceRenewal}
                          //value={props.SLOTSApplicationDetails.isForceRenewal}
                          handleChange={this.updateForceRenewalFlag.bind()}
                        //disabled={props.isDisabledFlag ? true : false}
                        />
                      </div>
                      {this.state.isForceRenewalLoader && this.state.isForceRenewalLoader === true ?
                        <>
                          <span className="filter-lable font-bold text-[#757575] pl-4">
                            Loading...
                          </span>
                        </>
                        :
                        <>
                          <span className="filter-lable font-bold text-[#757575] pl-4">
                            Force Renewal
                          </span>
                        </>
                      }
                    </div>
                    {/* <ValidationText error={props.validStateSLOTSMember.error.companyName} /> */}
                  </div>
                </div>
                :
                null
              }
              <div className="basic-application">
                <SectionWithBorder
                  totalColumn={1}
                  textSize="2xl"
                  className="font-bold pr-0"
                  column1={
                    <>
                      <div className="flex items-center justify-between">
                        <div className=" text-[25px] text-[#000000]">
                          <p>Basic Application</p>
                        </div>
                        <div className="flex gap-x-[25px] justify-end">
                          {this.state.SLOTSApplicationDetails.IsMigratedData ?
                            <>
                              <div>
                                {this.state.SLOTSApplicationDetails.IsMigratedData && "Is Migrated"}
                              </div>
                            </>
                            :
                            null
                          }
                          {this.state.SLOTSApplicationDetails.IsMigratedData && this.state.SLOTSApplicationDetails.CertificateMembershipNo ?
                            <>
                              |
                            </>
                            :
                            null
                          }
                          {this.state.SLOTSApplicationDetails.CertificateMembershipNo ?
                            <>
                              <div>
                                <p>{this.state.SLOTSApplicationDetails.CertificateMembershipNo}</p>
                              </div>
                            </>
                            :
                            null
                          }

                        </div>
                      </div>


                      {/* <div className="flex items-left justify-between text-[25px] text-[#000000]">
                      <p>Basic Application</p>
                      <p>{this.state.SLOTSApplicationDetails.IsMigratedData ? "Is Migrated" : null } </p>
                      {this.state.SLOTSApplicationDetails.CertificateMembershipNo && (
                        <p>{this.state.SLOTSApplicationDetails.CertificateMembershipNo}</p>
                      )}
                    </div> */}
                    </>
                  }
                >

                  <BasicAppication
                    countryList={this.state.countryList}
                    SLOTSApplicationDetails={this.state.SLOTSApplicationDetails}
                    validStateBasicApplication={this.state.validStateBasicApplication}
                    isDisabledFlag={this.state.isDisabledFlag}
                    isExpiredMember={this.state.isExpiredMember}
                    handleChange={this.handleChange.bind(this)}
                    handleChangeInDropdown={this.handleChangeInDropdown.bind(
                      this
                    )}
                    validateField={this.validateSLOTSMember.bind(this)}
                    updateBasicAppicationDetails={this.updateBasicAppicationDetails.bind(this)}
                    isBasicApplicationLoading={this.state.isBasicApplicationLoading}
                    isForceRenewalLoader={this.state.isForceRenewalLoader}
                    updateForceRenewalFlag={this.updateForceRenewalFlag.bind(this)}
                  />
                </SectionWithBorder>
              </div>

            </div>

            <div>
              <SectionWithBorder
                totalColumn={1}
                textSize="2xl"
                className="font-bold"
                column1="Organisation Profile"
              >
                <OrganisationProfile
                  annualTurnoverList={this.state.annualTurnoverList}
                  staffEmployeeLocalList={this.state.staffEmployeeLocalList}
                  roleList={this.state.roleList}
                  SLOTSApplicationDetails={this.state.SLOTSApplicationDetails}
                  roleDetails={this.state.roleDetails}
                  validStateOrganisationProfile={this.state.validStateOrganisationProfile}
                  validStateSLOTSMemberRole={
                    this.state.validStateSLOTSMemberRole
                  }
                  isDisabledFlag={this.state.isDisabledFlag}
                  isACRACerLoader={this.state.isACRACerLoader}
                  isWPRecordLoader={this.state.isWPRecordLoader}
                  is6MonCPFLoader={this.state.is6MonCPFLoader}
                  isCPFConLoader={this.state.isCPFConLoader}
                  isApprovedBCALoader={this.state.isApprovedBCALoader}
                  isOrganisationProfileLoading={this.state.isOrganisationProfileLoading}
                  handleChange={this.handleChange.bind(this)}
                  handleChangeInDropdown={this.handleChangeInDropdown.bind(
                    this
                  )}
                  validateField={this.validateSLOTSMember.bind(this)}
                  validateRole={this.validateSLOTSMemberRole.bind(this)}
                  GetSlotsMemberFiles={this.GetSlotsMemberFiles.bind(this)}
                  foreignWorkPermitHolderList={
                    this.state.foreignWorkPermitHolderList
                  }
                  DownloadMultipleFilesZip={this.DownloadMultipleFilesZip.bind(
                    this
                  )}
                  onFileChange={this.onFileChange.bind(this)}
                  deleteFile={this.deleteFile.bind(this)}
                  updateOrganisationProfileDetails={this.updateOrganisationProfileDetails.bind(this)}
                  getAuthorisedRepresentative={this.getAuthorisedRepresentative.bind(this)}
                />
              </SectionWithBorder>
            </div>

            <div>
              <SectionWithBorder
                totalColumn={1}
                textSize="2xl"
                className="font-bold"
                column1="SLOTS Application"
              >
                <SLOTSApplication
                  countryList={this.state.countryList}
                  mainGroupList={this.state.mainGroupList}
                  workheadList={this.state.workheadList}
                  gradeList={this.state.gradeList}
                  validStateSLOTSMember={this.state.validStateSLOTSMember}
                  SLOTSApplicationDetails={this.state.SLOTSApplicationDetails}
                  selectedBuildingConScope={this.state.selectedBuildingConScope}
                  selectedSLOTSRegistrationScope={
                    this.state.selectedSLOTSRegistrationScope
                  }
                  mainGroupListPricing={this.state.mainGroupListPricing}
                  workheadListPricing={this.state.workheadListPricing}
                  priceCalculatorArray={this.state.priceCalculatorArray}
                  validStateBCAScope={this.state.validStateBCAScope}
                  validStateregistrationScope={
                    this.state.validStateregistrationScope
                  }
                  isDisabledFlag={this.state.isDisabledFlag}
                  isBankAccStatLoader={this.state.isBankAccStatLoader}
                  isOnGoingProLoader={this.state.isOnGoingProLoader}
                  isOnGoingProLoader2={this.state.isOnGoingProLoader2}
                  isCompProLoader={this.state.isCompProLoader}
                  isCompProLoader2={this.state.isCompProLoader2}
                  isFormALoader={this.state.isFormALoader}
                  isFormASupportingDocumentLoader={this.state.isFormASupportingDocumentLoader}
                  isPaymentProofLoader={this.state.isPaymentProofLoader}

                  // renewalSLOTSRegistrationHeadScope={this.state.renewalSLOTSRegistrationHeadScope}
                  handleChange={this.handleChange.bind(this)}
                  handleChangeInDropdown={this.handleChangeInDropdown.bind(
                    this
                  )}
                  GetSlotsMemberFiles={this.GetSlotsMemberFiles.bind(this)}
                  renewalDataLength={this.state.renewalDataLength}
                  DownloadMultipleFilesZip={this.DownloadMultipleFilesZip.bind(
                    this
                  )}
                  onFileChange={this.onFileChange.bind(this)}
                  deleteFile={this.deleteFile.bind(this)}
                  deleteSlotsMembersFilesComman={this.deleteSlotsMembersFilesComman.bind(this)}
                  removeRegistrationHead={this.removeRegistrationHead.bind(
                    this
                  )}
                  removeRegistrationHeadAdditional={this.removeRegistrationHeadAdditional.bind(
                    this
                  )}
                  pricingCalculation={this.pricingCalculation.bind()}
                  pricingCalculationAdditional={this.pricingCalculationAdditional.bind()}
                  currentPriceCalculatorArray={this.state.priceCalculatorArray}
                  priceCalculatorArrayTopUpAdditional={this.state.priceCalculatorArrayTopUpAdditional}
                  handleAddRolesAndScope={this.handleAddRolesAndScope.bind(
                    this
                  )}
                  handleRemoveRolesAndScope={this.handleRemoveRolesAndScope.bind(
                    this
                  )}
                  BuyAdditionalRegistrationHeadTopUp={this.BuyAdditionalRegistrationHeadTopUp.bind(
                    this
                  )}
                  getSLOTSMemberDetailByMemberId={this.getSLOTSMemberDetailByMemberId.bind(this)}
                  updateSLOTSRegistrationHeadScopeTopUp={this.updateSLOTSRegistrationHeadScopeTopUp.bind(this)}
                //saveInformtion={this.saveInformtion.bind(this)}
                />
              </SectionWithBorder>
            </div>
            {(this.state.SLOTSApplicationDetails.statusId === 17 ||
              this.state.SLOTSApplicationDetails.statusId === 18) &&
              this.state.SLOTSApplicationDetails.statusId !== 21 ? (
              <>
                <div>
                  <SectionWithBorder
                    totalColumn={1}
                    textSize="2xl"
                    className="font-bold"
                    column1="Annual fee payment details"
                  >
                    <>
                      <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-2 2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                        {/* SLOTs Registration Head(s) Selection Section */}
                        {/* SLOTs Registration Head Table Section */}
                        <div className="col-span-12">
                          <div className="table-section">
                            <div className="overflow-x-auto">
                              <table className="w-full">
                                <thead className="bg-[#e78e3c] text-white">
                                  <tr>
                                    <th className="whitespace-nowrap text-left py-3 px-5">Selected Scope</th>
                                    <th className="whitespace-nowrap text-left py-3 px-5">Workhead</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.SLOTSApplicationDetails?.slotsRegistrationHeadScope?.map((scope, key) => (
                                    <tr className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]" key={key}>
                                      <td className="whitespace-nowrap text-left py-2 px-5">{scope.mainGroupName}</td>
                                      <td className="whitespace-nowrap text-left py-2 px-5">{scope.workheadName}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 mt-5">
                          <div className="grid grid-cols-12 gap-4 w-full justify-between">
                            <div className="lg:col-span-6 col-span-12">
                              <h2 className="input-title">
                                Price Calculator
                                <span className="text-[#C00000]">*</span>
                              </h2>
                            </div>
                            <div className="col-span-12">
                              <div className="table-section">
                                <div className="overflow-x-auto">
                                  <table className="w-full">
                                    <thead className="bg-[#e78e3c] text-white">
                                      <tr>
                                        <th className="whitespace-nowrap text-left py-3 px-5">
                                          Description
                                        </th>
                                        <th className="whitespace-nowrap text-left py-3 px-5">
                                          Quantity
                                        </th>
                                        <th className="whitespace-nowrap text-center py-3 px-5">
                                          Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.SLOTSApplicationDetails?.priceCalculator.map(
                                        (price, key) => {
                                          return (
                                            <tr
                                              className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                              key={key}
                                            >
                                              <td className="whitespace-nowrap text-left py-2 px-5">
                                                {price.description}
                                              </td>
                                              <td className="whitespace-nowrap text-left py-2 px-5">
                                                {price.quantity}
                                              </td>
                                              <td className="whitespace-nowrap text-center py-2 px-5">
                                                ${price.amount}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 mt-0">
                          <div className="flex justify-end">
                            {this.state.isPaymentLinkLoader ? (
                              <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-orange-border mr-5" />
                            ) : (
                              <>

                                <button
                                  className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border w-[250px] text-red p-3 mt-4 mr-5 ${this.state.isDisabledFlag
                                    ? "cursor-not-allowed"
                                    : null
                                    }`}
                                  onClick={() => {
                                    this.sendPaymentLinkOfSLOTSMember();
                                  }}
                                  disabled={
                                    this.state.isDisabledFlag ? true : false
                                  }
                                >
                                  Send Payment link
                                </button>


                                {/* <button
                              className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border w-[250px] text-red p-3 mt-4 mr-5 ${this.state.isDisabledFlag
                                ? "cursor-not-allowed"
                                : null
                                }`}
                              onClick={() => {
                                this.sendPaymentLinkOfSLOTSMember();
                              }}
                              disabled={
                                this.state.isDisabledFlag ? true : false
                              }
                            >
                              Send Payment link
                            </button> */}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  </SectionWithBorder>
                </div>
              </>
            ) : null}
            {this.state.SLOTSApplicationDetails.statusId === 22 ?
              <>
                <div>
                  <SectionWithBorder
                    totalColumn={1}
                    textSize="2xl"
                    className="font-bold"
                    column1="Renewal fee payment details"
                  >
                    <>
                      <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-2 2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                        {/* SLOTs Registration Head(s) Selection Section */}
                        {/* SLOTs Registration Head Table Section */}
                        <div className="col-span-12">
                          <div className="table-section">
                            <div className="overflow-x-auto">
                              <table className="w-full">
                                <thead className="bg-[#e78e3c] text-white">
                                  <tr>
                                    <th className="whitespace-nowrap text-left py-3 px-5">Selected Scope</th>
                                    <th className="whitespace-nowrap text-left py-3 px-5">Workhead</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.SLOTSApplicationDetails?.renewalSLOTSRegistrationHeadScope?.map((scope, key) => (
                                    <tr className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]" key={key}>
                                      <td className="whitespace-nowrap text-left py-2 px-5">{scope.mainGroupName}</td>
                                      <td className="whitespace-nowrap text-left py-2 px-5">{scope.workheadName}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 mt-5">
                          <div className="grid grid-cols-12 gap-4 w-full justify-between">
                            <div className="lg:col-span-6 col-span-12">
                              <h2 className="input-title">
                                Price Calculator
                                <span className="text-[#C00000]">*</span>
                              </h2>
                            </div>
                            <div className="col-span-12">
                              <div className="table-section">
                                <div className="overflow-x-auto">
                                  <table className="w-full">
                                    <thead className="bg-[#e78e3c] text-white">
                                      <tr>
                                        <th className="whitespace-nowrap text-left py-3 px-5">
                                          Description
                                        </th>
                                        <th className="whitespace-nowrap text-left py-3 px-5">
                                          Quantity
                                        </th>
                                        <th className="whitespace-nowrap text-center py-3 px-5">
                                          Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.SLOTSApplicationDetails?.renewalPriceCalculator.map(
                                        (price, key) => {
                                          return (
                                            <tr
                                              className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                              key={key}
                                            >
                                              <td className="whitespace-nowrap text-left py-2 px-5">
                                                {price.description}
                                              </td>
                                              <td className="whitespace-nowrap text-left py-2 px-5">
                                                {price.quantity}
                                              </td>
                                              <td className="whitespace-nowrap text-center py-2 px-5">
                                                ${price.amount}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 mt-0">
                          <div className="flex justify-end">
                            {this.state.isPaymentLinkLoader ? (
                              <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-orange-border mr-5" />
                            ) : (
                              <>
                                <button
                                  className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border text-red p-3 mt-4 mr-5`}
                                  onClick={() => {
                                    this.acceptRenewSLOTSMembership();
                                  }}
                                >
                                  Send Payment link for renewal
                                </button>
                                {/* <button
                                    className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border w-[250px] text-red p-3 mt-4 mr-5`}
                                    onClick={() => {
                                      this.acceptRenewSLOTSMembership();
                                    }}          
                                  >
                                  Send Payment link for renewal
                                </button> */}
                              </>
                            )}
                          </div>
                        </div>
                      </div>


                    </>
                  </SectionWithBorder>
                </div>
              </>
              :
              null
            }
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">
              <div className="flex items-center justify-end">
                <div className="flex gap-3 items-center justify-between mt-3">
                  {this.state.SLOTSApplicationDetails.statusId === 22 ?
                    null
                    : <>
                      {
                        this.state.SLOTSApplicationDetails.statusId !== 21 ? (
                          this.state.isLoadingExpireApplication ? (
                            <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-orange" />
                          ) : (
                            <button
                              className={`col-end-9 col-span-2 text-xl font-bold btn btn-orange w-[250px] p-3 mt-4 ${this.state.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                              onClick={() => this.ExpireSlotsMemberApplication()}
                              disabled={this.state.isDisabledFlag ? true : false}
                            >
                              Expire Application
                            </button>
                          )
                        ) : null
                      }</>
                  }

                  {this.state.SLOTSApplicationDetails.statusId !== 21 ? (
                    <button
                      className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border w-[250px] text-red p-3 mt-4 ${this.state.isDisabledFlag ? "cursor-not-allowed" : null
                        }`}
                      onClick={this.setSendClarificationsFlag.bind(this)}
                      disabled={this.state.isDisabledFlag ? true : false}
                    >
                      Send Clarifications
                    </button>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">
              <div className="flex items-center justify-end">
                <div className="flex gap-3 items-center justify-between mt-3">
                  {this.state.SLOTSApplicationDetails.statusId === 12 &&
                    this.state.SLOTSApplicationDetails.statusId !== 21 ? (
                    this.state.isLoading ? (
                      <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-orange" />
                    ) : (
                      <button
                        className={`col-end-9 col-span-2 text-xl font-bold btn btn-orange w-[250px] p-3 mt-4 ${this.state.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                        onClick={() => {
                          this.rejectSLOTSMemberApplication("UnReject");
                        }}
                        disabled={this.state.isDisabledFlag ? true : false}
                      >
                        Appeal Process
                      </button>
                    )
                  ) : (
                    <>
                      {this.state.SLOTSApplicationDetails.statusId ===
                        4 ? null : (this.state.SLOTSApplicationDetails
                          .statusId === 15 ||
                          this.state.SLOTSApplicationDetails.statusId === 16) &&
                          this.state.SLOTSApplicationDetails.statusId !== 21 ? (
                        this.state.isWorkshopProofLoader ? (
                          <ButtonLoader className="w-[250px] mt-4 btn btn-orange-border mr-5" />
                        ) : (
                          <>
                            <button
                              className={`col-end-9 col-span-2 text-xl font-bold btn btn-orange-border text-red w-[250px] p-3 mt-4 ${this.state.isDisabledFlag
                                ? "cursor-not-allowed"
                                : null
                                } `}
                              onClick={() => {
                                this.sendReconciliationUploadWorkshopProofLinkToSlots();
                              }}
                              disabled={
                                this.state.isDisabledFlag ? true : false
                              }
                            >
                              Send Workshop Proof Link
                            </button>
                          </>
                        )
                      ) : null}
                      {this.state.SLOTSApplicationDetails.statusId === 22 || this.state.SLOTSApplicationDetails.isRenewal == 1 ?
                        null
                        :
                        <>
                          {this.state.isLoading ? (
                            <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-orange" />
                          ) : (
                            <button
                              className={`col-end-9 col-span-2 text-xl font-bold btn btn-orange w-[250px] p-3 mt-4 ${this.state.isDisabledFlag
                                ? "cursor-not-allowed"
                                : null
                                }`}
                              onClick={() => {
                                this.editSlotsMemberDetails();
                              }}
                              disabled={this.state.isDisabledFlag ? true : false}
                            >
                              Update
                            </button>
                          )}
                        </>
                      }

                      <button
                        className={`col-end-9 col-span-2 text-xl font-bold btn btn-orange w-[250px] p-3 mt-4 ${this.state.isDisabledFlag
                          ? "cursor-not-allowed"
                          : null
                          }`}
                        //onClick={() => { this.rejectSLOTSMemberApplication('Rejected'); }}
                        onClick={this.setSendRejectionReasonFlag.bind(this)}
                        disabled={this.state.isDisabledFlag ? true : false}
                      >
                        Reject application
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="membership-task-list-process-sec">
              <div className="send-clarification-model">
                <SendClarifications
                  validState={this.state.validState}
                  validateField={this.validateSLOTSMember.bind(this)}
                  clarificationModal={this.state.setSendClarifications}
                  clarification={this.state.clarification}
                  isLoading={this.state.isLoading}
                  setOpenModal={() =>
                    this.setState({ setSendClarifications: false })
                  }
                  onTinyEceEditorChange={(data) =>
                    this.handleChangeClarificationDetails(data)
                  }
                  // onClose={(e) => {e.stopPropagation();
                  //   this.setState({ setSendClarifications: false });
                  // }}
                  saveClarificationDetails={this.saveClarificationDetails.bind(
                    this
                  )}
                />
              </div>
            </div>
            <div className="membership-task-list-process-sec">
              <div className="send-clarification-model">
                <SendRejectionReason
                  rejectionModal={this.state.setRejectionReason}
                  sendRejectionReason={this.state.sendRejectionReason}
                  isLoadingRejectAplication={
                    this.state.isLoadingRejectAplication
                  }
                  setOpenModal={() =>
                    this.setState({ setRejectionReason: false })
                  }
                  onTinyEceEditorChange={(data) =>
                    this.handleChangeSendRejectionReasonDetails(data)
                  }
                  // handleChangeSendRejectionReasonDetails={this.handleChangeSendRejectionReasonDetails.bind(this)}
                  // onClose={(e) => {e.stopPropagation();
                  //   this.setState({ setRejectionReason: false });
                  // }}
                  btn_color={"btn-orange"}
                  rejectSLOTSMemberApplication={this.rejectSLOTSMemberApplication.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}
