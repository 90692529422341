// Import React Libraries and Components
import React, { Component } from "react";
import AccountInformation from "../../../components/membership/createIndividualMember/AccountInformation";
import IndividualBasicInformation from "../../../components/membership/createIndividualMember/IndividualBasicInformation";
import EngagementTrackingNotes from "../../../components/membership/createIndividualMember/EngagementTrackingNotes";
import IndividualMembershipSubscription from "../../../components/membership/createIndividualMember/IndividualMembershipSubscription";
import FinancialsRecord from "../../../components/membership/createIndividualMember/FinancialsRecords";
import CPASAccrediation from "../../../components/membership/createIndividualMember/CPASAccrediation";
import moment from 'moment/moment';
// Import API Services
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import { CommonSuccessMessages, ErrorMessage, SuccessMessage } from "../../../utils/Messages";
import { validate, isValidForm } from "../../../utils/validation/CommonValidator";
import {
  EngagementTrackingValRules,
  IndividualProfileValRules,
  SIISIndividualAccountValRules,
  SCALMembershipInformationRules,
} from "./IndividualValidationRules";
import { saveAs } from 'file-saver';
import SwalServices from "../../../services/swalServices/SwalServices"
import { checkParameters, encryptAES } from "../../../utils/Encryption";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import { convertToDecimal, isNullBoolean, isNullString } from "../../../utils/Utils";
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import { monthDifference } from "../../../utils/DateUtils";
import { addEditCreditNoteValRules, addEditCreditNoteValRules2 } from "../corporateMembers/CorporateValidations";
import CONQUASAccrediation from "../../../components/membership/createIndividualMember/CONQUASAccrediation";
import { Navigate } from "react-router-dom";
import CONQUASMemberService from "../../../services/axiosServices/apiServices/CONQUASMemberServices";



export default class CreateIndividualMembers extends Component {
  constructor(props) {
    super(props);
    this.corporateMemberServices = new CorporateMemberServices();
    this.individualMemberServices = new IndividualMemberService();
    this.financeServices = new FinanceServices();
    this.swalServices = new SwalServices();
    this.conquasMemberService = new CONQUASMemberService();
    this.state = {
      membershipTypeName: "",
      individualMemberId: 0,
      conquasMemberId: 0,
      cancellationReason: [],
      isLoading: false,
      isDisabledFlag: false,
      isMigratedData: "",
      isConquasMember: false,
      CertificateMembershipNo: "",
      _by: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchParentName: "",
        searchGroupName: "",
        searchGroupType: "",
        status: 0,
        filterByType: 0,
        filterByStatus: 0,
      },

      updatedBy: 0,
      updatedAppId: 0,
      editDetails: 0,
      addNew: false,
      openSection: "IndividualAccountInformation",

      roles: [],
      organisation: [],
      filteredOrganisation: [],
      status: [],
      _By: [],
      billingCategory: [],
      membershipTypes: [],
      membershipTypeForNewMembers: [],
      companyCategory: [],
      emailDomain: [],
      individualStatus: [],
      corporateMemberId: 0,
      certificateMembershipNo: '',

      mrMrs: [],
      countryList: [],
      placeOfBirthList: [],
      nationalitylist: [],
      educationLevelList: [],
      hearAboutUsList: [],

      // ----- View Individual Data List -----

      // Individual Account Inforamtion
      individualAccountInfo: {
        individualSIISAccountId: 0,
        individualMemberId: 0,
        accountStatus: 1,
        individualJoinedDate: "",
        updatedBy: 0,
        updatedAt: "",
        siisIndividualId: "",
        conquasMemberId: 0,
        isConquasMember: false
        // markForDeleted: false,
        // deletionReasonId: 0,
        // deletedAt: "",
        // deletionDate: "",
        // engagementScore: 0,
      },

      CONQUASAccrediation: {
        conquasMemberSubscriptionId: 0,
        subscriptionStartDate: "",
        subscriptionEndDate: "",
        updatedBy: 0,
        updatedAt: null,
        certificatePath: "",
        isForRenewal: false,
        renewalUrl: "",

      },

      // Individual Basic inforamtion (SCAL)
      individualBasicInforamtion: {
        individualMemberId: 0,
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        mailingAddress1: "",
        mailingAddress2: "",
        mailingAddress3: "",
        countryId: 153,
        postalCode: "",
        contactNo: "",
        email: "",
        placeOfBirthId: 0,
        nationalityTypeId: 0,
        photo: "",
        description: "",
        educationLevelId: 0,
        titleOfCourse: "",
        constructionCertification: "",
        companyName: "",
        jobTitle: "",
        emailOfWork: "",
        howDoYouHearId: 0,
        membershipTypeId: 0,
        descriptionWordsCount: 0,
      },
      characterLimit: 100,
      // SCAL Membership Inforamtion (Individual)
      scalMembershipInforamtion: {
        individualSubscriptionId: 0,
        membershipTypeId: 4,
        billingCategoryId: 1,
        IsMigratedData: false,
        subscriptionStartDate: null,
        subcriptionEndDate: null,
        updatedBy: 0,
        updatedAt: null,
        membershipRevenueId: 0,
        uploadPaymentProof: "",
        comments: ""
      },

      validMemberSub: {
        isValid: true,
        error: {}
      },

      isPaymentProofLoader: false,

      // CPAS Accrediation
      capsAccrediation: {},

      // CONQUAS Accrediation
      conquasAccrediation: {},

      // SABS Accrediation
      sabsAccrediation: {},

      // Engagement Tracking and Notes
      engagementTrackingAndNotes: {
        individualMemberId: 0,
        individualEngagementId: 0,
        corporateEngagementTypeId: 0,
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
        updatedBy: 0,
        updatedAt: "",
      },

      // Add Credit Notes
      addEditCreditNote: {
        membershipPaymentId: 0,
        entranceRefund: 0,
        annualRefund: 0,
        chapterRefund: 0,
        subTotalRefund: 0,
        gstRefund: 0,
        discountRefund: 0,
        totalRefund: 0,
        cancellationReason: "",
        creditNoteMonth: "",
        compareEntranceRefund: 0,
        compareAnnualRefund: 0,
        compareChapterRefund: 0,
        membershipPaymentTypeId: 0,
      },
      corporateFinanceInfo: [],
      individualCreditNotePaymentInfo: {},

      individualContribution: {
        individualContributionId: 0,
        name: "",
        roleId: 0,
        startDate: "",
        endDate: "",
        individualMemberId: 0,
        workgroupMappingId: 0,
        chapterInvolvement: true,
        committeeInvolvement: true,
      },

      validStateSIIS: {
        isValid: true,
        error: {},
      },
      validStateEngagement: {
        isValid: true,
        error: {},
      },
      validStateCommittee: {
        isValid: true,
        error: {},
      },
      validStateIndProfile: {
        isValid: true,
        error: {},
      },
      validStateIndContribution: {
        isValid: true,
        error: {},
      },
      validStateMemInfo: {
        isValid: true,
        error: {},
      },
      validCreditNote: {
        isValid: true,
        error: {}
      },
      validCreditNote2: {
        isValid: true,
        error: {}
      },
      designationList: [],
      engagemmentTrackingList: [],
      individualContributionList: [],

      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  // Method Declaration Section Start 
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);

    let checkRead = window.location.search;
    let getRead = checkRead.split("=");
    let checkFinal = getRead[2];
    if (checkFinal === "ReadOnly") {
      this.setState({ isDisabledFlag: true });
    }

    let individualId = params.get("");
    let data = getAuthProps();
    this.setState({ updatedBy: data.adminUserId, updatedAppId: 111 });
    if (individualId && (individualId = checkParameters(individualId, "Number"))) {
      if (individualId != null && individualId > 0) {
        this.setState({ individualMemberId: individualId },
          () => {
            this.getAllDropdownsForIndividualMember();
          }
        );
      }

    } else {
      this.getAllDropdownsForIndividualMember();
    }

  }
  ////////////////////////////////////////////////////////////////////////// 


  // API Method Declaration Section Start


  getInvoiceReceiptPdfByBookingId = (bookingId, type) => {
    let reqParams = [bookingId, type];
    //reqParams.isMember = 12;
    this.individualMemberServices.getInvoiceReceiptPdfByBookingId(reqParams).then((response) => {
      if (response != null) {
        var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

        var filename = "Booking";
        if (type === "Receipt") {
          filename = `Booking Receipt ${formattedDate}`;
        } else if (type === "Invoice") {
          filename = `Booking Invoice ${formattedDate}`;
        }
        var file = new Blob([response.data], { type: "application/pdf" });

        // const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        //  const pdfWindow = window.open();
        //  pdfWindow.location.href = fileURL;       

        saveAs(file, filename + ".pdf");
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };


  getIndividualContribution = (id) => {
    this.individualMemberServices.getIndividualContribution(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ individualContributionList: response.responseItem.responseContent });
        console.log("Individual Contribution List :", response.responseItem.responseContent);
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getAllDropdownsForIndividualMember = () => {
    this.individualMemberServices.getAllDropdownsForIndividualMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        var accountStatus = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AccountStatus");
        accountStatus.map(function (item) { delete item["dropdownName"]; return item; });
        // accountStatus = accountStatus.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ status: accountStatus });

        var engagementType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EngagementTypes");
        engagementType.map(function (item) { delete item["dropdownName"]; return item; });
        // engagementType = engagementType.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ engagementType: engagementType });

        var _By = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Users");
        _By.map(function (item) { delete item["dropdownName"]; return item; });
        // _By = _By.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ _By: _By });
        var nationality = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Nationality");
        nationality.map(function (item) { delete item["dropdownName"]; return item; });
        // nationality = nationality.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ nationalityList: nationality });

        var country = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Country");
        country.map(function (item) { delete item["dropdownName"]; return item; });
        country = country.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ countryList: country, placeOfBirthList: country });

        var educationLevel = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EducationLevel");
        educationLevel.map(function (item) { delete item["dropdownName"]; return item; });
        // educationLevel = educationLevel.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ educationLevelList: educationLevel });

        var hearAboutUs = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "HearAboutUs");
        hearAboutUs.map(function (item) { delete item["dropdownName"]; return item; });
        // hearAboutUs = hearAboutUs.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ konwAboutUsList: hearAboutUs });

        var billingCategory = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BillingCategory");
        billingCategory.map(function (item) { delete item["dropdownName"]; return item; });
        // billingCategory = billingCategory.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ billingCategory: billingCategory }, () => console.log("Billing Category :", this.state.billingCategory));

        // var MembershipTypesForCorporate = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipTypes");
        // var newMembershipType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipTypes");        

        // newMembershipType.map(function (item) { delete item["dropdownName"]; });
        // //delete newMembershipType[3];
        // // membershipType = membershipType.map((p) => ({ value: p.id, label: p.name }));
        // this.setState({ membershipTypes: newMembershipType }, () => console.log("membership Types Category :", this.state.membershipTypes));

        var membershipType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipTypes");
        membershipType.map(function (item) { delete item["dropdownName"]; return item; });
        // membershipType = membershipType.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ membershipTypes: membershipType }, () => console.log("membership Types Category :", this.state.membershipTypes));

        //let membershipTypeForNewMembers = MembershipTypesForCorporate.map(function (item) { delete item["dropdownName"]; });
        //let membershipTypeForNewMembers = [{...MembershipTypesForCorporate.find(o => o.name === 'Individual Member')}]  ;  
        //membershipTypeForNewMembers = membershipTypeForNewMembers.map((p) => ({ id: p.id, name: p.name , valueOrdering: 0 }));    
        //membershipTypeForNewMembers = membershipType.map((p) => { return if(p.name ==='Individual Member'){ value = p.id, label= p.name }});
        //this.setState({ membershipTypeForNewMember: membershipTypeForNewMembers }, () => console.log("membership Types Category :", this.state.membershipTypes));
        // var companyType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "KnowAboutUs");
        // companyType.map(function (item) { delete item["dropdownName"]; });
        // companyType = companyType.map((p) => ({ value: p.id, label: p.name }));
        // this.setState({ companyType: companyType }, () => {
        //     let data = this.state.accountDetails;
        //     data.companyTypeId = this.state.companyType[0].value;
        //     data.selectedCompanyType = this.state.companyType[0];
        //     this.setState({ accountDetails: data })
        // });
      }
      this.getIndividualAccountInforamtion(this.state.individualMemberId);
    });
  }



  //  Get Segment Information Methods Section Start //

  // Get Individual Account Inforamt Method
  getIndividualAccountInforamtion = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualAccountInformation(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          let data = this.state.individualAccountInfo;
          data.individualJoinedDate = response.responseItem.responseContent.updatedAt;
          if (response.message == null)
            this.setState({

              individualAccountInfo: response.responseItem.responseContent,
              corporateMemberId: response.responseItem.responseContent.corporateMemberId,
              membershipTypeName: response.responseItem.responseContent.membershipTypeName,
              certificateMembershipNo: response.responseItem.responseContent.certificateMembershipNo,
              conquasMemberId: response.responseItem.responseContent.conquasMemberId,
              CertificateMembershipNo: response.responseItem.responseContent.certificateMembershipNo,
              isMigratedData: response.responseItem.responseContent.isMigratedData,

              isConquasMember: response.responseItem.responseContent.isConquasMember
            }, () => {
              if (response.responseItem.responseContent.conquasMemberId > 0) {
                this.getCONQUASMembershipSubscriptionById();
              }
            })
          //let profile = this.state.individualProfile;
          //profile.firstName = response.responseItem.responseContent.firstName;
          //profile.lastName = response.responseItem.responseContent.lastName;
          //this.setState({ individualProfile: profile });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  //  Get CONQUAS Membership Subscription Detail By Id Method 
  getCONQUASMembershipSubscriptionById = () => {
    if (this.state.conquasMemberId > 0 && this.state.conquasMemberId != null) {
      this.individualMemberServices.getCONQUASMembershipSubscriptionById(this.state.conquasMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.CONQUASAccrediation };
            res.conquasMemberId = response.responseItem.responseContent.conquasMemberId;
            res.conquasMemberSubscriptionId = response.responseItem.responseContent.conquasMemberSubscriptionId;
            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subscriptionEndDate = response.responseItem.responseContent.subscriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.certificatePath = response.responseItem.responseContent.certificatePath;
            // res.certificatePath = response.responseItem.responseContent.certificatePath;
            // res.isForRenewal = response.responseItem.responseContent.isForRenewal;
            // res.renewalUrl = response.responseItem.responseContent.renewalUrl;
            this.setState({ CONQUASAccrediation: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  // Get Individual Basic Information Method Start
  // getIndividualProfileDetails = (id) => {
  //   if (id > 0) {
  //     this.individualMemberServices.getIndividualProfileDetails(id).then((response) => {
  //       console.log("get Individual profile Details :", response)
  //       if (response.statusCode === 200 && response.responseItem != null) {
  //         let details = response.responseItem.responseContent;
  //         let organisationList = [...this.state.organisation];
  //         // akshay
  //         if (details.title !== null && details.title !== "") {
  //           var array = response.responseItem.responseContent.title.split(',');
  //           var designation = [];
  //           array.forEach(topic => {
  //             designation.push(this.state.designationList.find(deg => deg.label === topic));
  //           });
  //           details.selectedDesignation = designation;
  //         }
  //         if (response.responseItem.responseContent.isNonMember === true) {
  //           details.emailText = response.responseItem.responseContent.email;
  //           details.email = response.responseItem.responseContent.email;
  //         }
  //         else {
  //           var fields = details.email.split('@');
  //           let companyDetails = organisationList.find(x => x.id === details.corporateMemberId);
  //           if (companyDetails) {
  //             details.companyName = companyDetails.name;
  //           }
  //           details.emailText = fields[0];
  //           details.emailDomainId = details.corporateMemberId;
  //           details.email = response.responseItem.responseContent.email;
  //         }
  //         this.getOrganisationProfileById(details.corporateMemberId)
  //         this.setState({ individualProfile: response.responseItem.responseContent }, () => {
  //           let data = { ...this.state.individualProfile };
  //           data.selectedDesignation = designation;
  //           this.setState({ individualProfile: data });
  //         })
  //         this.getEmailHistoryForIndividualMemberById(this.state.individualMemberId);
  //       } else {
  //         this.swalServices.Error(response.message);
  //       }
  //     });
  //   }
  // }

  // Get SCAL Membership Information (Individual) Method
  getIndividualMembershipSubscriptionById = () => {

    if (this.state.individualMemberId != null && this.state.individualMemberId > 0) {
      this.individualMemberServices.getIndividualMembershipSubscriptionById(this.state.individualMemberId).then((response) => {


        if (response.statusCode === 200 && response.responseItem != null) {


          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.scalMembershipInforamtion };
            res.individualSubscriptionId = response.responseItem.responseContent.individualSubscriptionId;
            res.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
            res.billingCategoryId = response.responseItem.responseContent.billingCategoryId;
            res.IsMigratedData = response.responseItem.responseContent.isMigratedData;
            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subcriptionEndDate = response.responseItem.responseContent.subcriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            this.setState({ scalMembershipInforamtion: res });

            this.getIndividualMemberTypeByID(this.state.individualMemberId);
          } else {
            let membershipType = this.state.membershipTypes;
            let obj = [];
            membershipType.forEach(element => {
              if (element.id === 4 || element.id === 6) {
                obj.push(element);
              }
            });
            this.setState({ membershipTypes: obj })
            let res = { ...this.state.scalMembershipInforamtion };
            res.membershipTypeId = 6;
            this.setState({ scalMembershipInforamtion: res })
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  // Get Engagement Tracking and Notes Method
  getIndividualEngagementTrackingById = (id) => {

    this.individualMemberServices.getIndividualEngagementTrackingById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ engagementTrackingAndNotes: response.responseItem.responseContent })
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  getIndividualMemberTypeByID = (id) => {

    this.individualMemberServices.GetIndividualMemberTypeByID(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent) {
          if (response.responseItem.responseContent.isCorporate) {
            let membershipType = this.state.membershipTypes;
            let obj = [];
            membershipType.forEach(element => {
              if (element.id === 1 || element.id === 2 || element.id === 3 || element.id === 6) {
                obj.push(element);
              }
            });
            this.setState({ membershipTypes: obj })
          }
        } else {
          let membershipType = this.state.membershipTypes;
          let obj = [];
          membershipType.forEach(element => {
            if (element.id === 4 || element.id === 6) {
              obj.push(element);
            }
          });
          this.setState({ membershipTypes: obj })
        }
        // this.setState({ engagementTrackingAndNotes: response.responseItem.responseContent })
      }

    });
  }

  getDomainByOrganisationId = (id) => {
    // var domainId = this.state.emailDomain.filter(domain => domain.id === id);
    // this.setState({ emailDomain: domainId });

    var domainId = this.state.emailDomain.find(domain => domain.id === id);
    let setDomain = this.state.individualProfile;
    setDomain.emailDomainId = domainId.id;
    this.setState({ individualProfile: setDomain });
  }

  getEngagementTrackingList = (id) => {
    if (id > 0) {
      this.individualMemberServices.getEngagementTrackingList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          console.log("list : ", response);
          this.setState({ engagemmentTrackingList: response.responseItem.responseContent })

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  getOrganisationProfileById = (corporateMemberId) => {
    // if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
    this.corporateMemberServices.getOrganisationProfileById(corporateMemberId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let res = { ...this.state.individualProfile };
        res.companyAddress = response.responseItem.responseContent.mailingAdress;
        res.companyAddress2 = response.responseItem.responseContent.mailingAdress2 === null ? " " : response.responseItem.responseContent.mailingAdress2;
        res.companyAddress3 = response.responseItem.responseContent.mailingAdress3 === null ? "" : response.responseItem.responseContent.mailingAdress3;
        this.setState({ individualProfile: res });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
    // }
  }

  addEditIndividualAccountInformation = () => {
    //if (this.isValidSIIS()) {
    let request = this.state.individualAccountInfo;
    request.individualMemberId = this.state.individualMemberId;

    request.updatedBy = this.state.updatedBy;
    request.updatedAppId = this.state.updatedAppId;
    this.individualMemberServices.addEditIndividualAccountInformation(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.keyId !== this.state.individualMemberId) {
          this.setState({ individualMemberId: response.responseItem.responseContent.keyId }, () => this.getIndividualAccountInforamtion(this.state.individualMemberId))
          //this.setState({ individualMemberId: response.responseItem.responseContent.keyId }, () => this.openSection("IndividualBasicInforamtion")) // this.getIndividualAccountInforamtion(this.state.individualMemberId)
        }
        this.swalServices.Success("Scal information inserted successfully.");
      } else {
        this.swalServices.Error(response.message);
      }
    });
    //}
  }

  // Add Edit SCAL Membership Inforamtion Method
  addEditIndividualMembershipSubscription = () => {
    if (this.state.individualMemberId > 0 && this.state.individualMemberId != null) {

      var request = this.state.scalMembershipInforamtion;
      request.individualSubscriptionId = this.state.scalMembershipInforamtion.individualSubscriptionId;
      request.membershipTypeId = this.state.scalMembershipInforamtion.membershipTypeId;
      request.billingCategoryId = this.state.scalMembershipInforamtion.billingCategoryId;
      request.subscriptionStartDate = this.state.scalMembershipInforamtion.subscriptionStartDate;
      request.subcriptionEndDate = this.state.scalMembershipInforamtion.subcriptionEndDate;
      request.individualMemberId = this.state.individualMemberId;
      //request.createdBy = 1;
      request.createdBy = this.state.updatedBy;
      request.createdAppId = this.state.updatedAppId;
      if (this.isValidMembershipInformation()) {
        this.setState({ isLoading: true });
        this.individualMemberServices.addEditIndividualMembershipSubscription(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              var res = this.state.scalMembershipInforamtion;
              res.updatedAt = new Date();
              //res.updatedBy = 1;
              res.updatedBy = this.state.updatedBy;
              res.individualSubscriptionId = response.responseItem.responseContent.keyId;
              this.setState({ scalMembershipInforamtion: res }, () => { this.swalServices.Success("Individual membership subscription"); });
            }
            else {
              this.swalServices.Error("Individual membership subscription");
            }
          }
          else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter individual account information.");
    }
  }

  addEditEngagementTracking = () => {
    if (this.state.individualMemberId > 0 && this.state.individualMemberId !== null) {
      if (this.isValidIndividualEngagement()) {
        let request = this.state.engagementTrackingAndNotes;
        request.updatedBy = this.state.updatedBy;
        request.UpdatedAppId = this.state.updatedAppId;
        request.individualMemberId = this.state.individualMemberId;
        this.individualMemberServices.addEditEngagementTracking(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("Engagement inserted successfully.");
            this.getEngagementTrackingList(this.state.individualMemberId);
            this.resetEngagement();
          } else {
            this.swalServices.Error(response.message);
          }
        });
      }
    }
    else {
      //this.swalServices.Error("Please enter first SIIS individual account information.");
      this.swalServices.Error("Please enter first Scal individual account information.");
    }


  }

  addEditIndividualBasicInformation = () => {
    if (this.state.individualMemberId > 0 && this.state.individualMemberId !== null) {
      //if (this.isValidIndividualProfile()) {
      let request = { ...this.state.individualBasicInforamtion };
      request.individualMemberId = this.state.individualMemberId;
      //request.individualMemberId = this.state.individualMemberId;
      if (request.isNonMember === true) {
        request.email = request.emailText ? request.emailText : '';
        request.isNonMember = true;
        request.companyName = request.companyName ? request.companyName : '';
      }
      // else {
      //   let domain = this.state.emailDomain.find(x => x.id === request.emailDomainId);
      //   request.email = `${request.emailText}@${domain.name}`;
      //   request.isNonMember = false;
      //   request.companyName = '';
      // }
      if (request.selectedDesignation && request.selectedDesignation.length > 0) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.title = arr1.join([',']);
      } else {
        request.title = "";
      }
      request.individualMemberId = this.state.individualMemberId;
      console.log("email domain :", request);
      request.updatedAppId = this.state.updatedAppId;
      request.updatedBy = this.state.updatedBy;

      this.individualMemberServices.addEditIndividualBasicInformation(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Email") {
          console.log("response : ", response);
          this.swalServices.Error("Email address already exists.");
        } else if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Individual profile details updated successfully");
          //this.getIndividualProfileDetails(this.state.individualMemberId);
          this.getIndividualBasicInformationById(this.state.individualMemberId);

        }
        else {
          this.swalServices.Error(response.message);
        }
      });
      //}
    } else {
      this.swalServices.Error("Please enter first Scal individual account information.");
    }
  }

  getIndividualAccountInformation = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualAccountInformation(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.message == null)
            this.setState({
              siisIndividualAccountInfo: response.responseItem.responseContent,
              corporateMemberId: response.responseItem.responseContent.corporateMemberId
            })
          let chapInfo = this.state.chapterInformation;
          chapInfo.availableChapters = response.responseItem.responseContent.availableChapter ? response.responseItem.responseContent.availableChapter : 0;
          chapInfo.joinedChapters = response.responseItem.responseContent.joinedChapter ? response.responseItem.responseContent.joinedChapter : 0;
          chapInfo.purchasedChapters = response.responseItem.responseContent.purchasedChapters ? response.responseItem.responseContent.purchasedChapters : 0;
          this.setState({ chapterInformation: chapInfo });

          let profile = this.state.individualProfile;
          profile.firstName = response.responseItem.responseContent.firstName;
          profile.lastName = response.responseItem.responseContent.lastName;
          this.setState({ individualProfile: profile });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  // Save image
  saveMainImage = (e, file, keyId, imageIdentity) => {
    file.storagePath = imageIdentity;
    // var selectedFile = e.target.files[0];
    this.individualMemberServices.storeBase64Image(file).then((response) => {
      if (response.fileName != null) {
        this.swalServices.Success("Image Uploaded");
        let request = this.state.individualBasicInforamtion;
        request.photo = response.fileName;
        this.setState({ individualBasicInforamtion: { ...request } });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  SendPaymentLinkForConquas = (id) => {

    if (id === 0 || id === null || id === undefined) {
      id = 0
    }
    // var selectedFile = e.target.files[0];
    this.conquasMemberService.SendPaymentLinkForConquas(id).then((response) => {
      if (response.statusCode === 200) {
        this.swalServices.Success("Payment Link Send");

      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  // Edit enagagement Tracking list
  onEdit = (id) => {
    let reset = this.state.validStateEngagement;
    reset.error = {}
    //this.setState({ validStateEngagement: {...reset} })
    this.getIndividualEngagementTrackingById(id);
  }

  //CREDIT NOTE FUNCTIONALITY 
  GetCreditNoteInfoForIndividualMember = (membershipPaymentId, paymentStatus, isReceipt) => {
    if (this.state.individualMemberId > 0 && membershipPaymentId > 0) {
      let request = [this.state.individualMemberId, membershipPaymentId];
      this.financeServices.GetCreditNoteInfoForIndividualMember(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.setState({ individualCreditNotePaymentInfo: response.responseItem.responseContent });
          let creditNote = { ...this.state.addEditCreditNote };
          if (isNullString(paymentStatus) === 'Pending' && isNullBoolean(isReceipt) === false) {
            creditNote.entranceRefund = 0
            creditNote.annualRefund = 0
            creditNote.chapterRefund = 0;
            creditNote.subTotalRefund = 0;
            creditNote.gstRefund = 0;
            creditNote.discountRefund = 0;
            creditNote.totalRefund = response.responseItem.responseContent.paidPriceByReconciliation
              ? response.responseItem.responseContent.paidPriceByReconciliation : 0;
            creditNote.compareEntranceRefund = 0;
            creditNote.compareAnnualRefund = 0;
            creditNote.compareChapterRefund = 0;
          } else {
            creditNote.entranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.annualRefund = response.responseItem.responseContent.annualFee;
            creditNote.chapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.subTotalRefund = response.responseItem.responseContent.subTotalAmount;
            creditNote.gstRefund = response.responseItem.responseContent.gstAmount;
            creditNote.discountRefund = response.responseItem.responseContent.discountedAmount;
            creditNote.totalRefund = response.responseItem.responseContent.totalAmount;
            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
          }
          creditNote.membershipPaymentTypeId = response.responseItem.responseContent.membershipPaymentTypeId;
          creditNote.creditNoteMonth = response.responseItem.responseContent.subscriptionStartDate;// moment();
          creditNote.paymentStatus = paymentStatus;
          creditNote.conquasMemberId = response.responseItem.responseContent.conquasMemberId ? response.responseItem.responseContent.conquasMemberId : 0;
          this.setState({ addEditCreditNote: creditNote, gstRate: response.responseItem.responseContent.gstRate });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  getCancellationReasonForCreditNote = () => {
    this.financeServices.getCancellationReasonForCreditNote().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null && response.responseItem.responseContent.length > 0) {
        this.setState({ cancellationReason: response.responseItem.responseContent });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  addFinanceCreditNote = () => {
    if (this.isValidFinanceCreditNote()) {
      if (this.isValidFinanceCreditNoteForOtherReason()) {
        let cookie = getAuthProps();
        const adminUserId = (cookie && cookie.adminUserId) ? cookie.adminUserId : '';
        let request = {
          IndividualMemberId: this.state.individualMemberId,
          bookingId: this.state.addEditCreditNote.membershipPaymentId,
          isCorporateMember: false,
          creditNoteMonth: this.state.addEditCreditNote.creditNoteMonth,
          creditNoteTotalRefund: this.state.addEditCreditNote.totalRefund,
          creditNoteStatusId: 1,
          creditNoteItem: [],
          creditNoteCancellationReason: this.state.addEditCreditNote.cancellationReason,
          CreatedAppId: 111,
          CreatedAt: moment(),
          CreatedBy: adminUserId,
          creditSubTotalAmount: this.state.addEditCreditNote.subTotalRefund,
          creditNoteGSTAmount: this.state.addEditCreditNote.gstRefund,
          creditNoteDiscountAmount: this.state.addEditCreditNote.discountRefund ? this.state.addEditCreditNote.discountRefund : 0,
          membershipPaymentTypeId: this.state.addEditCreditNote.membershipPaymentTypeId,
          conquasMemberId: this.state.addEditCreditNote.conquasMemberId,
        }
        let creditNoteItemList = [];
        let stardDate = this.state.individualCreditNotePaymentInfo.subscriptionStartDate;
        let endDate = this.state.individualCreditNotePaymentInfo.subcriptionEndDate;
        let membershipTypeName = this.state.individualCreditNotePaymentInfo.membershipTypeName;
        // creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Entrance Fee`, creditNoteItemAmount: this.state.addEditCreditNote.entranceRefund });
        creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Annual Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.annualRefund });
        // creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Additional Chapter Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.chapterRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership Subtotal Amount", creditNoteItemAmount: this.state.addEditCreditNote.subTotalRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership GST Amount", creditNoteItemAmount: this.state.addEditCreditNote.gstRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership Discounted Amount", creditNoteItemAmount: this.state.addEditCreditNote.discountRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership Total Amount", creditNoteItemAmount: this.state.addEditCreditNote.totalRefund });
        request.creditNoteItem = creditNoteItemList;
        this.financeServices.addFinanceCreditNote(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Credit Note"));
              this.getIndividualFinancialInfo(this.state.individualMemberId);
              //this.addCreditNoteInvoiceRecordsForBatchJob(response.responseItem.responseContent.keyId);
            }
            else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Credit note"));
            }
          }
          else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        });
      }
    }
  }
  // Internal Method Declaration Section Start


  // All Individual Segment Validation Method Section
  validateSIISIndividualAccount = (key) => {
    const returnValidState = validate(key, this.state.individualAccountInfo, SIISIndividualAccountValRules, this.state.validStateSIIS);
    this.setState({ validStateSIIS: returnValidState });
  }
  isValidSIIS = () => {
    const returnValidState = isValidForm(this.state.individualAccountInfo, SIISIndividualAccountValRules, this.state.validStateSIIS);
    this.setState({ validStateSIIS: returnValidState });
    return returnValidState.isValid;
  }
  validateIndividualProfile = (key) => {
    const returnValidState = validate(key, this.state.individualBasicInforamtion, IndividualProfileValRules, this.state.validStateIndProfile);
    this.setState({ validStateIndProfile: returnValidState });
  }
  isValidIndividualProfile = () => {
    const returnValidState = isValidForm(this.state.individualBasicInforamtion, IndividualProfileValRules, this.state.validStateIndProfile);
    this.setState({ validStateIndProfile: returnValidState });
    return returnValidState.isValid;
  }
  validateIndividualEngagement = (key) => {
    const returnValidState = validate(key, this.state.engagementTrackingAndNotes, EngagementTrackingValRules, this.state.validStateEngagement);
    this.setState({ validStateEngagement: returnValidState });
  }
  isValidIndividualEngagement = () => {
    const returnValidState = isValidForm(this.state.engagementTrackingAndNotes, EngagementTrackingValRules, this.state.validStateEngagement);
    this.setState({ validStateEngagement: returnValidState });
    return returnValidState.isValid;
  }

  validateMembershipInformation = (key) => {
    const returnValidState = validate(key, this.state.scalMembershipInforamtion, SCALMembershipInformationRules, this.state.validStateMemInfo);
    this.setState({ validStateMemInfo: returnValidState });
  }
  isValidMembershipInformation = () => {
    let newRules = SCALMembershipInformationRules;
    if (this.state.scalMembershipInforamtion.individualSubscriptionId === 0) {
      newRules.uploadPaymentProof = [
        {
          type: "require",
          message: "Please upload payment proof",
        },
      ]
    };
    //  let newRules2 = SCALMembershipInformationRules;
    if (this.state.scalMembershipInforamtion.comments === "" && this.state.scalMembershipInforamtion.uploadPaymentProof !== "") {

      newRules.comments = [
        {
          type: "require",
          message: "Please Enter Comments",
        },
      ]
    }



    const returnValidState = isValidForm(this.state.scalMembershipInforamtion, newRules, this.state.validStateMemInfo);
    this.setState({ validStateIndProfile: returnValidState });

    return returnValidState.isValid;
  }

  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(this.state.individualCreditNotePaymentInfo, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  }

  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState ? providedState : this.state.individualCreditNotePaymentInfo;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
  }

  isValidFinanceCreditNoteForOtherReason = () => {
    const newValidState = isValidForm(this.state.addEditCreditNote, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
    return newValidState.isValid;
  }

  validFinanceCreditNoteForOtherReason = (key, providedState) => {
    providedState = providedState ? providedState : this.state.addEditCreditNote;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
  }
  ////////////////////////////////////////////////////////////////////////////////////////////

  // rest engagement
  resetEngagement() {
    let details = this.state.engagementTrackingAndNotes;
    details.corporateEngagementTypeId = 0;
    details.engagementDate = "";
    details.engagementDescription = "";
    details.corporateEngagementRecordedById = 0;
    details.corporateEngagementFollowUpById = 0;
    details.individualEngagementId = 0;
    this.setState({ engagementTrackingAndNotes: details })
  }


  // Open Individual Member Segment And Call API on Open Segment Method
  openSection = (section) => {
    this.setState({
      openSection: this.state.openSection === section ? "" : section,
    });
    if (section === "IndividualAccountInformation") {
      // this.getIndividualAccountInforamtion(this.state.individualMemberId);
      this.getIndividualAccountInforamtion(this.state.individualMemberId);
    } else if (section === "IndividualBasicInforamtion") {
      this.getIndividualBasicInformationById(this.state.individualMemberId);
    }

    else if (section === "SCALMembershipInformation") {
      this.getIndividualMembershipSubscriptionById(this.state.individualMemberId);
    }

    else if (section === "CPASAccrediation") {
      // this.getChapterInformationById(this.state.corporateMemberId);
    }


    else if (section === "CONQUASAccrediation") {


      if (this.state.individualAccountInfo.conquasMemberId > 0) {
        this.getCONQUASMembershipSubscriptionById();
      }
      //this.getCONQUASMembershipSubscriptionById();
      // this.getIndividualCommittee(this.state.individualMemberId);
    }


    else if (section === "SABSAccrediation") {
      // this.getIndividualCommittee(this.state.individualMemberId);
    } else if (section === "EngagementTrackingAndNotes") {
      this.getEngagementTrackingList(this.state.individualMemberId);
      // this.getIndividualProfileDetails(this.state.individualMemberId);
      // this.getEmailHistoryForIndividualMemberById(this.state.individualMemberId);
      // this.getOrganisationProfileById(this.state.individualProfile?.corporateMemberId);
    } else if (section === "Financials") {
      this.getIndividualFinancialInfo(this.state.individualMemberId);
      //   this.getEmploymentInformation(this.state.individualMemberId);
    }
  };
  // Handle Change for multi select dropdown


  // handle change for checkbox

  handleChangeCheckBoxSIIS = (e) => {
    const { name, checked } = e.target;
    let det = this.state.individualAccountInfo;
    det[name] = checked;
    this.setState({ individualAccountInfo: { ...det } });
  }

  //Handle Input Change Method Section Start
  handleChangeIndividualMember(event, identity) {
    const name = event.target.name;
    var value = '';
    if (identity === "EngagementTracking" && name === 'engagementDescription') {
      value = event.target.value;
    } else {
      value = event.target.value.toUpperCase();

    }
    //const value = event.target.value.toUpperCase();
    if (identity === "IndividualBasicInformation") {
      let detail = this.state.individualBasicInforamtion;
      if (name === 'description') {
        //const array1 = value.trim().split(' ');
        const array = value.split(/\s+/).filter(word => word !== '').length;
        if (array <= this.state.characterLimit) {
          //const array = value.trim().split(" ");
          detail.descriptionWordsCount = array;
          detail.description = value;
          //let test = (this.state.characterLimit - detail.descriptionWordsCount);
          this.setState({ individualBasicInforamtion: detail });
        }

      } else {
        detail[name] = value;
        this.setState({ individualBasicInforamtion: detail });
      }
    }
    else if (identity === "IndividualAccountInforamtion") {
      let detail = this.state.individualAccountInfo;
      detail[name] = value;
      this.setState({ individualAccountInfo: { ...detail } });
    }
    else if (identity === "EngagementTracking") {
      let detail = this.state.engagementTrackingAndNotes;
      detail[name] = value;
      this.setState({ engagementTrackingAndNotes: { ...detail } });
    }
    else if (identity === "comments") {
      let detail = this.state.scalMembershipInforamtion;
      detail[name] = value;
      this.setState({ scalMembershipInforamtion: { ...detail } });
    } else if (identity === "CreditNoteOthers") {
      let detail = this.state.addEditCreditNote;
      detail[name] = value;
      this.setState({ addEditCreditNote: { ...detail } });
    }
    // else if (identity === "CreditNote") {
    //   let detail = { ...this.state.addEditCreditNote };
    //   var mydate = new Date(value);
    //   if (name === "creditNoteMonth") {
    //     const firstDay = new Date(mydate.getFullYear(), mydate.getMonth(), mydate.getDate());
    //     let val = moment(firstDay).format("yyyy-MM-DD");
    //     detail[name] = value;
    //   }
    //   if (name === "annualRefund") {
    //     detail[name] = value;
    //     detail.annualRefund = value;
    //     detail.compareAnnualRefund = value;
    //     detail.subTotalRefund = parseFloat(detail.annualRefund).toFixed(2);
    //     detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
    //     detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
    //   }

    //   this.setState({ addEditCreditNote: { ...detail } });
    // }
    else if (identity === "CreditNote") {

      let detail = { ...this.state.addEditCreditNote };
      // let val = value;
      // var mydate = new Date(value);
      let usedMonth = 0;
      let totalMonth = monthDifference(this.state.individualCreditNotePaymentInfo.subcriptionEndDate, this.state.individualCreditNotePaymentInfo.subscriptionStartDate, false) + 1;
      let data = { ...this.state.individualCreditNotePaymentInfo };
      if (name === "creditNoteMonth") {
        if (detail.paymentStatus !== 'Pending') {
          detail[name] = value;
          usedMonth = monthDifference(detail.creditNoteMonth, this.state.individualCreditNotePaymentInfo.subscriptionStartDate, false);
          let paidMonth = ((totalMonth - usedMonth) / totalMonth)
          // const firstDay = new Date(mydate.getFullYear(), mydate.getMonth(), mydate.getDate());
          // let val = moment(firstDay).format("DD-MM-yyyy");
          detail.annualRefund = value;
          detail.compareAnnualRefund = value;
          detail.annualRefund = this.state.individualCreditNotePaymentInfo.annualFee;
          detail.annualRefund = parseFloat(paidMonth * detail.annualRefund).toFixed(2);
          detail.subTotalRefund = parseFloat(detail.annualRefund).toFixed(2);
          detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
          detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
        }
        this.setState({ individualCreditNotePaymentInfo: data });
      }
      else if ((value = convertToDecimal(value)) || value === 0) {
        // if(data.entranceFee < value ){
        detail[name] = value;
        this.validFinanceCreditNoteForOtherReason(name, detail);
        // }
        //detail[name] = value;
        detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund) + parseFloat(detail.annualRefund) + parseFloat(detail.chapterRefund)).toFixed(2));
        detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
        detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
        detail.cancellationReason = value;
      }
      this.setState({ addEditCreditNote: { ...detail } });
    }
  }

  // Set filter parameter for single select dropdown
  setFilterSCALMembershipInformation(id, drpIdentity) {
    let subscription = this.state.scalMembershipInforamtion;
    if (drpIdentity === "SCALMembershipInformation") {
      subscription["membershipTypeId"] = id;
      this.validateMembershipInformation("membershipTypeId");
    }
    this.setState({ scalMembershipInforamtion: subscription });
  }

  // Set filter parameter for single select dropdown
  setFilterParametersEngagement(id, drpIdentity) {
    let engagement = this.state.engagementTrackingAndNotes;
    if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      this.validateIndividualEngagement("corporateEngagementTypeId");
    }
    else if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
    }
    else if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
    }
    this.setState({ engagementTrackingAndNotes: engagement });
  }

  // Dropdown Handle Method For Individual Basic Information
  setFilterParametersProfile(id, drpIdentity) {
    let basicInfo = this.state.individualBasicInforamtion;
    if (drpIdentity === "Country") {
      basicInfo["countryId"] = id;
      //this.validateIndividualProfile("countryId", this.state.individualBasicInforamtion);
    }
    else if (drpIdentity === "Birth_Place") {
      basicInfo["placeOfBirthId"] = id;
      //this.validateIndividualProfile("placeOfBirthId", this.state.individualBasicInforamtion);
    }
    else if (drpIdentity === "Nationality") {
      basicInfo["nationalityTypeId"] = id;
      // this.validateIndividualProfile("nationalityTypeId", this.state.individualBasicInforamtion);
    }
    else if (drpIdentity === "Education_Level") {
      basicInfo["educationLevelId"] = id;
      // this.validateIndividualProfile("educationLevelId", this.state.individualBasicInforamtion);
    }
    else if (drpIdentity === "Heared_About_Us") {
      basicInfo["howDoYouHearId"] = id;
      // this.validateIndividualProfile("howDoYouHearId", this.state.individualBasicInforamtion);
    }
    if (drpIdentity === "IndividualStatus") {
      basicInfo["individualStatusId"] = id;
      // this.validateIndividualProfile("individualStatusId",this.state.individualBasicInforamtion);
    }
    this.setState({ individualBasicInforamtion: basicInfo });
  }

  setFilterParametersIndividualAccountInfor(id, drpIdentity) {
    let details = this.state.individualAccountInfo;
    let cancel_reason = this.state.cancellationReason;
    if (drpIdentity === "accountStatus") {
      details["accountStatus"] = id;
      this.validateSIISIndividualAccount("accountStatus");
    }
    if (drpIdentity === "updatedBy") {
      details["updatedBy"] = id;
      this.validateSIISIndividualAccount("updatedBy");
    }
    if (drpIdentity === "DeletionReasonId") {
      details["deletionReasonId"] = id;
    }
    else if (drpIdentity === "cancel_reason") {
      //let othersArr = cancel_reason.filter((x) => x.id == id).find(({ name }) => name === "Others");
      //this.setState({ isOtherCancellationReason: othersArr !== null && othersArr !== undefined ? true : false });
      let data = { ...this.state.addEditCreditNote }
      let details = { ...this.state.individualCreditNotePaymentInfo }
      let findData = cancel_reason.filter((x) => x.id === id);
      if (findData.length > 0) {
        details.cancellationReason = findData[0].id;
        if (findData[0].name === "Others") {
          data.cancellationReason = '';
          this.setState({ isOtherCancellationReason: true });
          this.validFinanceCreditNoteForOtherReason("cancellationReason", data);
        } else {
          data.cancellationReason = findData[0].name;
          this.setState({ isOtherCancellationReason: false });
        }
      } else {
        data.cancellationReason = '';
        details.cancellationReason = 0;
        this.setState({ isOtherCancellationReason: false, individualCreditNotePaymentInfo: details });
      }
      this.validateFinanceCreditNote("cancellationReason", details);
      this.setState({ addEditCreditNote: data, individualCreditNotePaymentInfo: details });
    }
    this.setState({ individualAccountInfo: details });
  }

  handleChangeMultiDropdown(selected, identity) {
    var profile = { ...this.state.individualBasicInforamtion };
    if (identity === "Country") {
      profile.countryId = selected.value;
      this.setState({ individualBasicInforamtion: profile }, () => {
        //this.validateIndividualProfile("countryId");
      });
    } else if (identity === "Birth_Place") {
      profile.placeOfBirthId = selected.value;
      this.setState({ individualBasicInforamtion: profile }, () => {
        //this.validateIndividualProfile("placeOfBirthId");
      });
    }
  }

  getIndividualBasicInformationById = (id) => {
    if (id > 0) {
      this.individualMemberServices.getIndividualBasicInformationById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          console.log("Individual basic Information : ", response);
          // var individualInfo = response.responseItem.responseContent;
          this.setState({ individualBasicInforamtion: response.responseItem.responseContent })
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  getIndividualFinancialInfo = (individualMemberId) => {
    if (individualMemberId > 0 && individualMemberId != null) {
      this.corporateMemberServices.getIndividualFinancialInfo(individualMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          if (response.responseItem.responseContent.length > 0) {
            this.setState({ corporateFinanceInfo: response.responseItem.responseContent });
          }
          else {
            this.swalServices.Alert('No records found.');
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    } else {
    }
  };

  getIndividualFinanceReceiptInvoice = (receiptInvoiceId, isReceipt) => {
    if (receiptInvoiceId > 0 && receiptInvoiceId != null) {
      let request = [
        this.state.individualMemberId,
        receiptInvoiceId,
        isReceipt
      ]
      this.corporateMemberServices.getIndividualFinanceReceiptInvoice(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          let formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

          let filename = "";
          if (isReceipt === "Invoice") {
            filename = `Invoice-${formattedDate}`;
          } else if (isReceipt === "Receipt") {
            filename = `Receipt-${formattedDate}`;
          } else if (isReceipt === "Certificate") {
            filename = `Certificate-${formattedDate}`;
          }
          // if (isReceipt === true) {
          //   filename = `Receipt-${formattedDate}`;
          // } else {
          //   filename = `Invoice-${formattedDate}`;
          // }
          var file = new Blob([response.data], { type: "application/pdf" });
          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");
          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }


  getReceiptAndCertificate = (MembershipPaymentId) => {
    if (MembershipPaymentId > 0 && MembershipPaymentId != null) {
      //let request = MembershipPaymentId;

      this.corporateMemberServices.getReceiptAndCertificate(MembershipPaymentId).then((response) => {
        if (response.responseItem !== null && response.statusCode === 200) {
          this.swalServices.Success("Certificate & Receipt send to user successfully.");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {

    }
  }

  getIndividualMemberFiles = () => {
    let request = [
      this.state.individualMemberId,
      this.state.individualBasicInforamtion.constructionCertification
    ]
    this.individualMemberServices.getIndividualMemberFiles(request).then((response) => {
      if (response.data !== null && response.status === 200) {
        var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

        var filename = "";
        //if (isACRA === true) {
        filename = `constructionCertification${formattedDate}`;
        //} else {
        //filename = `Project_TractRecordFile_${formattedDate}`;
        //}
        var file = new Blob([response.data], { type: "application/pdf" });

        URL.createObjectURL(file);
        window.open(URL.createObjectURL(file), "_blank");
        saveAs(file, filename + ".pdf");
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }


  onFileChange = (event, fileType) => {

    const file = event.target.files[0];
    if (fileType === "Upload_Documents") {
      if (file && file.type === "application/pdf") {
        let uploadFile = { ...this.state.resFile };
        uploadFile.filename = file.name;
        uploadFile["file"] = file;
        // uploadFile.storagePath = "Resource";
        uploadFile.storagePath = "IndividualConstructionCertification";
        //Project Track Record
        let detail = { ...this.state.individualSignUpDetails };
        detail.constructionCertification = file.name;
        this.setState({ individualSignUpDetails: detail });
        this.individualMemberServices.uploadPDF(uploadFile).then((response) => {

          if (response != null) {
            if (response.isNotCurrupted === true) {
              let detail = { ...this.state.individualBasicInforamtion };
              detail.constructionCertification = response.fileName;
              detail.isValidateConstructionCertificationPath = true;
              this.setState({ individualBasicInforamtion: detail });
              this.swalServices.Success("File Uploaded");
            }
            else {
              this.swalServices.Error("Invalid Document");
            }
          }
          else {
            this.swalServices.Error(response.message);
          }
        })
      }
      else {
        this.swalServices.Error("Please Upload only PDF file !");
      }
    } else if (fileType === "Upload_Payment_Proof") {
      //const file = event.target.files[0];
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        let request = {};
        request.storagePath = "PaymentImages";
        request.file = file;
        request.fileName = file.name;
        this.setState({ isPaymentProofLoader: true });
        this.individualMemberServices.uploadPDF(request).then((response) => {
          if (response != null) {
            //if (request.storagePath === "PaymentImages") {
            let detail = { ...this.state.scalMembershipInforamtion };
            detail.uploadPaymentProof = response.fileName;
            //detail["fileDisplayName"] = file.name;
            this.setState({ scalMembershipInforamtion: detail }, () => {
              this.swalServices.Success("Payment Proof Uploaded");
              //this.validateField("uploadPaymentProof", "SLOTS_Application_Summary");
            });
            // }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isPaymentProofLoader: false });
        });
      } else {
        this.swalServices.Error("Please select only Image");
      }
    }
  };

  viewUpdateCONQUASMember = (value) => {
    value = this.state.individualAccountInfo.conquasMemberId;
    if (value && value > 0) {
      this.setState({ route: '/EditCONQUASMember/CONQUASMemberId?=' + encryptAES(value) });
    }
  };

  copyToClipboard = (copyUrl) => {

    // var copyText = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyUrl);
    this.swalServices.Toaster("Link Copied");
    // alert("Copied the text: " + copyUrl);
  };

  getCONQUASMemberCertificate = (e) => {
    if (this.state.conquasMemberId > 0) {

      let request = [
        this.state.CONQUASAccrediation.certificatePath
      ]
      this.conquasMemberService.getCONQUASMemberCertificate(request).then((response) => {

        if (response.data !== null && response.status === 200) {

          // var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

          // var filename = "";
          // //if (isACRA === true) {
          // filename = `${type}${formattedDate}`;
          // //} else {
          // //filename = `Project_TractRecordFile_${formattedDate}`;
          // //}
          // var file = new Blob([response.data], { type: "application/pdf" });

          // URL.createObjectURL(file);
          // window.open(URL.createObjectURL(file), "_blank");
          // saveAs(file, filename + ".pdf");
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

          var filename = `Certificate-${formattedDate}`;

          var file = new Blob([response.data], { type: "application/pdf" });
          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");
          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }
  isValidateAllFields = () => {

    const newValidState = isValidForm(
      this.state.paymentProofApproveDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  savePaymentReconcilationDetailsByAdmin = () => {

    let req = {};
    req.paymentProofId = this.state.singlePaymentProofDetails.paymentProofId;
    req.bookingId = this.state.singlePaymentProofDetails.bookingId;
    req.amountCollected = this.state.paymentProofApproveDetails.amountCollected;
    req.amountCollectedDate = this.state.paymentProofApproveDetails.amountCollectedDate;
    req.addreceipt = this.state.paymentProofApproveDetails.addreceipt
    req.updatedAppId = this.state.updatedAppId;
    req.updatedAt = new Date();
    req.updatedBy = this.state.updatedBy;
    req.bankInformationName =
      this.state.paymentProofApproveDetails.bankInformationName;
    req.bankInformationId =
      this.state.paymentProofApproveDetails.bankInformationNameId;
    if (this.isValidateAllFields()) {
      let collectedAmount = 0;
      if (
        this.state.singlePaymentProofDetails &&
        this.state.singlePaymentProofDetails.paymentList
      ) {
        this.state.singlePaymentProofDetails.paymentList.forEach((element) => {
          collectedAmount += element.amountCollected;
        });
      }

      if (
        this.state.singlePaymentProofDetails.reconciliationTotalAmount <
        collectedAmount +
        Number(this.state.paymentProofApproveDetails.amountCollected)
      ) {
        this.swalServices.Warning("You can't insert more than total amount.");
      } else {
        this.setState({ isAddRecord: true });
        this.membershipPricingServices.savePaymentReconcilationDetailsByAdmin(req).then(

          (response) => {

            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent != null) {
                let amount = Number(this.state.paymentProofApproveDetails.amountCollected)
                if (amount + collectedAmount
                  === Number(this.state.singlePaymentProofDetails.reconciliationTotalAmount.toFixed(2))) {
                  console.log(this.state.singlePaymentProofDetails);
                  // TODO: need to discuss with Sanjay , Jay and Jay bhatt

                  this.acceptTotalPaidAmountInReconcilation(

                    this.state.singlePaymentProofDetails.bookingId,
                    this.state.singlePaymentProofDetails.corporateMemberId,
                    this.state.singlePaymentProofDetails.membershipPaymentTypeId,
                    this.state.singlePaymentProofDetails.slotsMemberId,
                    this.state.singlePaymentProofDetails.conquasMemberId,
                    this.state.singlePaymentProofDetails.studentMemberId,
                  )
                  this.resetPaymentProofDetails();
                  this.getEventPaymentReconcilationList();
                  // this.setState({ setpaymentApprovalModalOpen: false }, () => {
                  //   this.resetPaymentProofDetails();
                  //   this.getEventPaymentReconcilationList();
                  // });
                } else {
                  this.setState({ setpaymentApprovalModalOpen: false }, () => {
                    this.resetPaymentProofDetails();
                    this.getEventPaymentReconcilationList();
                  });
                  this.swalServices.Success(
                    "Payment reconciliation details inserted successfully."
                  );
                }
                this.setState({ isAddRecord: false });
              }
            } else {
              this.swalServices.Error(response.message);
            }
          }
        );
      }
    }
  };


  addEditIndividualMembershipTaskAndSendPaymentLink = () => {
    if (this.state.taskDetails.isApplicationFee) {
      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
          CommonSuccessMessages.Send_Payment_Link.replace(
            "{0}",
            "Payment Link"
          ),
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          //console.log("Confirm Response", response);
          let resp = response;
          if (resp === true) {

            let isValidInput = this.isValidateAllFields();
            if (isValidInput) {
              //var pricingList = [];
              var request = { ...this.state.taskDetails };
              request.taskId = this.state.selectedTaskId;
              // request.push(this.state.systemCalculatedTable.concat(
              //   this.state.percentageDiscountTable
              // ));
              // request.pricingList = [];
              // request.pricingList = this.state.systemCalculatedTable.concat(
              //   this.state.percentageDiscountTable,
              //   this.state.basePriceTable
              // );
              // request.pricingList.push(this.state.basePriceTable);
              // request.pricingList.push(this.state.percentageDiscountTable);
              // request.percentageDiscountTable = this.state.percentageDiscountTable;
              request.IsApprovalRequired = false;
              request.IsPaymentLinkSent = true;
              request.updatedBy = this.state.updatedBy;
              request.updatedAppId = this.state.updatedAppId;
              this.setState({ isLoadingPayment: true });

              this.TaskListServices.addEditIndividualMembershipTaskAndSendPaymentLink(
                request
              ).then((response) => {
                //console.log("addEditMembershipTaskAndSendPaymentLink", response);
                if (
                  response.statusCode === 200 &&
                  response.responseItem != null
                ) {
                  // window.location.replace('https://web.sgtech-test.infixsofttech.com//membership/MembershipPayment/' + response.responseItem.responseContent.keyId)
                  // this.setState({ setAddeditModalOpen: false }, () =>
                  //   this.getAllIndividualMemberTaskList()
                  // );
                  //this.swalServices.Success(
                  //  SuccessMessage.Update_Success.replace("{0}", "Task")
                  //);
                  // this.addEditMembershipTask()
                  this.setState({ setPaymentLink: true }, () =>
                    this.setState({ setAddeditModalOpen: false })
                  );
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoadingPayment: false });
              });
            }
          }
        });
    } else {
      this.swalServices.Error("Please accept application fee.");
    }
  };
  SendPaymentLinkForIndividual = () => {
    this.setState({ isPaymentLinkLoader: true });
    this.individualMemberServices.SendPaymentLinkForIndividual(this.state.individualMemberId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          this.swalServices.Success(
            "Payment Link has been sent successfully."
          );
          //this.getSLOTSMemberDetailByMemberId(this.state.slotsMemberId);
        }
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isPaymentLinkLoader: false });
    });
  };
  SendRenewalMail = (e) => {
    if (this.state.conquasMemberId > 0) {
      this.setState({ isLoading: true });
      this.conquasMemberService.SendRenewalReminderMailForCONQUASMember(this.state.conquasMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.swalServices.Success("Send Renewal mail successfully");
          this.setState({ isLoading: false });
        } else {
          this.swalServices.Error(response.message);
        }
      });
      this.setState({ isLoading: false });
    }
  }
  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">

        {/*Individual Account Information Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="accountInformation"
              type="checkbox"
              name="corporate-d"
              value={"IndividualAccountInformation"}
              checked={this.state.openSection === "IndividualAccountInformation"}
              onClick={() => this.openSection("IndividualAccountInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="accountInformation"
            >
              <span className="small-title font-bold theme-color pl-5">
                Individual Account Information
              </span>
              {/* <div className="flex items-left justify-between text-[18px] text-[#000000]">
                <p> Individual ID: {this.state.individualAccountInfo.siisIndividualId ? this.state.individualAccountInfo.siisIndividualId : "SIIS"} | Engagement Score: {this.state.individualAccountInfo.engagementScore}</p>
              </div> */}
              <div className="flex items-left justify-between text-[18px] text-[#000000]">
                <p> Individual ID: {this.state.individualAccountInfo.siisIndividualId ? this.state.individualAccountInfo.siisIndividualId : "SCALL"} </p>
              </div>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <AccountInformation
                status={this.state.status}
                _By={this.state._By}
                // new 
                individualAccountInfo={this.state.individualAccountInfo}
                addEditIndividualAccountInformation={this.addEditIndividualAccountInformation.bind(this)}
                //siisIndividualAccountInfo={this.state.siisIndividualAccountInfo}
                handleChangeCheckBox={this.handleChangeCheckBoxSIIS.bind(this)}
                handleChange={this.handleChangeIndividualMember.bind(this)}
                setFilterParameters={this.setFilterParametersIndividualAccountInfor.bind(this)}
                validState={this.state.validStateSIIS}
                validate={this.validateSIISIndividualAccount.bind(this)}
                isDisabledFlag={this.state.isDisabledFlag}
              />
            </div>
          </div>
        </div>
        {/*Individual Account Information Card End*/}

        {/* Individual Basic Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="basicinfo"
              type="checkbox"
              name="corporate-d"
              value={"IndividualBasicInforamtion"}
              checked={this.state.openSection === "IndividualBasicInforamtion"}
              onClick={() => this.openSection("IndividualBasicInforamtion")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="basicinfo"
            >
              <span className="small-title font-bold theme-color pl-5">
                Individual Basic Information (SCAL)
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <IndividualBasicInformation
                mrMrs={this.state.mrMrs}
                countryList={this.state.countryList}
                placeOfBirthList={this.state.placeOfBirthList}
                nationalityList={this.state.nationalityList}
                educationLevelList={this.state.educationLevelList}
                konwAboutUsList={this.state.konwAboutUsList}
                characterLimit={this.state.characterLimit}
                individualBasicInforamtion={this.state.individualBasicInforamtion}
                validState={this.state.validStateIndProfile}
                isDisabledFlag={this.state.isDisabledFlag}
                handleChange={this.handleChangeIndividualMember.bind(this)}
                //onSave={this.addEditIndividualProfile.bind(this)}
                onSave={this.addEditIndividualBasicInformation.bind(this)}
                setFilterParameters={this.setFilterParametersProfile.bind(this)}
                validate={this.validateIndividualProfile.bind(this)}
                saveMainImage={this.saveMainImage.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                getIndividualMemberFiles={this.getIndividualMemberFiles.bind(this)}
                onFileChange={this.onFileChange.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Individual Basic Information End */}

        {/* Corporate Membership Subscription Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="Indi_Mem_Info"
              type="checkbox"
              name="corporate-d"
              value={" SCALMembershipInformation"}
              checked={this.state.openSection === "SCALMembershipInformation"}
              onClick={() => this.openSection("SCALMembershipInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="Indi_Mem_Info"
            >
              <span className="small-title font-bold theme-color pl-5">
                SCAL Membership Information (Individual)
              </span>
              <span className="text-xl items-left text-[#005B9C] font-semibold tracking-normal">
                {this.state.membershipTypeName}
                {/* {this.state.certificateMembershipNo ? <>: {this.state.certificateMembershipNo}</> : null} */}
              </span>
              {this.state.isMigratedData === true ?
                <>|
                  <span className="text-xl items-left text-[#005B9C] font-semibold tracking-normal">
                    {this.state.isMigratedData === true ? "IsMigrated" : ""}
                  </span>
                </>
                :
                null
              }
              {this.state.certificateMembershipNo ?
                <>|
                  <span className="text-xl items-left text-[#005B9C] font-semibold tracking-normal">
                    {this.state.certificateMembershipNo}
                  </span>
                </>
                :
                null
              }
              {/* <div className="flex items-left justify-between text-[18px] text-[#000000]">

                <p>{this.state.scalMembershipInforamtion.IsMigratedData ? "IsMigratedData" : null}</p>
              </div> */}
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <IndividualMembershipSubscription
                _by={this.state._By}
                billingCategory={this.state.billingCategory}
                membershipTypes={this.state.membershipTypes}
                //membershipTypeForNewMembers={this.state.membershipTypeForNewMembers}
                scalMembershipInforamtion={this.state.scalMembershipInforamtion}
                validState={this.state.validStateMemInfo}
                isLoading={this.state.isLoading}
                validStateIndProfile={this.state.validStateIndProfile}
                isDisabledFlag={this.state.isDisabledFlag}
                setFilterParameters={this.setFilterSCALMembershipInformation.bind(this)}
                onSave={this.addEditIndividualMembershipSubscription.bind(this)}
                onFileChange={this.onFileChange.bind(this)}
                isPaymentProofLoader={this.state.isPaymentProofLoader}
                handleChange={this.handleChangeIndividualMember.bind(this)}
              // validate={this.corporateInformationValidate.bind()}
              />
            </div>
          </div>
        </div>
        {/* Corporate Membership Subscription End */}

        {/* CPAS Accrediation Subscription Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="CPAS_Accrediation"
              type="checkbox"
              name="corporate-d"
              value={" CPASAccrediation"}
              checked={this.state.openSection === "CPASAccrediation"}
              onClick={() => this.openSection("CPASAccrediation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="CPAS_Accrediation"
            >
              <span className="small-title font-bold theme-color pl-5">
                CPAS Accrediation
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <CPASAccrediation />
            </div>
          </div>
        </div>
        {/* CPAS Accrediation Subscription End */}

        {/* CONQUAS Accrediation Subscription Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="CONQUAS_Accrediation"
              type="checkbox"
              name="corporate-d"
              value={"CONQUASAccrediation"}
              // disabled={this.state.individualAccountInfo.conquasMemberId ? false : true}
              // checked={
              //   this.state.individualAccountInfo.conquasMemberId
              //     ? this.state.openSection === "CONQUASAccrediation"
              //     : true 
              // }


              checked={this.state.individualAccountInfo.isConquasMember ? this.state.openSection === "CONQUASAccrediation" : this.state.conquasMemberId === 0 ? false : true}
              onClick={() => this.openSection("CONQUASAccrediation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="CONQUAS_Accrediation"
            >
              <span className="small-title font-bold theme-color pl-5">
                CONQUAS Accreditation
              </span>
              {
                this.state.individualAccountInfo.isConquasMember ? (
                  <span className="text-xl items-left text-[#005B9C] font-semibold tracking-normal">
                    Yes
                  </span>
                ) : (
                  <span className="text-xl items-left text-[#005B9C] font-semibold tracking-normal">
                    No
                  </span>
                )
              }
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <CONQUASAccrediation
                CONQUASAccrediation={this.state.CONQUASAccrediation}
                _by={this.state._by}
                setFilterParametersProfile={this.setFilterParametersProfile.bind(this)}
                setFilterParametersEngagement={this.setFilterParametersEngagement.bind(this)}
                setFilterSCALMembershipInformation={this.setFilterSCALMembershipInformation.bind(this)}
                setFilterParametersIndividualAccountInfor={this.setFilterParametersIndividualAccountInfor.bind(this)}
                onSave={this.addEditIndividualMembershipSubscription.bind(this)}
                handleChange={this.handleChangeIndividualMember.bind(this)}
                // validState={this.state.validMemberSub}
                // validate={this.memberSubValidate.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                //renewCorporateMember={this.renewCorporateMember.bind(this)}
                viewUpdateCONQUASMember={this.viewUpdateCONQUASMember.bind(this)}
                isLoading={this.state.isLoading}
                isDisabledFlag={this.state.isDisabledFlag}
                copyToClipboard={this.copyToClipboard.bind(this)}
                getCONQUASMemberCertificate={this.getCONQUASMemberCertificate.bind(this)}
                SendRenewalMail={this.SendRenewalMail.bind(this)}
              />
            </div>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              {/* <CPASAccrediation /> */}
            </div>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              {/* <IndividualMembershipSubscription
                _by={this.state._By}
                billingCategory={this.state.billingCategory}
                membershipTypes={this.state.membershipTypes}
                scalMembershipInforamtion={this.state.scalMembershipInforamtion}
                validState={this.state.validState}
              // onSave={this.addEditCorporateMembershipSubscription.bind(this)}
              // validate={this.corporateInformationValidate.bind()}
              // handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              /> */}
            </div>
          </div>
        </div>
        {/* CONQUAS Accrediation Subscription End */}

        {/* SABS Accrediation Subscription Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="SABS_Accrediation"
              type="checkbox"
              name="corporate-d"
              value={" SABSAccrediationnew"}
              checked={this.state.openSection === "SABSAccrediation"}
              onClick={() => this.openSection("SABSAccrediation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="SABS_Accrediation"
            >
              <span className="small-title font-bold theme-color pl-5">
                SABS Accrediation
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <CPASAccrediation />
            </div>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              {/* <IndividualMembershipSubscription
                _by={this.state._By}
                billingCategory={this.state.billingCategory}
                membershipTypes={this.state.membershipTypes}
                scalMembershipInforamtion={this.state.scalMembershipInforamtion}
                validState={this.state.validState}
              // onSave={this.addEditCorporateMembershipSubscription.bind(this)}
              // validate={this.corporateInformationValidate.bind()}
              // handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)} 
              /> */}
            </div>
          </div>
        </div>
        {/* SABS Accrediation Subscription End */}

        {/* Engagement Tracking and Notes Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="engage"
              type="checkbox"
              name="corporate-d"
              value={"EngagementTrackingAndNotes"}
              checked={this.state.openSection === "EngagementTrackingAndNotes"}
              onClick={() => this.openSection("EngagementTrackingAndNotes")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="engage"
            >
              <span className="small-title font-bold theme-color pl-5">
                Engagement Tracking and Notes
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <EngagementTrackingNotes
                _By={this.state._By}
                engagementList={this.state.engagemmentTrackingList}
                isCorporate={false}
                // new 
                engagementType={this.state.engagementType}
                engagementTrackingAndNotes={this.state.engagementTrackingAndNotes}
                isDisabledFlag={this.state.isDisabledFlag}
                onClick={this.addEditEngagementTracking.bind(this)}
                handleChange={this.handleChangeIndividualMember.bind(this)}
                onEdit={this.onEdit.bind(this)}
                setFilterParameters={this.setFilterParametersEngagement.bind(this)}
                validState={this.state.validStateEngagement}
                validate={this.validateIndividualEngagement.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Engagement Tracking and Notes End */}

        {/* Financials Card Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="Financials"
              type="checkbox"
              name="corporate-d"
              value={" Financials"}
              checked={this.state.openSection === "Financials"}
              onClick={() => this.openSection("Financials")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              for="Financials"
            >
              <span className="small-title font-bold theme-color pl-5">
                Financials
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <FinancialsRecord
                corporateFinanceInfo={this.state.corporateFinanceInfo}
                getIndividualFinanceReceiptInvoice={this.getIndividualFinanceReceiptInvoice.bind(this)}
                GetCreditNoteInfoForIndividualMember={this.GetCreditNoteInfoForIndividualMember.bind(this)}
                SendPaymentLinkForConquas={this.SendPaymentLinkForConquas.bind(this)}
                individualCreditNotePaymentInfo={this.state.individualCreditNotePaymentInfo}
                getCancellationReasonForCreditNote={this.getCancellationReasonForCreditNote.bind(this)}
                cancellationReason={this.state.cancellationReason}
                setFilterParameters={this.setFilterParametersIndividualAccountInfor.bind(this)}
                isOtherCancellationReason={this.state.isOtherCancellationReason}
                addEditCreditNote={this.state.addEditCreditNote}
                handleChange={this.handleChangeIndividualMember.bind(this)}
                addFinanceCreditNote={this.addFinanceCreditNote.bind(this)}
                validate={this.validateFinanceCreditNote.bind(this)}
                validCreditNote={this.state.validCreditNote}
                validate2={this.validFinanceCreditNoteForOtherReason.bind(this)}
                validCreditNote2={this.state.validCreditNote2}
                getReceiptAndCertificate={this.getReceiptAndCertificate.bind(this)}
                SendPaymentLinkForIndividual={this.SendPaymentLinkForIndividual.bind(this)}
                savePaymentReconcilationDetailsByAdmin={this.savePaymentReconcilationDetailsByAdmin.bind(this)}

              />
            </div>
          </div>
        </div>
        {/* Financials Card End*/}
      </div>
    );
  }
}