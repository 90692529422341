
import React, { Component } from "react";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import Table from "../../components/table/Table";
import BookingServices from "../../services/axiosServices/apiServices/BookingServices";
import ModalBasic from "../component/FormModal";
import moment from "moment";
import SwalServices from "../../services/swalServices/SwalServices";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages, CommonSuccessMessages } from "../../utils/Messages";
import ValidationText from "../../utils/validation/ValidationText";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import MembershipPricingServices from "../../services/axiosServices/apiServices/MembershipPricingServices";
import DateInput from "../../components/inputs/DateInput";
import { saveAs } from "file-saver";
import { getAuthProps } from "../../utils/AuthenticationLibrary";

export default class PaymentReconcilation extends Component {
  constructor(props) {
    super(props);
    this.BookingServices = new BookingServices();
    this.entryServices = new EntryManagementServices();
    this.swalServices = new SwalServices();
    this.commonServices = new CommonServices();
    this.membershipPricingServices = new MembershipPricingServices();
    this.state = {
      statusList: [
        {
          value: 1,
          label: "Pending Approval",
        },
        {
          value: 2,
          label: "Completed",
        },
        {
          value: 3,
          label: "Approved",
        },
        {
          value: 4,
          label: "Rejected",
        },
      ],
      paymentCategory: [
        {
          value: 1,
          label: "Event",
        },
        {
          value: 2,
          label: "Membership",
        },
        {
          value: 3,
          label: "External Invoice",
        },
        {
          value: 4,
          label: "Additional Chapter",
        },
      ],
      bankNameList: [],
      bookingId: 0,
      membershipPaymentTypeList: [],
      setpaymentApprovalModalOpen: false,
      paymentReconcilationList: [],
      isAddRecord: false,
      updatedBy: 0,
      updatedAppId: 0,
      adminName: '',
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchCommentsText: "",
        reconcilationStatus: "",
        filterDate: "",
        paymentCategory: "",
        selectedPaymentCategory: [],
      },
      totalResultes: "",
      isLoading: false,

      singlePaymentProofDetails: {
        paymentList: [],
        reconciliationTotalAmount: 0,
        filePath: "",
        statusName: "",
        bookingId: 0,
        corporateMemberId: 0,
        paymentCategory: "",
        financeAdhocInvoiceId: 0,
        comments: "",
        slotsMemberId: 0,
        conquasMemberId: 0,
        membershipType: 0,
        studentMemberId: 0
      },
      paymentProofExtension: '',
      paymentProofApproveDetails: {
        paymentProofId: 0,
        bookingId: 0,
        amountCollected: "",
        bankInformationName: "",
        bankInformationNameId: 0,
        amountCollectedDate: "",
        addreceipt: "",
      },
      setModal: false,
      outstandingInvoicesRequest: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchCommentsText: "",
      },
      outstandingInvoicesList: [],
      totalOutstandingInvoicesListCount: 0,
      outstandingInvoiceDeatilsModal: false,
      singleOutstandingInvoiceDetails: {
        membershipPaymentId: "",
        financeAdhocInvoiceId: "",
        bookingId: "",
        membershipPaymentTypeId: "",
        invoiceNo: "",
        paymentVia: "",
        amount: "",
        status: "",
        invoiceGenerationDate: "",
        corporateMemberId: "",
        IndividualMemberId: "",

        eventId: "",
        filePath: "",
        comments: "",
        fileDisplayName: "",
      },
      validationRule: {
        amountCollected: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "amount"
            ),
          },
          // {
          //   type: 'decimalNumber',
          //   message: CommonValidationMessages.FieldRequired.replace('{0}', 'only numbers')
          // },
        ],
        bankInformationName: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "bank name"
            ),
          },
        ],
        amountCollectedDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "Date"
            ),
          },
        ],
        addreceipt: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "Receipt"
            ),
          },
        ],
      },
      validationRule1: {
        comments: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "comments for payment"
            ),
          },

        ],
        fileDisplayName: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "payment proof"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      isPaidTotalPrice: false,
      isLoader: false,
    };
  }
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getEventPaymentReconcilationList(newPagination);
  };

  actions = (element, index, value) => {
    return element !== "paymentProofId" ? null : (

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <div class="flex">
          <div className="flex items-center relative">
            <button
              className="action-btn mr-4 flex items-center"
              aria-controls="add-edit-modal"
              // onClick={(e) => {
              //   let found = this.state.paymentReconcilationList.find(x => x.paymentProofId === value);
              //   if (found) {
              //     //  let tempArr = [];
              //     //  tempArr = found.pasy;
              //     this.setState({ singlePaymentProofDetails: found }, () => {
              //       e.stopPropagation();
              //       this.setState({ setpaymentApprovalModalOpen: true });
              //     });}}}
              onClick={(e) => this.openApprovalModal(e, value)}
            >
              {this.state.paymentReconcilationList[index].paymentProofStatusId === 3 ? (
                <p className="ml-2 tool-tip-txt underline text-[#005b9c]">View</p>
              ) : (
                <>
                  {this.state.paymentReconcilationList[index].paymentProofStatusId === 4 ?
                    null
                    :
                    <p className="ml-2 tool-tip-txt underline text-[#005b9c]">
                      View/Approve
                    </p>
                  }

                </>
              )}
            </button>
          </div>
          <div>
            {
              this.state.paymentReconcilationList[index].paymentProofStatusId === 3 || this.state.paymentReconcilationList[index].paymentProofStatusId === 1 ?
                (
                  <ActionDeleteButton
                    value={value}
                    id={index}
                    index={index}
                    onActionClick={this.onDelete}
                    text="Delete"
                  />
                ) :
                (
                  null
                )
            }
          </div>
        </div>


      </td>
    );
  };

  openApprovalModal = (e, value) => {

    let paymentReconcilationArray = [...this.state.paymentReconcilationList];
    let found = {
      ...paymentReconcilationArray.find((x) => x.paymentProofId === value),
    };
    if (found) {
      let filteredPaymentProofList = []
      if (found.membershipPaymentTypeId === 5) {
        filteredPaymentProofList = [
          ...paymentReconcilationArray.filter(
            (x) =>
              x.financeAdhocInvoiceId &&
              x.financeAdhocInvoiceId === found.financeAdhocInvoiceId &&
              x.paymentProofId !== found.paymentProofId
          ),
        ];
      }
      else {
        filteredPaymentProofList = [
          ...paymentReconcilationArray.filter(
            (x) =>
              x.bookingId &&
              x.bookingId === found.bookingId &&
              x.paymentProofId !== found.paymentProofId
          ),
        ];
      }

      let allPaymentList = [];
      filteredPaymentProofList.forEach((paymentProofObj, paymentProofIndex) => {
        let paymentList = {
          ...filteredPaymentProofList[paymentProofIndex].paymentList,
        };
        if (paymentList[0].amountCollected !== null && paymentList[0].amountCollectedDate !== null) {
          // found.paymentList.push(paymentList);
          allPaymentList.push(paymentList[0]);
          found.paidPrice = found.paidPrice + paymentProofObj.paidPrice;
        }
      });

      let tempPaymentList = found.paymentList;
      if (
        tempPaymentList[0].amountCollected != null &&
        tempPaymentList[0].amountCollectedDate != null
        //&& tempPaymentList[0].bankName != null
      ) {
        found.paymentList = found.paymentList.concat(allPaymentList);
      } else {
        found.paymentList = allPaymentList;
      }

      let getExtension = found.filePath.split('.').pop();
      //filename.split('.').pop();

      this.setState({ singlePaymentProofDetails: found, paymentProofExtension: getExtension }, () => {
        e.stopPropagation();
        console.log("1", this.state.singlePaymentProofDetails);
        console.log("1", this.state.paymentReconcilationList);
        this.setState({ setpaymentApprovalModalOpen: true });
      });
    }
  };

  getEventPaymentReconcilationList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });

    let request = {};
    request.pageNo = pagination.pageNo;
    request.pageSize = pagination.pageSize;
    request.orderByColumn = pagination.orderByColumn;
    request.orderFlag = pagination.orderFlag;
    request.searchText = pagination.searchText;
    request.searchCommentsText = pagination.searchCommentsText;
    request.reconcilationStatus = pagination.reconcilationStatus;
    request.filterDate = pagination.filterDate;
    request.paymentCategory = pagination.paymentCategory;
    // if (this.state.pagination.selectedPaymentCategory.length > 0) {
    //   let arr1 = this.state.pagination.selectedPaymentCategory.map(function (item) {
    //     return item.label;
    //   });
    //   request.paymentCategory = arr1.join([',']);
    // }
    // else {
    //   request.paymentCategory = "";
    // }
    this.membershipPricingServices.getEventPaymentReconcilationList(request).then(
      (response) => {

        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          details.map((key) => {
            key.registrationDate = moment(key.registrationDate).format(
              "DD MMM YYYY"
            );
            return key;
          });
          this.setState({ paymentReconcilationList: details });
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  onDelete = (value) => {

    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Payment Reconciliation"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "payment reconciliation"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.BookingServices.deleteEventPaymentReconcilation(request).then((response) => {
            if (response.statusCode === 200) {
              this.swalServices.Success("Delete successfully");
              this.getEventPaymentReconcilationList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  };

  componentDidMount() {
    debugger

    let cookie = getAuthProps();
    if (cookie != null) {
      const adminUserId = cookie.adminUserId;
      const adminname = cookie.name;
      this.setState({ updatedBy: adminUserId, updatedAppId: 111, adminName: adminname });
    }

    this.getAllDropDownForEntryManagement();
  }

  savePaymentReconcilationDetailsByAdmin = () => {

    let req = {};
    req.paymentProofId = this.state.singlePaymentProofDetails.paymentProofId;
    req.bookingId = this.state.singlePaymentProofDetails.bookingId;
    req.amountCollected = this.state.paymentProofApproveDetails.amountCollected;
    req.amountCollectedDate = this.state.paymentProofApproveDetails.amountCollectedDate;
    req.addreceipt = this.state.paymentProofApproveDetails.addreceipt
    req.updatedAppId = this.state.updatedAppId;
    req.updatedAt = new Date();
    req.updatedBy = this.state.updatedBy;
    req.bankInformationName =
      this.state.paymentProofApproveDetails.bankInformationName;
    req.bankInformationId =
      this.state.paymentProofApproveDetails.bankInformationNameId;
    if (this.isValidateAllFields()) {
      let collectedAmount = 0;
      if (
        this.state.singlePaymentProofDetails &&
        this.state.singlePaymentProofDetails.paymentList
      ) {
        this.state.singlePaymentProofDetails.paymentList.forEach((element) => {
          collectedAmount += element.amountCollected;
        });
      }

      if (
        this.state.singlePaymentProofDetails.reconciliationTotalAmount <
        collectedAmount +
        Number(this.state.paymentProofApproveDetails.amountCollected)
      ) {
        this.swalServices.Warning("You can't insert more than total amount.");
      } else {
        this.setState({ isAddRecord: true });
        this.membershipPricingServices.savePaymentReconcilationDetailsByAdmin(req).then(

          (response) => {

            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent != null) {
                let amount = Number(this.state.paymentProofApproveDetails.amountCollected)
                if (amount + collectedAmount
                  === Number(this.state.singlePaymentProofDetails.reconciliationTotalAmount.toFixed(2))) {
                  console.log(this.state.singlePaymentProofDetails);
                  // TODO: need to discuss with Sanjay , Jay and Jay bhatt

                  this.acceptTotalPaidAmountInReconcilation(

                    this.state.singlePaymentProofDetails.bookingId,
                    this.state.singlePaymentProofDetails.corporateMemberId,
                    this.state.singlePaymentProofDetails.membershipPaymentTypeId,
                    this.state.singlePaymentProofDetails.slotsMemberId,
                    this.state.singlePaymentProofDetails.conquasMemberId,
                    this.state.singlePaymentProofDetails.studentMemberId,
                  )
                  this.resetPaymentProofDetails();
                  this.getEventPaymentReconcilationList();
                  // this.setState({ setpaymentApprovalModalOpen: false }, () => {
                  //   this.resetPaymentProofDetails();
                  //   this.getEventPaymentReconcilationList();
                  // });
                } else {
                  this.setState({ setpaymentApprovalModalOpen: false }, () => {
                    this.resetPaymentProofDetails();
                    this.getEventPaymentReconcilationList();
                  });
                  this.swalServices.Success(
                    "Payment reconciliation details inserted successfully."
                  );
                }
                this.setState({ isAddRecord: false });
              }
            } else {
              this.swalServices.Error(response.message);
            }
          }
        );
      }
    }
  };

  acceptTotalPaidAmountInReconcilation

    = (

      bookingId = this.state.singlePaymentProofDetails.bookingId,
      corporateId,
      paymentCategoryId,
      slotsMemberId,
      conquasMemberId,
      studentMemberId = this.state.singlePaymentProofDetails.studentMemberId
    ) => {
      if (studentMemberId > 0) {

        let acceptTotalMembershipRequest = {};
        acceptTotalMembershipRequest.studentMemberId = studentMemberId;
        acceptTotalMembershipRequest.membershipPaymentId = bookingId;
        this.setState({ isLoader: true });
        this.membershipPricingServices.acceptMembershipAmountInReconciliationOfStudentMember(acceptTotalMembershipRequest).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              this.setState({ setpaymentApprovalModalOpen: false }, () => {
                this.resetPaymentProofDetails();
                this.getEventPaymentReconcilationList();
              });
              this.swalServices.Success(
                "Payment reconciliation details accepted successfully."
              );
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoader: false });
        });
      }
      else if (conquasMemberId > 0) {

        let acceptTotalMembershipRequest = {};
        acceptTotalMembershipRequest.conquasMemberId = conquasMemberId;
        acceptTotalMembershipRequest.membershipPaymentId = bookingId;
        this.setState({ isLoader: true });
        this.membershipPricingServices.acceptMembershipAmountInReconciliationOfCONQUASMember(acceptTotalMembershipRequest).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              this.setState({ setpaymentApprovalModalOpen: false }, () => {
                this.resetPaymentProofDetails();
                this.getEventPaymentReconcilationList();
              });
              this.swalServices.Success(
                "Payment reconciliation details accepted successfully."
              );
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoader: false });
        });
      } else if (slotsMemberId > 0) {

        let acceptTotalMembershipRequest = {};
        acceptTotalMembershipRequest.slotsMemberId = slotsMemberId;
        acceptTotalMembershipRequest.membershipPaymentId = bookingId;
        this.setState({ isLoader: true });
        this.membershipPricingServices.acceptMembershipAmountInReconciliationOfSlotsMember(acceptTotalMembershipRequest).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              this.setState({ setpaymentApprovalModalOpen: false }, () => {
                this.resetPaymentProofDetails();
                this.getEventPaymentReconcilationList();
              });
              this.swalServices.Success(
                "Payment reconciliation details accepted successfully."
              );
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoader: false });
        });
      }
      else {

        let acceptTotalMembershipRequest = {};
        acceptTotalMembershipRequest.corporateMemberId = corporateId;
        acceptTotalMembershipRequest.membershipPaymentTypeId = paymentCategoryId;
        acceptTotalMembershipRequest.membershipPaymentId = bookingId;
        this.setState({ isLoader: true });
        console.log(this.state.corporateId);
        this.membershipPricingServices.acceptTotalMembershipPaidAmountInReconciliation(acceptTotalMembershipRequest).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              this.setState({ setpaymentApprovalModalOpen: false }, () => {
                this.resetPaymentProofDetails();
                this.getEventPaymentReconcilationList();
              });
              this.swalServices.Success(
                "Payment reconciliation details accepted successfully."
              );
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoader: false });
        });
      }
      // if (paymentCategoryId === 4) {
      //   this.membershipPricingServices.acceptTotalPaidAmountInReconcilation(bookingId).then(
      //     (response) => {
      //       if (response.statusCode === 200 && response.responseItem != null) {
      //         if (response.responseItem.responseContent != null) {
      //           this.setState({ setpaymentApprovalModalOpen: false }, () => {
      //             this.resetPaymentProofDetails();
      //             this.getEventPaymentReconcilationList();
      //           });
      //           this.swalServices.Success(
      //             "Payment reconciliation details inserted successfully."
      //           );
      //         }
      //       } else {
      //         this.swalServices.Error(response.message);
      //       }
      //     }
      //   );
      // } else if (paymentCategoryId === 1 || paymentCategoryId === 3) {
      //   let acceptTotalMembershipRequest = {};
      //   acceptTotalMembershipRequest.corporateMemberId = corporateId;
      //   acceptTotalMembershipRequest.membershipPaymentTypeId = paymentCategoryId;
      //   acceptTotalMembershipRequest.membershipPaymentId = bookingId;
      //   this.BookingServices.acceptTotalMembershipPaidAmountInReconciliation(acceptTotalMembershipRequest).then((response) => {
      //     if (response.statusCode === 200 && response.responseItem != null) {
      //       if (response.responseItem.responseContent != null) {
      //         this.setState({ setpaymentApprovalModalOpen: false }, () => {
      //           this.resetPaymentProofDetails();
      //           this.getEventPaymentReconcilationList();
      //         });
      //         this.swalServices.Success(
      //           "Payment reconciliation details inserted successfully."
      //         );
      //       }
      //     } else {
      //       this.swalServices.Error(response.message);
      //     }
      //   });
      // } else if (paymentCategoryId === 5) {
      //   this.BookingServices.acceptTotalExternalInvoicePaidAmountInReconciliation(
      //     financeAdhocInvoiceId
      //   ).then((response) => {
      //     if (response.statusCode === 200 && response.responseItem != null) {
      //       if (response.responseItem.responseContent != null) {
      //         this.setState({ setpaymentApprovalModalOpen: false }, () => {
      //           this.resetPaymentProofDetails();
      //           this.getEventPaymentReconcilationList();
      //         });
      //         this.swalServices.Success(
      //           "Payment reconciliation details inserted successfully."
      //         );
      //       }
      //     } else {
      //       this.swalServices.Error(response.message);
      //     }
      //   });
      // } else if (paymentCategoryId === 2) {
      //   this.BookingServices.acceptTotalPurchaseChapterPaidAmountInReconciliation(
      //     corporateId
      //   ).then((response) => {
      //     if (response.statusCode === 200 && response.responseItem != null) {
      //       if (response.responseItem.responseContent != null) {
      //         this.setState({ setpaymentApprovalModalOpen: false }, () => {
      //           this.resetPaymentProofDetails();
      //           this.getEventPaymentReconcilationList();
      //         });
      //         this.swalServices.Success(
      //           "Payment reconciliation details inserted successfully."
      //         );
      //       }
      //     } else {
      //       this.swalServices.Error(response.message);
      //     }
      //   });
      // }
    };

  getAllDropDownForEntryManagement = () => {
    this.membershipPricingServices.getAllDropDownForEntryManagement(0).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var bankList = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BankNames"
        );
        var membershipPaymentTypeList =
          response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "MembershipPaymentType"
          );
        bankList = bankList.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        membershipPaymentTypeList = membershipPaymentTypeList.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({
          bankNameList: bankList,
          membershipPaymentTypeList: membershipPaymentTypeList,

        }, () => this.getEventPaymentReconcilationList());
      } else {
        this.getEventPaymentReconcilationList();
        this.swalServices.Error(response.message);
      }
    });
    //this.getEventPaymentReconcilationList();
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.paymentProofApproveDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };


  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.paymentProofApproveDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  handleChange(event, identity) {
    const value = event.target.value;
    const name = event.target.name;

    if (identity === "paymentProofApproveDetails") {
      let detail = { ...this.state.paymentProofApproveDetails };
      detail[name] = value;
      this.setState({ paymentProofApproveDetails: detail });
    }
    if (identity === "pagination") {
      let page = { ...this.state.pagination };
      page.pageNo = 1;
      page[name] = value;
      this.setState({ pagination: page });
    }
  }

  handleChangeInDropdown = (selected, drpIdentity) => {
    let detail = this.state.paymentProofApproveDetails;
    let page = this.state.pagination;
    if (drpIdentity === "BankName") {
      detail.bankInformationNameId = selected.value;
      if (selected.value === 0) {
        detail.bankInformationName = "";
      } else {
        detail.bankInformationName = selected.label;
      }
      this.setState({ paymentProofApproveDetails: detail });
      this.validateField("bankInformationName");
    } else if (drpIdentity === "PaymentCategory") {
      if (selected.value === 0) {
        page.paymentCategory = "Event";
      } else {
        let selectedPaymentCategory = [];
        let commaSeparatedString = "";
        selected.forEach((selectedObj, selectedObjIndex) => {
          switch (selectedObj.label) {
            case "Event":
              selectedPaymentCategory.push(4);
              break;
            case "Membership":
              selectedPaymentCategory.push(1);
              selectedPaymentCategory.push(3);
              break;
            case "External Invoice":
              selectedPaymentCategory.push(5);
              break;
            case "Additional Chapter":
              selectedPaymentCategory.push(2);
              break;
            default:
              selectedPaymentCategory.push(3);
          }
        });
        if (selectedPaymentCategory.length > 0) {
          commaSeparatedString = selectedPaymentCategory.join([","]);
        }
        page.paymentCategory = commaSeparatedString;
        page.selectedPaymentCategory = selected;
      }
      this.setState({ pagination: page });
    } else if (drpIdentity === "PaymentStatus") {
      if (selected.value === 0) {
        page.reconcilationStatus = "";
      } else {
        page.reconcilationStatus = selected.label;
      }
      this.setState({ pagination: page });
    }
  };

  filterSearch = () => {
    this.getEventPaymentReconcilationList(this.state.pagination);
  };

  resetPaymentProofDetails = () => {
    let reset = this.state.paymentProofApproveDetails;
    let reset2 = this.state.singlePaymentProofDetails;
    reset.paymentProofId = 0;
    reset.bookingId = 0;
    reset.amountCollected = "";
    reset.bankInformationName = "";
    reset.bankInformationNameId = 0;
    reset2.paymentList = [];
    reset2.totalPrice = 0;
    reset2.filePath = "";
    reset2.amountCollectedDate = "";
    reset2.addreceipt = "";
    reset2.comments = "";
    reset.amountCollectedDate = "";
    this.setState({
      paymentProofApproveDetails: reset,
      singlePaymentProofDetails: reset2,
    });
  };

  getOutstandingInvoicesList = (outstandingInvoicesRequest = this.state.outstandingInvoicesRequest) => {
    this.setState({ isLoading: true });
    this.membershipPricingServices.getOutstandingInvoicesList(outstandingInvoicesRequest).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          details.map((key) => {
            key.invoiceGenerationDate = key.invoiceGenerationDate ? moment(key.invoiceGenerationDate).format("DD MMM YYYY") : "-";
            key.invoiceNo = key.invoiceNo ? key.invoiceNo : "-";
            return key;
          });
          this.setState({ outstandingInvoicesList: details });
          this.setState({
            totalOutstandingInvoicesListCount: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  openOutstandingModal = () => {
    this.setState({ setModal: true });
    this.getOutstandingInvoicesList();
  }

  setPaginationForOutstanding = (newPagination) => {
    this.setState({ outstandingInvoicesRequest: newPagination });
    this.getOutstandingInvoicesList(newPagination);
  };

  outstandingInvoicesActions = (element, index, value) => {
    return element !== "Id" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <div class="grid grid-cols-2 gap-4">
          <div className="flex items-center relative">
            <button
              className="action-btn mr-4 flex items-center"
              aria-controls="add-edit-modal"
              onClick={(e) => this.openOutstandingDetaisModal(e, value)}
            >
              <p className="ml-2 tool-tip-txt underline text-[#005b9c]">Update</p>
            </button>
          </div>
        </div>
      </td>
    );
  };

  openOutstandingDetaisModal = (e, value) => {
    let details = [...this.state.outstandingInvoicesList];
    let found = {
      ...details.find((x) => x.membershipPaymentId === value),
    };
    this.setState({ singleOutstandingInvoiceDetails: found });
    this.setState({ outstandingInvoiceDeatilsModal: true });
  }

  onFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf")) {
      let request = {};
      request.storagePath = "PaymentImages";
      request.file = file;
      request.fileName = file.name;

      this.commonServices.uploadPDF(request).then((response) => {
        if (response != null) {
          if (request.storagePath === "PaymentImages") {
            let detail = { ...this.state.singleOutstandingInvoiceDetails };
            detail["filePath"] = response.fileName;
            detail["fileDisplayName"] = file.name;

            this.setState({ singleOutstandingInvoiceDetails: detail }, () => {
              this.swalServices.Success("File Uploaded");
            });
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {
      this.swalServices.Error("Please select only Image or PDF");
    }
  };

  handleChangeComments(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.singleOutstandingInvoiceDetails };
    detail[name] = value;
    this.setState({ singleOutstandingInvoiceDetails: detail });
  }

  addProofInPaymentProofMapping = () => {
    let isValidInput = this.isValidateAllFieldsOutstandingDetails();
    if (isValidInput) {
      let detail = { ...this.state.singleOutstandingInvoiceDetails }
      detail.paymentProofStatusId = 1;
      //detail.membershipPaymentId = detail.membershipPaymentId;
      this.membershipPricingServices.addProofInPaymentProofMapping(detail).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(response.message);
          this.getOutstandingInvoicesList();
          this.reset();
          this.setState({ outstandingInvoiceDeatilsModal: false })
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
  };

  reset = () => {
    let details = this.state.singleOutstandingInvoiceDetails;
    details.membershipPaymentId = "";
    details.financeAdhocInvoiceId = "";
    details.bookingId = ""
    details.membershipPaymentTypeId = ""
    details.invoiceNo = "";
    details.paymentVia = "";
    details.amount = "";
    details.status = "";
    details.invoiceGenerationDate = "";
    details.corporateMemberId = "";
    details.IndividualMemberId = "";

    details.eventId = "";
    details.filePath = "";
    details.comments = "";
    details.fileDisplayName = "";
    this.setState({ singleOutstandingInvoiceDetails: details })
  }
  validateFieldOutstandingDetails = (key) => {
    const newValidState = validate(
      key,
      this.state.singleOutstandingInvoiceDetails,
      this.state.validationRule1,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFieldsOutstandingDetails = () => {
    const newValidState = isValidForm(
      this.state.singleOutstandingInvoiceDetails,
      this.state.validationRule1,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  GetSlotsMemberFiles = (type, Filepath) => {

    if (Filepath) {
      let request = [
        0,
        type,
        Filepath,
      ];
      this.membershipPricingServices.GetSlotsMemberFiles(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
          var filename = "";
          filename = `${type}${formattedDate}`;
          const extension = Filepath.split(".").pop();
          if (extension) {
            saveAs(new Blob([response.data]), `${filename}.${extension}`);
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {
      this.swalServices.Error("No data found");
    }
  };

  render() {
    return (
      <div className="main-body-section finance-body-sec">
        {/* Add/Edit GST Start */}
        <div className="bg-white">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-6 pb-12">
            <div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                {/* Search Invoice No. */}
                Search
                {/* <span className="text-[#c00000]">*</span> */}
              </h2>

              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="searchText"
                placeholder="Search"
                value={this.state.pagination.searchText}
                onChange={(e) => this.handleChange(e, "pagination")}
              />
            </div>
            <div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                {/* Search By Comments */}
                Search Comments
                {/* <span className="text-[#c00000]">*</span> */}
              </h2>
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="searchCommentsText"
                placeholder="Search By Comments"
                value={this.state.pagination.searchCommentsText}
                onChange={(e) => this.handleChange(e, "pagination")}
              />
            </div>
            <div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Status
                {/* <span className="text-[#c00000]">*</span> */}
              </h2>
              <div className="with-border-line w-full">
                <Selector
                  options={this.state.statusList}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{
                  //   Option,
                  // }}
                  onChange={this.handleChangeInDropdown.bind(this)}
                  allowSelectAll={false}
                  value={this.state.statusList.find(
                    (item) =>
                      item.label === this.state.pagination.reconcilationStatus
                  )}
                  drpIdentity="PaymentStatus"
                />
              </div>
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Date
                {/* <span className="text-[#c00000]">*</span> */}
              </h2>
              <input
                id="default"
                name="filterDate"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={this.state.pagination.filterDate}
                onChange={(e) => this.handleChange(e, "pagination")}
              />
            </div>


            {/*<div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Payment Category                
              </h2>
              <div className="with-border-line w-full">
                <Selector
                  options={this.state.paymentCategory}
                  isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{
                  //   Option,
                  // }}
                  onChange={this.handleChangeInDropdown.bind(this)}
                  allowSelectAll={false}
                  //value={this.state.paymentCategory.find(item => item.label === this.state.pagination.paymentCategory)}
                  value={this.state.pagination.selectedPaymentCategory}
                  drpIdentity="PaymentCategory"
                />
              </div>
            </div>*/}

            <div className="col-span-12 w-full">
              <div className="pt-7 flex items-center justify-between w-full">
                <button
                  className="btn btn-blue text-xl border text-white"
                  onClick={() => this.filterSearch()}
                >
                  Search
                </button>
                <button className="btn  btn-blue text-xl border text-white "
                  onClick={(e) => {
                    this.openOutstandingModal()
                  }}
                >
                  Add Records
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Add/Edit GST End */}
        {/* Section 3 Start */}
        <div className="new-class-add-for-pending-aprovle">
          <div className="mt-10 payment-reconcilation-tble ">
            <Table
              columns={[
                { name: "registrationDate", title: "Registration Date" },
                { name: "reconciliationInvoiceNo", title: "Invoice No" },
                { name: "companyName", title: "Company Name" },
                { name: "companyUEN", title: "Company UEN" },
                { name: "contactPerson", title: "Contact Person" },
                //{ name: "paymentVia", title: "Payment Via" },
                { name: "paymentProofStatus", title: "Status" },
                { name: "paymentProofId", title: "Action" },
              ]}
              rows={this.state.paymentReconcilationList}
              isLoading={this.state.isLoading}
              sortingColumns={["StatusName"]}
              pagination={this.state.pagination}
              totalResultes={this.state.totalResultes}
              totalCount={this.state.totalResultes}
              setPagination={this.setPagination.bind(this)}
              customScope={[
                {
                  column: "paymentProofId",
                  renderTableData: this.actions.bind(this),
                },
              ]}
            />
          </div>
        </div>

        {/* Section 3 End */}

        <div className="payment-view-approve-modal">
          <ModalBasic
            id="paymentviewapprove"
            modalOpen={this.state.setpaymentApprovalModalOpen}
            setModalOpen={() =>
              this.setState({ setpaymentApprovalModalOpen: false }, () => {
                // this.resetPaymenPtroofDetails();
              })
            }
            title="Payment Approval"
          >
            <div className="md:px-10 p-4 md:py-7 border-t border-[#000000]-300 bg-white body-sec-approve">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 border-b-2 border-[#000000]-300 md:pb-14 pb-5">
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-bold">Proof</h2>
                    <div className="download-icon">
                      <button className="flex items-center btn btn-blue text-white">
                        <span className="font-bold text-xl">
                          Download
                        </span>
                        {/* <img className="w-6 pl-2" src="../download-icon-white.png"/> */}
                        <img className="w-6 pl-2" src="../download-icon-white.png" alt="" />

                      </button>
                    </div>
                  </div>
                  {this.state.paymentProofExtension === 'pdf' ?
                    <>
                      <div class="w-auto">
                        <button
                          className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-blue-border text-xl border text-[#e78e3c]"
                          onClick={() =>
                            this.GetSlotsMemberFiles(
                              "PaymentImages",
                              this.state.singlePaymentProofDetails.filePath
                            )
                          }
                        >
                          <span className="text-xl text-[#1974D2] font-semibold tracking-normal underline">
                            View Proof
                          </span>
                        </button>
                      </div>
                    </>
                    :
                    <>
                      <div className="payment-img mt-2">
                        <img
                          className="cursor-pointer"
                          onClick={() => {

                            window.open(
                              APIURL +
                              GetImage +
                              "?type=PaymentImages&&fileName=" +
                              this.state.singlePaymentProofDetails.filePath,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                          src={
                            APIURL +
                            GetImage +
                            "?type=PaymentImages&&fileName=" +
                            this.state.singlePaymentProofDetails.filePath
                          } alt="Payment Proof"
                        />
                      </div>
                    </>
                  }
                  <div className="col-span-10">
                    <h2 className="text-xl theme-color font-bold mb-3 pt-4">
                      Comments :{" "}
                      {this.state.singlePaymentProofDetails.comments}
                    </h2>
                  </div>
                  {/* {this.state.singlePaymentProofDetails.paymentList
                    .length > 0 &&
                    this.state.singlePaymentProofDetails.paymentList.map(
                      (e) => {
                        return (
                          <div className="col-span-10">
                              Comments :{" "} 
                            {e.comments}
                          </div>
                        );
                      }
                    )} */}
                </div>
                <div className="col-span-12">
                  {this.state.singlePaymentProofDetails.paymentProofStatusId ===
                    3 || this.state.singlePaymentProofDetails.paymentProofStatusId ===
                    2 || this.state.singlePaymentProofDetails.paymentProofStatusId ===
                    4 ? (
                    null
                  ) :
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full">
                        <h2 className="text-xl theme-color mb-3">
                          Bank Information
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <div className="with-border-line w-full">
                          <Selector
                            options={this.state.bankNameList}
                            // isMulti
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            // components={{
                            //   Option,
                            // }}
                            onChange={this.handleChangeInDropdown.bind(this)}
                            allowSelectAll={false}
                            // value={this.state.pagination.FilterByAgency}
                            value={this.state.bankNameList.filter(
                              (item) =>
                                item.label ===
                                this.state.paymentProofApproveDetails
                                  .bankInformationName
                            )}
                            drpIdentity="BankName"
                          />
                          <div>
                            <ValidationText
                              error={
                                this.state.validState.error.bankInformationName
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="2xl:col-span-4 lg:col-span-4 col-span-12 w-full">
                        <h2 className="text-xl theme-color mb-3">
                          Amount Collected
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <div className="with-border-line w-full">
                          <input
                            className="form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="number"
                            name="amountCollected"
                            value={
                              this.state.paymentProofApproveDetails
                                .amountCollected
                            }
                            onChange={(e) =>
                              this.handleChange(e, "paymentProofApproveDetails")
                            }
                            onBlur={() => this.validateField("amountCollected")}
                          ></input>
                          <div>
                            <ValidationText
                              error={
                                this.state.validState.error.amountCollected
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full">
                        <h2 className="text-xl theme-color mb-3">
                          Comments
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <div className="with-border-line w-full">
                          <input
                            className="form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="text"
                            name="addreceipt"
                            value={
                              this.state.paymentProofApproveDetails
                                .addreceipt
                            }
                            onChange={(e) =>
                              this.handleChange(e, "paymentProofApproveDetails")
                            }
                            onBlur={() => this.validateField("addreceipt")}
                          ></input>
                          <div>
                            <ValidationText
                              error={
                                this.state.validState.error.addreceipt
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="2xl:col-span-4 lg:col-span-4 col-span-12 w-full">
                        <h2 className="text-xl theme-color mb-3">
                          Amount Collect Date
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <div className="with-border-line w-full">
                          <DateInput
                            name="amountCollectedDate"
                            placeholder=" dd/mm/yyyy"
                            value={
                              this.state.paymentProofApproveDetails
                                .amountCollectedDate
                            }
                            handleChange={(e) =>
                              this.handleChange(e, "paymentProofApproveDetails")
                            }
                            onBlur={() => this.validateField("amountCollectedDate")}
                          />
                          <div>
                            <ValidationText
                              error={
                                this.state.validState.error.amountCollectedDate
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="2xl:col-span-2 lg:col-span-2 col-span-12 w-full">
                        <h2 className="text-xl theme-color mb-3 invisible">
                          Date
                        </h2>
                        <div className="flex items-center justify-end w-full">
                          {this.state.isAddRecord === true ?
                            <>
                              <ButtonLoader className={'btn btn-blue'} />
                            </>
                            :
                            <>
                              <button
                                className="btn btn-blue text-xl border text-white "
                                onClick={() =>
                                  this.savePaymentReconcilationDetailsByAdmin()
                                }
                              >
                                Add
                              </button>
                            </>
                          }

                        </div>
                      </div>
                    </div>}

                  {/* {this.state.singlePaymentProofDetails.paymentList
                    .length > 0 &&
                    this.state.singlePaymentProofDetails.paymentList.map(
                      (e) => {
                        <div className="col-span-12">
                          <h2 className="text-xl theme-color font-bold mb-3">
                            Total :{" "}
                            {`$${this.state.singlePaymentProofDetails.reconciliationTotalAmount}`}
                          </h2>
                        </div>
                      } */}
                  <div className="grid grid-cols-12 gap-6 md:mt-7 md:mb-5 mt-0">
                    <div className="col-span-12">
                      <h2 className="text-xl theme-color font-bold mb-3">
                        Total Amount:{" "}
                        {`$${this.state.singlePaymentProofDetails.reconciliationTotalAmount.toFixed(2)}`}
                      </h2>
                      <div className="approved-table-sec overflow-x-auto">
                        <table className="stretegic-member-tbl w-full mx-auto my-5 fixed_header">
                          <thead>
                            <tr className="text-base font-bold flex">
                              <th class="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                                <div class="font-bold tble-th-txt h-full flex items-center">Date</div>
                              </th>
                              <th class="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[35%]">
                                <div class="font-bold tble-th-txt h-full flex items-center">
                                  Bank Information
                                </div>
                              </th>
                              <th class="px-2 first:pl-5 last:pr-5 tbl-th whitespace-wrap text-white w-[15%]">
                                <div class="font-bold tble-th-txt h-full flex items-center">
                                  Amount Collected
                                </div>
                              </th>
                              <th class="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[35%]">
                                <div class="font-bold tble-th-txt h-full flex items-center">
                                  Comments
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody className="text-sm custom-tbody globle-setting-tbody">
                            {this.state.singlePaymentProofDetails.paymentList
                              .length > 0 &&
                              this.state.singlePaymentProofDetails.paymentList.map(
                                (e) => {
                                  return (
                                    <tr className="custom-tbl-td flex">
                                      <td className="px-2 first:pl-5 last:pr-5 whitespace-normal tble-td-txt text-[#000000] w-[15%]">
                                        <div className="flex items-center h-full">
                                          {moment(e.amountCollectedDate).format(
                                            "DD MMM YYYY"
                                          )}
                                        </div>
                                      </td>
                                      <td className="px-2 first:pl-5 last:pr-5 whitespace-normal tble-td-txt text-[#000000] w-[35%]">
                                        <div className="flex items-center h-full">
                                          {e.bankName ? e.bankName : ''}
                                        </div>
                                      </td>
                                      <td className="px-2 first:pl-5 last:pr-5 whitespace-normal tble-td-txt text-[#000000] w-[15%]">
                                        <div className="flex items-center h-full">
                                          {e.amountCollected}
                                        </div>
                                      </td>
                                      <td className="px-2 first:pl-5 last:pr-5 whitespace-normal tble-td-txt text-[#000000] w-[35%]">
                                        <div className="flex items-center h-full">
                                          {e.comments}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            {/* <tr className="grid justify-items-center">
              <td className="text-lg text-[#000000]	">No Records Found</td>
            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                      <div className="flex items-center justify-end w-full">
                        {this.state.singlePaymentProofDetails
                          .paymentProofStatusId !== 3 ? (
                          <>
                            {this.state.singlePaymentProofDetails.paidPrice ===
                              this.state.singlePaymentProofDetails
                                .reconciliationTotalAmount ? (
                              <>
                                {
                                  this.state.isLoader === true ?
                                    <>
                                      <ButtonLoader type='btn btn-blue' />
                                    </>
                                    :
                                    <>
                                      <button
                                        className="btn btn-blue text-xl border text-white "
                                        onClick={() =>
                                          this.acceptTotalPaidAmountInReconcilation(

                                            this.state.singlePaymentProofDetails.bookingId,
                                            this.state.singlePaymentProofDetails.corporateMemberId,
                                            this.state.singlePaymentProofDetails.membershipPaymentTypeId,
                                            this.state.singlePaymentProofDetails.slotsMemberId,
                                            this.state.singlePaymentProofDetails.conquasMemberId,
                                            this.state.singlePaymentProofDetails.studentMemberId,
                                          )
                                        }
                                      >
                                        Accept
                                      </button>
                                    </>
                                }
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBasic>

          <ModalBasic
            id="add-edit-modal"
            className="membership-add-edit-modal"
            modalOpen={this.state.setModal}
            setModalOpen={() => this.setState({ setModal: false })}
            title="Outstanding Invoices List"
          >
            <div className="add-record-popup-section">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="2xl:col-span-4 lg:col-span-6 col-span-12">
                  <h2 className="text-xl theme-color mb-1">
                    Search
                  </h2>
                  <input
                    id="default"
                    className="form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="text"
                    onChange={(e) => {
                      let data = { ...this.state.outstandingInvoicesRequest }
                      data.searchText = "";
                      data.searchText = e.target.value;
                      this.setState({ outstandingInvoicesRequest: data }, () => this.getOutstandingInvoicesList(data))
                    }
                    }
                  />
                </div>
              </div>
              <div className="mt-10 payment-reconcilation-tble">
                <Table
                  columns={[
                    { name: "InvoiceNo", title: "Invoice No" },
                    //{ name: "PaymentVia", title: "Payment" },
                    { name: "Amount", title: "Total Amount" },
                    { name: "Status", title: "Status" },
                    { name: "InvoiceGenerationDate", title: "Date" },
                    // { name: "paymentVia", title: "Payment Via" },
                    // { name: "paymentProofStatus", title: "Status" },
                    { name: "Id", title: "Action" },
                  ]}
                  rows={this.state.outstandingInvoicesList}
                  isLoading={this.state.isLoading}
                  sortingColumns={["PaymentVia", "Amount"]}
                  pagination={this.state.outstandingInvoicesRequest}
                  totalResultes={this.state.totalOutstandingInvoicesListCount}
                  totalCount={this.state.totalOutstandingInvoicesListCount}
                  setPagination={this.setPaginationForOutstanding.bind(this)}
                  customScope={[
                    {
                      column: "Id",
                      renderTableData: this.outstandingInvoicesActions.bind(this),
                    },
                  ]}
                />
              </div>
            </div>

          </ModalBasic>
          <div className="outstanding-invoice-detail-modal">
            <ModalBasic
              id="outstandingInvoices"
              modalOpen={this.state.outstandingInvoiceDeatilsModal}
              setModalOpen={() => this.setState({ outstandingInvoiceDeatilsModal: false })}
              title="Outstanding Invoice Details"
            >
              <div className="grid grid-span-12 gap-6 p-5 pb-10">
                <div className="col-span-12 mb-5">
                  <div className="items-center mb-5">
                    <label className="text-[#000000] text-[16px] mobile-14 md:mb-5 inline-block">
                      File 1
                    </label>
                    <div className="flex items-center disabled-input">
                      <input
                        className="w-full custom-input"
                        type="text"
                        name=""
                        value={this.state.singleOutstandingInvoiceDetails.fileDisplayName}
                        disabled
                      />
                      <div>
                        <ValidationText
                          error={this.state.validState.error.fileDisplayName}
                        />
                      </div>
                      <div className="flex items-center justify-center ml-4 ">
                        <form className="custom-uploader custom-file-upload-btn flex justify-center items-center text-center btn btn-blue-border">
                          <label
                            htmlFor={`upload_File`}
                            className="text-uplod block text-sm text-[#005B9C] w-full whitespace-nowrap"
                          >
                            Select File
                          </label>
                          {/* <img
                          className="uplod-svg inline-flex w-5 h-5 fill-[#000000] ml-4"
                          src="/pdf-upload.svg"
                        ></img> */}
                          <input
                            className="sr-only"
                            // key={`FileQuestion${key}`}
                            id={`upload_File`}
                            type="file"
                            onChange={(event) => this.onFileChange(event)}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="items-center">
                    <label className="text-[#000000] text-[16px] mobile-14 md:mb-5 inline-block">
                      Comments
                      <span className="text-[#c00000]">*</span>
                    </label>
                    <textarea
                      className="w-full"
                      // placeholder="Comments"
                      id=""
                      rows="5"
                      name="comments"
                      value={this.state.singleOutstandingInvoiceDetails.comments}
                      onChange={(e) => this.handleChangeComments(e)}
                      onBlur={() => this.validateFieldOutstandingDetails("comments")}
                    />
                    <div>
                      <ValidationText
                        error={this.state.validState.error.comments}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 flex justify-center">
                  <button class="w-32 px-7 py-2 btn-sm btn-blue text-lg text-white"
                    onClick={() => {
                      this.addProofInPaymentProofMapping();
                    }}
                  >Save</button>
                </div>
              </div>

            </ModalBasic>
          </div>

        </div>
      </div>
    );
  }
}
